import createAvatar from '../../utils/createAvatar';
import { CRAVE_THUMBNAIL } from '../../constants';

const avatarStyle = {
  width: 30,
  height: 30,
  fontWeight: 'bold',
  letterSpacing: 0.96,
  padding: '10px 5px',
  lineHeight: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
};

interface MyAvatarProps {
  onClick?: () => void;
  round?: boolean;
  fullName?: string;
}

function MyAvatar({ onClick, round, fullName }: MyAvatarProps) {
  const { name, color } = createAvatar(fullName);
  const photoUrl = '';

  return (
    <div
      className="my-avatar"
      onClick={onClick}
      style={{
        ...avatarStyle,
        backgroundColor: color.bg,
        color: color.textColor,
        borderRadius: round ? '50%' : 0,
        ...(Boolean(onClick) && {
          cursor: 'pointer',
        }),
        ...(Boolean(photoUrl) && {
          backgroundImage: `url(${CRAVE_THUMBNAIL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }),
      }}
    >
      {!photoUrl && (
        <span>
          {name.char1}
          {name.char2}
        </span>
      )}
    </div>
  );
}

export default MyAvatar;
