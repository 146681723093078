import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  RANGE_CALENDAR_DEFAULT_END_DATE,
  RANGE_CALENDAR_DEFAULT_START_DATE,
} from '../../../constants/constants';
import { useReduxSelector } from '../../../store/store';

const Orders = () => {
  const { user } = useReduxSelector((state) => state.auth);

  const [startDate, setStartDate] = useState(RANGE_CALENDAR_DEFAULT_START_DATE);
  const [endDate, setEndDate] = useState(RANGE_CALENDAR_DEFAULT_END_DATE);

  const [selectedLocations, setSelectedLocations] = useState([
    user.locationName,
  ]);
  const [sortOrder, setSortOrder] = useState('');
  const [orderTypes, setOrderTypes] = useState('ALL');
  const [filter, setFilter] = useState('');

  const props = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedLocations,
    setSelectedLocations,
    sortOrder,
    setSortOrder,
    orderTypes,
    setOrderTypes,
    filter,
    setFilter,
  };

  return <Outlet context={props} />;
};

export default Orders;
