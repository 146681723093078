export enum WidgetTypes {
  LEFT = 'left',
  RIGHT = 'right',
  SLIDER = 'slider',
}

export enum Platforms {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export enum MemberRoles {
  MANAGER = 'manager',
  STAFF = 'staff',
  APP = 'app',
}

export enum CustomDomainStatus {
  ENABLED = 'ENABLED',
  PENDING = 'PENDING',
  REVOKED = 'REVOKED',
}

export enum AppStoreConnectStatus {
  ISSUED = 'ISSUED',
}

export enum CodeMagicBuildStatus {
  FINISHED = 'finished',
}
