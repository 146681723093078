import axios from 'axios';

export const CRAVE_API_URL = process.env.REACT_APP_CRAVE_API_URL;

const crave = axios.create({
  baseURL: `${CRAVE_API_URL}/api/v2`,
  withCredentials: true,
});

export const apiFetcher = (url, token) =>
  crave
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => data);

export default crave;
