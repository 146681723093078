import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import ImageVideoPicker from '../pickers/ImageVideoPicker';
import { useState } from 'react';
import crave from '../../apis/crave';
import { useReduxDispatch, useReduxSelector } from '../../store/store';
import { formatApiError } from '../../utils';
import { updateOnBoarding } from '../../store/slices/onBoarding';
import { ADDRESS_AND_CURRENCY } from '../../routes';
import { useNavigate } from 'react-router-dom';
import AnimateContainer from '../common/AnimateContainer';
import MobilePreview from './MobilePreview';

const LogoAndCover = () => {
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();

  const { user } = useReduxSelector((state) => state.auth);
  const { loading, onBoarding } = useReduxSelector((state) => state.onBoarding);
  const { restaurantLogo, backgroundUrl, businessName } = onBoarding;

  const [coverProgress, setCoverProgress] = useState(0);
  const [logoProgressive, setLogoProgressive] = useState(0);
  const [coverLoading, setCoverLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const toast = useToast();

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      logo: restaurantLogo,
      cover: backgroundUrl,
      newLogo: null,
      newCover: null,
    },
    onSubmit: (values) => {
      dispatch(
        updateOnBoarding(
          {
            step: 1,
            data: {
              restaurantLogo: values.logo || '',
              backgroundUrl: values.cover || '',
            },
          },
          () => {
            navigate(ADDRESS_AND_CURRENCY);
          },
          (e: string) => {
            toast({
              title: e,
              status: 'error',
              isClosable: true,
            });
          }
        )
      );
    },
  });

  const handleUpload = async (
    file: File,
    setProgress: (progress: number) => void
  ) => {
    const formData = new FormData();
    formData.append('image', file);

    const config = {
      headers: { Authorization: `Bearer ${user.token}` },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        setProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    };

    const { data } = await crave.post(`/upload/image`, formData, config);

    return data;
  };

  const handleLogoUpload = async (file: File) => {
    try {
      setLogoLoading(true);
      await setFieldValue('newLogo', file);
      const data = await handleUpload(file, setLogoProgressive);

      await setFieldValue('logo', data.link);
      await setFieldValue('newLogo', null);
      setLogoLoading(false);
    } catch (error) {
      await setFieldValue('newLogo', null);
      const { e } = formatApiError(error);
      setLogoLoading(false);
      toast({
        title: e,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleCoverUpload = async (file: File) => {
    try {
      setCoverLoading(true);
      await setFieldValue('newCover', file);
      const data = await handleUpload(file, setCoverProgress);

      await setFieldValue('cover', data.link);
      await setFieldValue('newCover', null);
      setCoverLoading(false);
    } catch (error) {
      await setFieldValue('newCover', null);
      const { e } = formatApiError(error);
      setCoverLoading(false);
      toast({
        title: e,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const deleteImage = async (link: string, propName: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Are you sure you want to remove?')) {
      return;
    }

    try {
      const { data } = await crave.delete(`/upload/image`, {
        data: { link },
        headers: { Authorization: `Bearer ${user.token}` },
      });
      if (data.success) {
        await setFieldValue(propName, null);
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box px={{ lg: 6, base: 4 }} sx={{ flex: 1 }} py={6} overflow="auto">
        <AnimateContainer animate>
          <form onSubmit={handleSubmit} id="on-boarding">
            <Heading
              as="h1"
              fontSize={{ lg: '4xl', base: '3xl' }}
              mb={7}
              color="#241c15"
              fontWeight="medium"
            >
              Upload your brand's logo and cover photo
            </Heading>

            <HStack spacing={8} align="flex-start">
              <Stack spacing={4} py={3} flex={1}>
                <Text color="#363c42" fontSize="lg">
                  Brand Icon
                </Text>
                <ImageVideoPicker
                  savedImage={values.logo}
                  newImage={values.newLogo}
                  onDrop={(files) => handleLogoUpload(files[0])}
                  handleRemoveImage={() => deleteImage(values.logo, 'logo')}
                  progress={logoProgressive}
                  instructionText="Accepts .gif, .jpg and .png (maximum file size: 5MB)"
                  buttonText="Add icon"
                  loading={logoLoading}
                />

                <Text color="#363c42" fontSize="lg">
                  Brand Cover
                </Text>
                <ImageVideoPicker
                  savedImage={values.cover}
                  newImage={values.newCover}
                  onDrop={(files) => handleCoverUpload(files[0])}
                  handleRemoveImage={() => deleteImage(values.cover, 'cover')}
                  cover
                  progress={coverProgress}
                  instructionText="Accepts .gif, .jpg and .png (maximum file size: 5MB)"
                  buttonText="Add cover"
                  loading={coverLoading}
                />
              </Stack>
              <MobilePreview values={values} businessName={businessName} />
            </HStack>
          </form>
        </AnimateContainer>
      </Box>
      <Flex
        borderTop={1}
        borderColor="gray.200"
        borderStyle="solid"
        p={6}
        justify="flex-end"
      >
        <Button
          isLoading={loading}
          type="submit"
          form="on-boarding"
          size="lg"
          variant="primary"
          disabled={loading || coverLoading || logoLoading}
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default LogoAndCover;
