import React from 'react';
import { Select, OptionBase, SingleValue } from 'chakra-react-select';
// eslint-disable-next-line import/no-unresolved
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import {
  IconButton,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Stack,
} from '@chakra-ui/react';
import isNumeric from 'validator/es/lib/isNumeric';
import { Interval } from '../../types/schedule';
import { DeleteIcon } from '../../theme/overrides/CustomIcons';
import { time } from '../../constants/time';

interface TimeOptions extends OptionBase {
  label: string;
  value: string;
}

const filterOptions = (
  option: FilterOptionOption<TimeOptions>,
  inputValue: string
) => {
  if (!inputValue) return true;

  const inputValueStr =
    inputValue?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ?? '';

  const splitTime = option?.label?.toLowerCase().split(' ') ?? [];
  const hoursAndMinutes = splitTime[0];
  const amOrPM = splitTime[1];

  const hoursAndMinutesArr = hoursAndMinutes?.split(':') ?? [];
  const hour = hoursAndMinutesArr[0];
  const minute = hoursAndMinutesArr[1];

  const updatedInputValue =
    inputValueStr && inputValueStr.length === 1
      ? `0${inputValueStr}`
      : inputValueStr;

  if (updatedInputValue.length === 2 && isNumeric(updatedInputValue)) {
    return hour?.includes(updatedInputValue);
  }

  if (updatedInputValue.length > 2 && isNumeric(updatedInputValue)) {
    const hoursAndMinutesWithoutColon = `${hour}${minute}`;
    const firstFourChars = inputValueStr.slice(0, 4);
    return hoursAndMinutesWithoutColon?.includes(firstFourChars);
  }
  if (updatedInputValue.length <= 4) {
    // if this is executing means the string is alpha-numeric
    const hourWithAmPm = `${hour}${amOrPM}`;
    return hourWithAmPm?.includes(inputValueStr);
  }

  const fullTime = `${hour}${minute}${amOrPM}`;
  return fullTime?.includes(inputValueStr);
};

interface IntervalProps {
  handleDeleteInterval: () => void;
  index: number;
  interval: Interval;
  error: any;
  handleIntervalUpdates: (value: string, key: string) => void;
}

const OPTIONS = time.map((timeSingle) => {
  return { label: timeSingle, value: timeSingle };
});

const SELECT_STYLE = {
  '& .time-select__indicator-separator': {
    display: 'none',
  },

  '& .time-select__indicator': {
    bg: 'transparent',
  },
};

const IntervalC = (props: IntervalProps) => {
  const { handleDeleteInterval, interval, error, handleIntervalUpdates } =
    props;
  const { from, to } = interval;

  return (
    <VStack spacing={1.5} align="flex-start">
      <Stack direction="row">
        <Stack
          spacing={2.5}
          direction={{ base: 'column', md: 'row' }}
          sx={SELECT_STYLE}
        >
          <FormControl sx={{ w: 250 }} isInvalid={Boolean(error)}>
            <FormLabel mb={1} fontSize="sm" htmlFor="from-select">
              Start time
            </FormLabel>
            <Select
              className="time-select"
              classNamePrefix="time-select"
              id="from-select"
              value={{ value: from, label: from }}
              options={OPTIONS}
              filterOption={filterOptions}
              onChange={(newValue: SingleValue<TimeOptions>) => {
                if (newValue) {
                  handleIntervalUpdates(newValue.value, 'from');
                }
              }}
            />
          </FormControl>

          <VStack
            alignSelf="flex-end"
            height={10}
            justify="center"
            display={{ base: 'none', md: 'flex' }}
          >
            <Text>to</Text>
          </VStack>

          <FormControl sx={{ w: 250 }} isInvalid={Boolean(error)}>
            <FormLabel mb={1} fontSize="sm" htmlFor="to-select">
              Close time
            </FormLabel>
            <Select
              className="time-select"
              classNamePrefix="time-select"
              id="to-select"
              value={{ value: to, label: to }}
              options={OPTIONS}
              filterOption={filterOptions}
              onChange={(newValue: SingleValue<TimeOptions>) => {
                if (newValue) {
                  handleIntervalUpdates(newValue.value, 'to');
                }
              }}
            />
          </FormControl>
        </Stack>

        <VStack justify="center">
          <IconButton
            aria-label="Remove interval"
            variant="ghost"
            icon={<DeleteIcon sx={{ width: 6, height: 6 }} />}
            onClick={handleDeleteInterval}
            sx={{ mt: 6 }}
          />
        </VStack>
      </Stack>

      {Boolean(error) && (
        <Text fontSize="sm" color="red.500">
          {error}
        </Text>
      )}
    </VStack>
  );
};

export default IntervalC;
