// ----------------------------------------------------------------------

export default function Switch(theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '4.2rem',
          height: '2rem',
          padding: 0,
          display: 'flex',
        },
        switchBase: {
          padding: '0.3rem',
          color: theme.palette.primary.main,

          '&+.MuiSwitch-track': {
            backgroundColor: theme.palette.background.paper,
          },

          '&.Mui-checked': {
            color: theme.palette.background.paper,

            '&+.MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
        thumb: {
          width: '1.4rem',
          height: '1.4rem',
          boxShadow: 'none',
        },
        track: {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: 100,
          opacity: 1,
        },
      },
    },
  };
}
