import React from 'react';
import { ScaleFade } from '@chakra-ui/react';

interface AnimateContainerProps {
  animate?: boolean;
  children: React.ReactElement;
}

const AnimateContainer = ({ animate, children }: AnimateContainerProps) => {
  return (
    <ScaleFade initialScale={animate ? 0.6 : 1} in>
      {children}
    </ScaleFade>
  );
};

export default AnimateContainer;
