export const HOME_PAGE = '/';
// Public routes
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const RESET_PASSWORD_PAGE = '/user/reset-password'; // TODO -- Request server to update reset URL
export const REGISTRATION_PAGE = '/register';

// Private routes
export const DASHBOARD_PAGE = '/dashboard';
export const ACTIVATE_ACCOUNT = '/activate-account';
export const ORDERS_PAGE = '/orders';
export const LOCATIONS_PAGE = '/locations';
export const GUESTS_PAGE = '/guests';

export const STORE_INFORMATION_PAGE = '/store-settings';
export const HOLIDAY_HOURS_PAGE = '/holiday-hours';
export const MENU_MANAGER = '/menu-manager';
export const DISCOUNT_PAGE = '/discounts';
export const CREATE_DISCOUNT_PAGE = '/discounts/create';
export const STATEMENTS_PAGE = '/statements';
export const TEAM_PAGE = '/team';
export const INTEGRATIONS_PAGE = '/integrations';
export const SQUARE_INTEGRATION = `${INTEGRATIONS_PAGE}/square`;
export const CLOVER_INTEGRATION = `${INTEGRATIONS_PAGE}/clover`;
export const DOORDASH_INTEGRATION = `${INTEGRATIONS_PAGE}/doordash`;
export const DOORDASH_INTEGRATION_SETTINGS = `${INTEGRATIONS_PAGE}/doordash/settings`;
export const WEB_WIDGET_PAGE = '/web-widget';
export const QR_CODE_PAGE = '/qr-code';
export const MOBILE_APP_PAGE = '/mobile-app';
export const CREATE_QR_CODE_PAGE = '/qr-code/create';
export const DESIGN_QR_CODE_PAGE = '/qr-code/design';
// SETTINGS
export const SETTINGS_PAGE = '/settings';
export const SETTINGS_PAGE_BANK_ACCOUNT = '/settings/bank-account';
export const SETTINGS_PAGE_UPGRADE_PLAN = '/settings/upgrade-plan';

// ----- ON BOARDING PLANS -----
export const BUILD_PLAN = '/build-plan';
export const LOGO_AND_COVER = '/logo-and-cover';
export const ADDRESS_AND_CURRENCY = '/address-and-currency';
export const TAXATION = '/taxation';
export const BUSINESS_HOURS = '/business-hours';
export const LINK_BANK = '/link-bank';
export const PICKUP_SETTINGS = '/pickup-settings';
export const DELIVERY_SETTINGS = '/delivery-settings';
export const TABLE_SIDE_SETTING = '/tableside-settings';
export const RECEIVING_METHOD = '/receiving-method';
