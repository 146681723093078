import moment from 'moment';
import { Discount } from '../types';

const getDiscountChipTextColor = (
  discount: Discount
): { text: string; colorScheme: string } => {
  const { disabled, startsAt, expiresAt } = discount;
  if (moment().isAfter(expiresAt))
    return { text: 'Expired', colorScheme: 'red' };
  if (moment().isBefore(startsAt))
    return { text: 'Scheduled', colorScheme: 'yellow' };
  if (disabled) return { text: 'Disabled', colorScheme: 'gray' };
  return { text: 'Active', colorScheme: 'green' };
};

export default getDiscountChipTextColor;
