import React from 'react';
import moment from 'moment';
import { Interval, Schedule } from '../../types/schedule';
import { CLOCK_FORMATS } from '../../constants/constants';
import { Text, VStack } from '@chakra-ui/react';
import DayScheduleC from './DayScheduleC';

const getNewInterval = (intervals: Interval[]) => {
  const businessStartingTime = moment('09:00 AM', CLOCK_FORMATS.hour12);
  if (intervals.length === 0) {
    return {
      from: businessStartingTime.format(CLOCK_FORMATS.hour12),
      to: moment(businessStartingTime, CLOCK_FORMATS.hour12)
        .add(8, 'hour')
        .format(CLOCK_FORMATS.hour12),
    };
  }

  const lastInterval = intervals[intervals.length - 1];

  let lastIntervalClosingTimeMoment = moment(
    lastInterval.to,
    CLOCK_FORMATS.hour12
  );
  const endOfDay = moment().endOf('day');
  if (lastIntervalClosingTimeMoment.isSame(endOfDay, 'hour')) {
    lastIntervalClosingTimeMoment = moment().startOf('day');
  }

  const newIntervalStartingTimeMoment = lastIntervalClosingTimeMoment.add(
    1,
    'hour'
  );
  const formattedNewIntervalStartingTime = newIntervalStartingTimeMoment.format(
    CLOCK_FORMATS.hour12
  );
  const formattedNewIntervalClosingTime = newIntervalStartingTimeMoment
    .add(1, 'hour')
    .format(CLOCK_FORMATS.hour12);

  return {
    from: formattedNewIntervalStartingTime,
    to: formattedNewIntervalClosingTime,
  };
};

const handleSortIntervals = (intervals: Interval[]) => {
  try {
    return intervals.sort((timeSegment1, timeSegment2) => {
      return (
        //@ts-ignore
        moment(timeSegment1.from, CLOCK_FORMATS.hour12) -
        //@ts-ignore
        moment(timeSegment2.from, CLOCK_FORMATS.hour12)
      );
    });
  } catch (e) {
    return intervals;
  }
};

const scheduleContainerStyle = {
  '& .day-schedule': {
    '&:nth-of-type(1)': {
      order: 7,
      mb: 0,
    },

    '&:nth-of-type(2)': {
      mt: 0,
    },

    '&:nth-of-type(7)': {
      mb: 6,
    },
  },
};

interface ScheduleProps {
  schedule: Schedule[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any;
  errors: any;
  objKey?: string;
}

const ScheduleC = ({
  schedule,
  setFieldValue,
  errors,
  objKey = 'schedule',
}: ScheduleProps) => {
  const onToggleActive = (e: any) => {
    const { value: dayNumber } = e.target;

    const updatedSchedule = schedule.map((scheduleSingle) => {
      if (scheduleSingle.day === Number(dayNumber)) {
        const newValue = !scheduleSingle.active;
        return {
          ...scheduleSingle,
          intervals:
            newValue && scheduleSingle.intervals.length === 0
              ? [getNewInterval(scheduleSingle.intervals)]
              : scheduleSingle.intervals,
          active: newValue,
        };
      }
      return scheduleSingle;
    });

    setFieldValue(objKey, updatedSchedule);
  };

  const handleAddInterval = (dayNumber: number) => {
    const updatedSchedule = schedule.map((scheduleSingle) => {
      if (scheduleSingle.day === dayNumber) {
        const newInterval = getNewInterval(scheduleSingle.intervals);

        return {
          ...scheduleSingle,
          active: scheduleSingle.intervals.length
            ? scheduleSingle.active
            : true,
          intervals: handleSortIntervals([
            ...scheduleSingle.intervals,
            newInterval,
          ]),
        };
      }
      return scheduleSingle;
    });

    setFieldValue(objKey, updatedSchedule);
  };

  const handleDeleteInterval = (dayNumber: number, intervalId: number) => {
    const updatedSchedule = schedule.map((scheduleSingle) => {
      if (scheduleSingle.day === dayNumber) {
        const updatedIntervals = scheduleSingle.intervals.filter(
          (interval, index) => index !== intervalId
        );

        return {
          ...scheduleSingle,
          active: updatedIntervals.length ? scheduleSingle.active : false,
          intervals: updatedIntervals,
        };
      }
      return scheduleSingle;
    });

    setFieldValue(objKey, updatedSchedule);
  };

  const handleIntervalUpdates = ({
    name,
    value,
    dayNumber,
    intervalId,
  }: {
    name: string;
    value: string;
    dayNumber: number;
    intervalId: number;
  }) => {
    const updatedSchedule = schedule.map((scheduleSingle) => {
      if (scheduleSingle.day === dayNumber) {
        const updatedIntervals = scheduleSingle.intervals.map(
          (interval, index) => {
            if (index === intervalId) {
              return { ...interval, [name]: value };
            }

            return interval;
          }
        );

        return {
          ...scheduleSingle,
          intervals: handleSortIntervals(updatedIntervals),
        };
      }
      return scheduleSingle;
    });

    setFieldValue(objKey, updatedSchedule);
  };

  return (
    <>
      {errors[objKey] && (
        <Text fontSize="sm" color="#e53e3e">
          {errors[objKey]}
        </Text>
      )}
      <VStack spacing={6} align="flex-start" sx={scheduleContainerStyle}>
        {schedule.map((daySchedule) => (
          <DayScheduleC
            key={daySchedule.day}
            daySchedule={daySchedule}
            onToggleActive={onToggleActive}
            handleAddInterval={handleAddInterval}
            handleDeleteInterval={handleDeleteInterval}
            handleIntervalUpdates={handleIntervalUpdates}
            errors={errors}
            objKey={objKey}
          />
        ))}
      </VStack>
    </>
  );
};

export default ScheduleC;
