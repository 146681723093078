import { Box, Button, Stack, Text, VStack } from '@chakra-ui/react';
import { Bank } from '../../assets/icons';
import { EditIconC } from '../../theme/overrides/CustomIcons';
import { StripeBankAccount } from '../../types/stripe';

interface BankAccountUpdateProps {
  handleStartLinkBankAccount: () => void;
  isLoading: boolean;
  error?: string;
  stripePayoutBank?: StripeBankAccount;
}

const BankAccountUpdate = ({
  handleStartLinkBankAccount,
  isLoading,
  stripePayoutBank,
}: BankAccountUpdateProps) => {
  return (
    <Stack
      spacing={4}
      border="1px solid rgb(237, 239, 243)"
      borderRadius="15px"
      p={4}
      direction="row"
      align="center"
    >
      <Box>
        <Bank color="#3a1960" width={55} height={55} />
      </Box>
      <VStack align="flex-start" spacing={0} flex={1}>
        <Text>Account ending in {stripePayoutBank?.last4}</Text>
        <Text>
          {stripePayoutBank?.accountHolderName
            ? `${stripePayoutBank.accountHolderName} •`
            : ''}{' '}
          {stripePayoutBank?.bankName}
        </Text>
      </VStack>
      <Button
        leftIcon={<EditIconC />}
        colorScheme="primary"
        variant="ghost"
        height="48px"
        onClick={handleStartLinkBankAccount}
        isLoading={isLoading}
      >
        Change
      </Button>
    </Stack>
  );
};

export default BankAccountUpdate;
