import { Icon } from '@chakra-ui/react';

export function CarSimple(props: any) {
  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <path
        d="M224,120H32L61.89,52.75A8,8,0,0,1,69.2,48H186.8a8,8,0,0,1,7.31,4.75Z"
        opacity="0.2"
        fill="currentColor"
      ></path>
      <path
        fill="currentColor"
        d="M240,112H229.2L201.42,49.5A16,16,0,0,0,186.8,40H69.2a16,16,0,0,0-14.62,9.5L26.8,112H16a8,8,0,0,0,0,16h8v80a16,16,0,0,0,16,16H64a16,16,0,0,0,16-16V192h96v16a16,16,0,0,0,16,16h24a16,16,0,0,0,16-16V128h8a8,8,0,0,0,0-16ZM69.2,56H186.8l24.89,56H44.31ZM216,208H192V184a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8v24H40V128H216Z"
      ></path>
    </Icon>
  );
}
