import { useDisclosure, useToast } from '@chakra-ui/react';
import { FormikHelpers, FormikValues } from 'formik';
import MobileAppForm from '../../components/mobile-app/MobileAppForm';
import MobileAppSubmitted from '../../components/mobile-app/MobileAppSubmitted';
import MobileAppSubmitNew from '../../components/mobile-app/MobileAppSubmitNew';
import useWhiteLabelMobileApp from '../../hooks/useWhiteLabelMobileApp';
import AlertMessage from '../../components/common/ErrorAlert';
import CircularLoader from '../../components/common/CircularLoader';
import crave from '../../apis/crave';
import { useReduxSelector } from '../../store/store';
import { formatApiError } from '../../utils';
import {
  CLIENT_API_URL,
  SERVER_API_URL,
  STRIPE_PUBLISHABLE_KEY,
} from '../../constants/constants';
import { AppStoreConnectStatus, CodeMagicBuildStatus } from '../../constants';
import PageWrapper from '../../components/common/PageWrapper';

const MobileApp = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { whiteLabelMobileApp, loading, error, mutate } =
    useWhiteLabelMobileApp();
  const { user } = useReduxSelector((state) => state.auth);
  const toast = useToast();

  if (error) {
    return <AlertMessage title={error} />;
  }

  if (loading) {
    return <CircularLoader />;
  }

  const manualReleaseRequired =
    whiteLabelMobileApp?.isFirstBuild &&
    whiteLabelMobileApp.buildMessage?.includes('Publishing failed') &&
    whiteLabelMobileApp?.appStoreConnectStatus !== AppStoreConnectStatus.ISSUED;

  const isReleaseInProgress =
    (whiteLabelMobileApp?.buildStatus &&
      whiteLabelMobileApp?.buildStatus !== CodeMagicBuildStatus.FINISHED) ||
    (whiteLabelMobileApp?.buildStatus &&
      whiteLabelMobileApp?.buildStatus === CodeMagicBuildStatus.FINISHED &&
      whiteLabelMobileApp?.appStoreConnectStatus &&
      whiteLabelMobileApp?.appStoreConnectStatus !==
        AppStoreConnectStatus.ISSUED);

  const onSubmit = async (
    values: FormikValues,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    try {
      if (manualReleaseRequired || isReleaseInProgress) {
        toast({
          title: 'An app release is already in progress',
          status: 'info',
          isClosable: true,
        });
        setSubmitting(false);
        return;
      }

      const params = { headers: { Authorization: `Bearer ${user.token}` } };

      const newAppFormData = new FormData();

      newAppFormData.append('primaryColor', values.primaryColor);
      newAppFormData.append(
        'primaryContrastColor',
        values.primaryContrastColor
      );
      newAppFormData.append('secondaryColor', values.secondaryColor);
      newAppFormData.append(
        'secondaryContrastColor',
        values.secondaryContrastColor
      );
      newAppFormData.append('splashScreenBgColor', values.splashScreenBgColor);
      newAppFormData.append('appIcon', values.newAppIcon || values.appIcon);
      newAppFormData.append(
        'splashScreenImage',
        values.splashScreenImage || values.appIcon
      );
      newAppFormData.append('appName', values.appName);
      newAppFormData.append('shortDescription', values.shortDescription);
      newAppFormData.append('fullDescription', values.fullDescription);
      newAppFormData.append('privacyPolicyUrl', values.privacyPolicyUrl);
      newAppFormData.append('baseApiUrl', SERVER_API_URL!);
      newAppFormData.append('baseApiClientUrl', CLIENT_API_URL!);
      newAppFormData.append('stripePublishableKey', STRIPE_PUBLISHABLE_KEY!);

      const { data } = await crave.post(
        `merchants/${user.merchantId}/locations/${user.locationId}/mobile-app`,
        newAppFormData,
        params
      );

      await mutate(data);
      setSubmitting(false);
      onClose();
    } catch (err) {
      const { e } = formatApiError(err);
      setSubmitting(false);
      toast({
        title: e,
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <PageWrapper
      title="Publish Your Branded App to the App Store"
      text="Empower your business with a personalized ordering app for iOS."
    >
      <MobileAppForm
        manualReleaseRequired={manualReleaseRequired}
        isOpen={isOpen}
        onClose={onClose}
        whiteLabelMobileApp={whiteLabelMobileApp}
        onSubmit={onSubmit}
      />

      {whiteLabelMobileApp ? (
        <MobileAppSubmitted
          manualReleaseRequired={manualReleaseRequired}
          isReleaseInProgress={isReleaseInProgress}
          whiteLabelMobileApp={whiteLabelMobileApp}
          onOpen={onOpen}
        />
      ) : (
        <MobileAppSubmitNew onOpen={onOpen} />
      )}
    </PageWrapper>
  );
};

export default MobileApp;
