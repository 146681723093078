import React from 'react';
import './beat-loader.scss';
import { FONT_PRIMARY } from '../../../constants/constants';

const pulseStyle = {
  backgroundColor: '#3a1960',
  width: '1rem',
  height: '1rem',
  borderRadius: '50%',
  animationFillMode: 'both',
  marginRight: '0.2rem',
};

interface BeatLoaderProps {
  load?: boolean;
}

const BeatLoader = ({ load }: BeatLoaderProps) => {
  return (
    <div style={{ paddingTop: load ? 0 : 192 }}>
      {load && (
        <p
          style={{ fontFamily: FONT_PRIMARY, fontWeight: 500, marginBottom: 5 }}
        >
          Loading
        </p>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="ball-pulse"
      >
        <div style={pulseStyle} />
        <div style={pulseStyle} />
        <div style={pulseStyle} />
      </div>
    </div>
  );
};

export default BeatLoader;
