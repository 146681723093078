import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import ChInput from './ChInput';
import { List, ListIcon, ListItem, useToast } from '@chakra-ui/react';
import { HiLocationMarker } from 'react-icons/hi';
import { AutoGeneratedAddress } from '../../types/common';
import { getAddressComponents } from '../../utils';

interface Option {
  description: string;
  place_id: string;
}

interface AddressAutoCompleteProps {
  error?: string;
  value?: string;
  isInvalid?: boolean;
  setAddressValues: (addressInfo: AutoGeneratedAddress) => void;
  setFieldValue?: (key: string, newValue: string) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

const AddressAutoComplete = ({
  value: defaultValue,
  error,
  isInvalid,
  setAddressValues,
  setFieldValue,
  label,
  placeholder,
  disabled,
}: AddressAutoCompleteProps) => {
  const toast = useToast();

  const { value, suggestions, setValue, clearSuggestions } =
    usePlacesAutocomplete({
      debounce: 300,
      defaultValue: defaultValue ?? '',
      requestOptions: {
        componentRestrictions: { country: 'us' },
      },
    });

  const { status, data } = suggestions;

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleSelect =
    ({ place_id }: Option) =>
    async () => {
      try {
        clearSuggestions();

        const details = await getDetails({
          placeId: place_id,
          fields: ['address_components', 'geometry'],
        });

        if (typeof details === 'string') return;

        const addressInfo = getAddressComponents(details);
        if (addressInfo) {
          setAddressValues(addressInfo);
          setValue(addressInfo.street, false);
        } else {
          toast({
            title: 'Something went wrong, please try again',
            status: 'error',
            isClosable: true,
          });
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItem
          key={place_id}
          onClick={handleSelect(suggestion)}
          cursor="pointer"
          px={2.5}
          py={2}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ListIcon as={HiLocationMarker} sx={{ width: 5, height: 5 }} />
          <span>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </span>
        </ListItem>
      );
    });

  return (
    <div ref={ref} style={{ position: 'relative', width: '100%' }}>
      <ChInput
        id="address"
        name="address"
        label={label}
        placeholder={placeholder}
        isInvalid={isInvalid}
        error={error}
        value={value}
        handleChange={(e) => {
          setValue(e.target.value);
          if (setFieldValue) {
            setFieldValue('street', e.target.value);
          }
        }}
        disabled={disabled}
        autoComplete="new-password"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <List
          sx={{
            position: 'absolute',
            top: '80px',
            left: '1px',
            bg: 'white',
            zIndex: 5,
            w: 'full',
            borderRadius: 5,
            shadow: 'xs',
            maxH: '250px',
            overflow: 'auto',
          }}
        >
          {renderSuggestions()}
        </List>
      )}
    </div>
  );
};

export default AddressAutoComplete;
