export enum Integrations {
  SQUARE = 'square',
  CLOVER = 'clover',
  OTTER_X_CLOVER = 'otterxclover',
  OTTER_X_TOAST = 'otterxtoast',
  DOORDASH = 'doordash',
  META_PIXEL = 'metaPixel',
  GOOGLE_ANALYTICS = 'googleAnalytics',
  OTTER = 'otter',
}

export const posIntegrations = [
  Integrations.SQUARE,
  Integrations.OTTER_X_TOAST,
  Integrations.OTTER_X_CLOVER,
  Integrations.CLOVER,
];

export enum IntegrationTypes {
  POS = 'pos',
  DELIVERY = 'delivery',
  MARKETING = 'marketing',
}
