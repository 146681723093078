import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import ChInput from '../common/ChInput';
import { FormikValues, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { updateOnBoarding } from '../../store/slices/onBoarding';
import { useDispatch } from 'react-redux';
import { BUSINESS_HOURS } from '../../routes';
import { useReduxSelector } from '../../store/store';
import CustomCheckbox from '../common/CustomCheckbox';
import { FONT_PRIMARY } from '../../constants/constants';
import isNumeric from 'validator/es/lib/isNumeric';
import AnimateContainer from '../common/AnimateContainer';
import Decimal from 'decimal.js';
import { Info } from '../../assets/icons';

//----------

const validate = (values: FormikValues) => {
  const { tax } = values;

  const errors = {} as { [e: string]: string };

  if (!tax) {
    errors.tax = 'Please enter tax percentage';
  }

  if (tax && !isNumeric(tax)) {
    errors.tax = 'Please enter a valid tax percentage';
  }

  if (
    tax &&
    isNumeric(tax) &&
    (new Decimal(tax || '0').gt('100') || new Decimal(tax || '0').lt('0'))
  ) {
    errors.tax = 'Please enter a valid tax percentage';
  }

  return errors;
};

//----------

const Taxation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { loading, onBoarding } = useReduxSelector((state) => state.onBoarding);
  const { taxPercentage, taxInclusive } = onBoarding;

  const {
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    isValid,
    submitCount,
  } = useFormik({
    initialValues: {
      tax: taxPercentage || '',
      isTaxInclusive: taxInclusive || false,
    },
    validate,
    onSubmit: () => {
      dispatch(
        updateOnBoarding(
          {
            step: 3,
            data: {
              taxPercentage: values.tax,
              taxInclusive: values.isTaxInclusive,
            },
          },
          () => {
            navigate(BUSINESS_HOURS);
          },
          (e: string) => {
            toast({
              title: e,
              status: 'error',
              isClosable: true,
            });
          }
        )
      );
    },
  });

  return (
    <>
      <Box px={{ lg: 6, base: 4 }} sx={{ flex: 1 }} py={6} overflow="auto">
        <AnimateContainer animate>
          <form onSubmit={handleSubmit} id="on-boarding">
            <Heading
              as="h1"
              fontSize={{ lg: '4xl', base: '3xl' }}
              mb={6}
              color="#241c15"
              fontWeight="medium"
            >
              Set up your sales tax
            </Heading>

            <Text fontSize="lg" color="#363c42" mb={6}>
              Enter Your Tax Percentage And Rules
            </Text>

            <Stack
              spacing={3.5}
              align="flex-start"
              direction={{ base: 'column', md: 'row' }}
            >
              <ChInput
                id="tax"
                name="tax"
                placeholder="Tax Percentage"
                isInvalid={!!errors.tax && touched.tax}
                error={errors.tax}
                value={values.tax}
                handleChange={handleChange}
                sx={{ maxWidth: 250 }}
                rightIcon="%"
              />

              <Stack
                sx={{ height: '48px', align: 'center', direction: 'row' }}
                direction="row"
                align="center"
              >
                <CustomCheckbox
                  checked={values.isTaxInclusive}
                  onClick={() =>
                    setFieldValue('isTaxInclusive', !values.isTaxInclusive)
                  }
                  text="Tax is inclusive"
                  textStyle={{
                    fontWeight: 'medium',
                    color: 'rgba(92,92,92,0.8)',
                    fontFamily: FONT_PRIMARY,
                  }}
                />

                <Popover>
                  <PopoverTrigger>
                    <IconButton variant="ghost" aria-label="sales tax info">
                      <Info sx={{ width: 6, height: 6 }} />
                    </IconButton>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <Text fontSize="sm">
                        The amount of sales tax charged on an online purchase is
                        determined by applying the tax rate to the total price
                        of taxable items in an order. Optionally, you can
                        include tax in the item price, charge tax on shipping
                        and delivery fees and use item-level tax rates for
                        pickup, delivery, and self-serve orders.
                      </Text>
                      <Divider my={4} />
                      <Text fontSize="xs">
                        DISCLAIMER: <br /> Tax compliance is your
                        responsibility. We do not guarantee the applicability or
                        accuracy of our tax tools. If you have any questions
                        about your tax obligations, you should consult a
                        professional tax advisor.
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Stack>
            </Stack>
          </form>
        </AnimateContainer>
      </Box>
      <Flex
        borderTop={1}
        borderColor="gray.200"
        borderStyle="solid"
        p={6}
        justify="flex-end"
      >
        <Button
          isLoading={loading}
          disabled={(!isValid && submitCount > 0) || loading}
          type="submit"
          form="on-boarding"
          size="lg"
          variant="primary"
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default Taxation;
