export const toggleWidgetPreview = (show) => {
  try {
    if (show) {
      window.zE('webWidget', 'show');
    } else {
      window.zE('webWidget', 'hide');
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export const authenticateZendeskUser = (chatToken) => {
  try {
    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: (callback) => {
              callback(chatToken);
            },
          },
        },
      },
    };
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
