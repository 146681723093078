export default function Menu(theme) {
  return {
    MuiMenu: {
      defaultProps: {},
      styleOverrides: {},
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          paddingLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(0.5),

          '&:hover': {
            backgroundColor: theme.palette.grey[100],
          },

          '&.Mui-selected': {
            backgroundColor: theme.palette.grey[100],
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
            },
          },
        },
      },
    },
  };
}
