import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTIVATE_ACCOUNT, DASHBOARD_PAGE } from '../../routes';
import { useReduxSelector } from '../../store/store';
import getStepsStatus from '../../utils/getStepsStatus';

const Home = () => {
  const navigate = useNavigate();
  const { user } = useReduxSelector((state) => state.auth);

  useEffect(() => {
    const { isAllStepsCompleted } = getStepsStatus(user);

    if (isAllStepsCompleted) {
      navigate(DASHBOARD_PAGE);
    } else {
      navigate(ACTIVATE_ACCOUNT);
    }
  }, []);

  return <div />;
};

export default Home;
