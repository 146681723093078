import { useRef } from 'react';
import { Box, Image, Text, VStack } from '@chakra-ui/react';
import Slider from 'react-slick';

// SLICK CAROUSEL
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// LOGOS
import hrhLogoWhite from '../../assets/images/register/hrh-white.svg';
import brassBearLogoWhite from '../../assets/images/register/brass-bear-white.png';
import shrimpShackLogoWhite from '../../assets/images/register/shrimpshack-white.png';
import hrhLogoBlack from '../../assets/images/register/hrl-black.svg';
import brassBearLogoBlack from '../../assets/images/register/brass-bear-black.png';
import shrimpShackLogoBlack from '../../assets/images/register/shrimpshack-black.png';
import { FONT_TERTIARY } from '../../constants/constants';

//--------------- SLIDER SETTINGS ---------------

const SLICK_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoPlay: true,
  autoplay: true,
  autoplaySpeed: 2500,
  pauseOnHover: true,
};

//--------------- SLIDER STYLE ---------------

const getStyles = (type?: number) => ({
  '& .slick-dots': {
    bottom: '-2rem',
    '& li': {
      m: 0,
      '& button': {
        '&:hover': {
          color: type === 1 ? '#333333' : 'white',
          opacity: 0.5,
        },
        '&:before': {
          fontSize: '0.5rem',
          color: type === 1 ? '#333333' : 'white',
          opacity: 0.3,
        },
      },
      '&.slick-active': {
        '& button': {
          '&:before': {
            color: type === 1 ? '#333333' : 'white',
            opacity: 1,
          },
        },
      },
    },
  },
});

const SliderSingle = ({
  slider,
  type,
}: {
  slider: { text: string; img: string };
  type?: number;
}) => (
  <VStack spacing={6}>
    <Image boxSize="100px" objectFit="contain" src={slider.img} alt="logo" />

    <Text
      fontSize="sm"
      color={type === 1 ? '#333333' : 'white'}
      fontWeight="bold"
      fontFamily={FONT_TERTIARY}
    >
      {slider.text}
    </Text>
  </VStack>
);

const MerchantsSliders = ({ type }: { type?: number }) => {
  const SLIDERS = useRef([
    {
      text: '“Well, after just a few hours the hotel loves it so much they are very anxious to deploy it to Tropicana Poolside Bar.”',
      img: type === 1 ? hrhLogoBlack : hrhLogoWhite,
    },
    {
      text: '“Having Crave was a huge help when we moved from 25 indoor seats to 100+ seats. It meant our customers did not get up from their seats to put a food or drink order in.”',
      img: type === 1 ? brassBearLogoBlack : brassBearLogoWhite,
    },
    {
      text: '“Crave is the future of ordering at Haven City Market. Giving guests the opportunity to combine many vendors into one ordering flow creates an easy experience for everyone.”',
      img: type === 1 ? shrimpShackLogoBlack : shrimpShackLogoWhite,
    },
  ]).current;

  return (
    <Box w="full" sx={getStyles(type)}>
      <Slider {...SLICK_SETTINGS}>
        {SLIDERS.map((slider, index) => (
          <SliderSingle key={String(index)} slider={slider} type={type} />
        ))}
      </Slider>
    </Box>
  );
};

export default MerchantsSliders;
