import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import DialogC from '../common/DialogC';
import React from 'react';
import useUser from '../../hooks/useUser';
import {
  Bicycle,
  CaretDown,
  ClockCounterClockwise,
  List,
  ShoppingBag,
} from '../../assets/icons';

import burgerImg from '../../assets/images/mobile-app/burger.png';
import saladImg from '../../assets/images/mobile-app/salad.png';

interface mobilePreviewFormProps {
  isOpen: boolean;
  onClose: () => void;
  primaryColor: string;
  primaryContrastColor: string;
  splashScreenBgColor: string;
  appIcon: string;
}

const HomeScreen = ({
  primaryColor,
  primaryContrastColor,
}: {
  primaryColor: string;
  primaryContrastColor: string;
}) => {
  const { currentLocation } = useUser();
  const { backgroundUrl, locationDisplayName, description } = currentLocation;

  return (
    <Stack w="full" h="full" bg="#fff" spacing={0}>
      <Box
        height="320px"
        w="full"
        bgImage={`url(${backgroundUrl})`}
        bgSize="cover"
      >
        <HStack justify="space-between" px={3} py={3}>
          <IconButton
            aria-label="List"
            icon={<List width="28px" height="28px" />}
            bg="#ffffff"
            _hover={{ bg: '#ffffff' }}
            _active={{ bg: '#ffffff' }}
            borderRadius="100px"
          />
          <Button
            bg="#fff"
            _hover={{ bg: '#fff' }}
            _active={{ bg: '#fff' }}
            fontSize="sm"
            py={2}
            height="48px"
            color="#333333"
            borderRadius="100px"
            borderColor="#000"
            iconSpacing={2.5}
            leftIcon={
              <ClockCounterClockwise
                width="22px"
                height="22px"
                color={primaryColor}
              />
            }
          >
            History
          </Button>
        </HStack>
      </Box>
      <Stack flex={1} justify="space-between">
        <Box
          bg="#fff"
          mt="-15px !important"
          borderRadius="15px 15px 0 0"
          px={3}
          py={5}
        >
          <Heading fontSize="2xl">{locationDisplayName}</Heading>
          {description && (
            <Text mt={1.5} fontSize="sm">
              {description}
            </Text>
          )}

          <HStack justify="space-between" mt={6}>
            <VStack align="start" spacing={1}>
              <Text fontSize="xs" color="#73767d">
                Date & time
              </Text>
              <Text fontSize="sm" color="#333333">
                Now
              </Text>
            </VStack>

            <Button
              py="4.5px"
              px="30px"
              height="36px"
              borderRadius={20}
              fontSize="sm"
              bg={primaryColor}
              color={primaryContrastColor}
              _hover={{ color: primaryContrastColor, bg: primaryColor }}
              _active={{ color: primaryContrastColor, bg: primaryColor }}
            >
              Edit
            </Button>
          </HStack>
        </Box>

        <Stack pb={7}>
          <Heading
            textAlign="center"
            mb={6}
            fontSize="22px"
            color="#333333"
            fontWeight="600"
          >
            Choose An Order Type
          </Heading>
          <HStack justify="center" spacing={4}>
            <Button
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              width="165px"
              fontSize="sm"
              px={7}
              py={2}
              height="48px"
              color="#333333"
              borderRadius="100px"
              borderColor="#000"
              iconSpacing={2.5}
              variant="outline"
              leftIcon={
                <ShoppingBag width="28px" height="28px" color={primaryColor} />
              }
            >
              Pickup-Up
            </Button>
            <Button
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              width="165px"
              fontSize="sm"
              px={7}
              py={2}
              height="48px"
              color="#333333"
              borderRadius="100px"
              borderColor="#000"
              iconSpacing={2.5}
              variant="outline"
              leftIcon={
                <Bicycle width="28px" height="28px" color={primaryColor} />
              }
            >
              Delivery
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ProductItem = ({
  title,
  description,
  bgImage,
}: {
  title: string;
  description: string;
  bgImage: string;
}) => {
  return (
    <Stack
      height="324px"
      boxShadow="1px 1px 3px 1px rgba(0,0,0,0.12)"
      borderRadius="15px"
      overflow="hidden"
      spacing={0}
    >
      <Box
        height="178px"
        bgImage={`url(${bgImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
      />

      <Stack flex={1} justify="space-between" px={4} pb={4} pt={2.5}>
        <Box>
          <Heading color="#333333" fontSize="20">
            {title}
          </Heading>
          <Text mt={2.5} color="#696969" fontSize="14">
            {description}
          </Text>
        </Box>
        <Text mt={2.5} color="#696969" fontSize="20" fontWeight="bold">
          $ 13.00
        </Text>
      </Stack>
    </Stack>
  );
};

const ProductsScreen = ({
  primaryColor,
  primaryContrastColor,
}: {
  primaryColor: string;
  primaryContrastColor: string;
}) => {
  const { currentLocation } = useUser();
  const { locationDisplayName } = currentLocation;

  return (
    <Stack w="full" h="full" bg="#fff" spacing={0} px={4} py={5}>
      <HStack justify="space-between">
        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray" fontWeight="600">
            Delivery from
          </Text>
          <Heading fontSize="24" noOfLines={1}>
            {locationDisplayName}
          </Heading>
        </VStack>
        <HStack width={'150px'}>
          <Text fontSize="sm" color="#333333" fontWeight="600">
            All day menu
          </Text>
          <CaretDown />
        </HStack>
      </HStack>

      <HStack spacing={2.5} shouldWrapChildren my="16px !important">
        <Button
          fontWeight="600"
          py="4.5px"
          px="15px"
          height="36px"
          borderRadius={5}
          fontSize="sm"
          bg={primaryColor}
          color={primaryContrastColor}
          _hover={{ color: primaryContrastColor, bg: primaryColor }}
          _active={{ color: primaryContrastColor, bg: primaryColor }}
        >
          Popular
        </Button>
        <Button
          fontWeight="400"
          py="4.5px"
          px="15px"
          height="36px"
          borderRadius={5}
          fontSize="sm"
          bg="#fff"
          color="#333333"
          _hover={{ color: '#333333', bg: '#ffffff' }}
          _active={{ color: '#333333', bg: '#ffffff' }}
        >
          Appetizers
        </Button>
        <Button
          fontWeight="400"
          py="4.5px"
          px="15px"
          height="36px"
          borderRadius={5}
          fontSize="sm"
          bg="#fff"
          color="#333333"
          _hover={{ color: '#333333', bg: '#ffffff' }}
          _active={{ color: '#333333', bg: '#ffffff' }}
        >
          Salads
        </Button>
        <Button
          fontWeight="400"
          py="4.5px"
          px="15px"
          height="36px"
          borderRadius={5}
          fontSize="sm"
          bg="#fff"
          color="#333333"
          _hover={{ color: '#333333', bg: '#ffffff' }}
          _active={{ color: '#333333', bg: '#ffffff' }}
        >
          Sandwiches
        </Button>
        <Button
          fontWeight="400"
          py="4.5px"
          px="15px"
          height="36px"
          borderRadius={5}
          fontSize="sm"
          bg="#fff"
          color="#333333"
          _hover={{ color: '#333333', bg: '#ffffff' }}
          _active={{ color: '#333333', bg: '#ffffff' }}
        >
          Desserts
        </Button>
      </HStack>

      <Stack spacing={5}>
        <Heading mt={2} fontSize="26" noOfLines={1}>
          Popular
        </Heading>
        <Stack spacing={2.5}>
          <ProductItem
            title="Burger"
            description="I have burger in my restaurant menu could you give me a description for this?"
            bgImage={burgerImg}
          />
          <ProductItem
            title="Salad"
            description="Our salad is a delicious combination of fresh greens, crisp vegetables, and flavorful toppings."
            bgImage={saladImg}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const MobileWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      mb="40px !important"
      userSelect="none"
      overflow="hidden"
      height="812px"
      width="375px"
      boxShadow="0 20px 30px rgba(0,0,0,0.3)"
      borderRadius={20}
    >
      {children}
    </Box>
  );
};

const MobileAppPreview = (props: mobilePreviewFormProps) => {
  const { isOpen, onClose, appIcon, splashScreenBgColor } = props;

  return (
    <DialogC title="App Preview" open={isOpen} onClose={onClose} size="4xl">
      <Stack flexWrap="wrap" justifyContent="space-between" direction="row">
        <MobileWrapper>
          <Stack
            w="full"
            h="full"
            bg={splashScreenBgColor}
            justify="center"
            align="center"
          >
            <Box
              w="150px"
              h="150px"
              sx={{
                backgroundImage: `url(${appIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          </Stack>
        </MobileWrapper>

        <MobileWrapper>
          <HomeScreen {...props} />
        </MobileWrapper>

        <MobileWrapper>
          <ProductsScreen {...props} />
        </MobileWrapper>
      </Stack>
    </DialogC>
  );
};

export default MobileAppPreview;
