import { Subscription } from '../types/user';
import { StripePlans } from '../constants';

export const isStarterPlan = (subscription: Subscription) => {
  const STARTER_PLANS = [
    StripePlans.STARTER_YEARLY,
    StripePlans.STARTER_MONTHLY,
  ];
  return STARTER_PLANS.includes(subscription?.selectedPlanStripeId);
};
