import React from 'react';
import { Button, Flex, RadioGroup, Stack, Text } from '@chakra-ui/react';

import {
  ADDRESS_AND_CURRENCY,
  BUSINESS_HOURS,
  DELIVERY_SETTINGS,
  LINK_BANK,
  LOGO_AND_COVER,
  PICKUP_SETTINGS,
  RECEIVING_METHOD,
  TABLE_SIDE_SETTING,
  TAXATION,
} from '../../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReduxSelector } from '../../store/store';

export const ROUTES = [
  { name: 'Logo And Cover Image', path: LOGO_AND_COVER },
  { name: 'Address And Currency', path: ADDRESS_AND_CURRENCY },
  { name: 'Sales Tax Rates', path: TAXATION },
  { name: 'Business Hours', path: BUSINESS_HOURS },
  { name: 'Link Your Bank Account', path: LINK_BANK },
  { name: 'Pickup Settings And Hours', path: PICKUP_SETTINGS },
  { name: 'Delivery Settings And Hours', path: DELIVERY_SETTINGS },
  { name: 'Tableside QR Settings And Hours', path: TABLE_SIDE_SETTING },
  { name: 'Receive Orders From Crave', path: RECEIVING_METHOD },
];

const OnBoardingSteps = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { onBoarding } = useReduxSelector((state) => state.onBoarding);
  const { step = 0 } = onBoarding;

  return (
    <Flex
      p={6}
      w="400px"
      borderRight={1}
      borderColor="gray.200"
      borderStyle="solid"
      display={{ lg: 'flex', base: 'none' }}
    >
      <RadioGroup defaultValue="2">
        <Stack spacing={5}>
          {ROUTES.map((route, index) => {
            const active = pathname.indexOf(route.path) !== -1;
            return (
              <Button
                key={route.name}
                sx={{ justifyContent: 'flex-start', bg: 'transparent' }}
                onClick={() => navigate(route.path)}
                disabled={step !== index}
              >
                <Stack
                  border="1px solid black"
                  w="24px"
                  h="24px"
                  borderRadius="50%"
                  align="center"
                  justify="center"
                  _before={
                    active
                      ? {
                          content: '""',
                          w: '20px',
                          h: '20px',
                          bg: '#3a1960',
                          display: 'inline-block',
                          borderRadius: '50%',
                        }
                      : undefined
                  }
                  mr="2.5"
                />

                <Text fontSize="lg" color="#808080" fontWeight="normal">
                  {route.name}
                </Text>
              </Button>
            );
          })}
        </Stack>
      </RadioGroup>
    </Flex>
  );
};

export default OnBoardingSteps;
