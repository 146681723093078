import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  NumberInputField,
  Stack,
  Text,
  useToast,
  NumberInput,
  FormLabel,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import { FormikValues, useFormik } from 'formik';
import { defaultSchedule, FONT_PRIMARY } from '../../constants/constants';
import ScheduleC from './ScheduleC';
import ConfirmButton from './common/ConfirmButtons';
import { CheckCircleIcon } from '../../theme/overrides/CustomIcons';
import { IoBanOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { updateOnBoarding } from '../../store/slices/onBoarding';
import { TABLE_SIDE_SETTING } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { useReduxSelector } from '../../store/store';
import AnimateContainer from '../common/AnimateContainer';
import { DEFAULT_DELIVERY_FEE, DEFAULT_DELIVERY_RADIUS } from '../../constants';
import { getMeters, getMiles, validateDeliverySettings } from '../../utils';
import CustomCheckbox from '../common/CustomCheckbox';
import OnBoardingDeliveryZone from './OnBoardingDeliveryZone';

const BUTTONS = [
  {
    icon: <CheckCircleIcon sx={{ width: 5, height: 5 }} />,
    text: 'Yes',
    description: 'I would like to offer commission-free, direct delivery',
    value: true,
  },
  {
    icon: <IoBanOutline />,
    text: 'No',
    description:
      'I am not interested in offering commission-free direct delivery',
    value: false,
  },
];

const validate = (values: FormikValues) => {
  if (!values.enabled) {
    return {};
  }

  return validateDeliverySettings(values);
};

const DeliverySettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [isHoursSameAsBusiness, setIsHoursSameAsBusiness] = useState(false);

  const { loading, onBoarding } = useReduxSelector((state) => state.onBoarding);
  const { delivery, businessHours } = onBoarding;
  const hours = delivery?.settings?.hours;
  const fee = delivery?.settings?.fee;
  const radius = delivery?.settings?.radius;
  const address = delivery?.settings?.address;
  const addressData = delivery?.settings?.addressData;
  const lat = delivery?.settings?.lat;
  const lng = delivery?.settings?.lng;

  const formik = useFormik({
    initialValues: {
      enabled: delivery?.enabled ?? true,
      schedule: hours && hours.length > 0 ? hours : defaultSchedule,
      fee: fee || DEFAULT_DELIVERY_FEE,
      radius: Number(
        getMiles(radius ? Number(radius) : Number(DEFAULT_DELIVERY_RADIUS))
      ),
      address: address || '',
      lat: lat || '',
      lng: lng || '',
      street: addressData?.street || '',
      streetOptional: addressData?.streetOptional || '',
      zipCode: addressData?.zipCode || '',
      country: addressData?.country || '',
      city: addressData?.city || '',
      state: addressData?.state || '',
    },
    validate,
    onSubmit: (values) => {
      const delivery = {
        enabled: values.enabled,
        ...(values.enabled && {
          settings: {
            fee: values.fee,
            address: values.address,
            addressData: {
              street: values.street,
              streetOptional: values.streetOptional,
              city: values.city,
              state: values.state,
              zipCode: values.zipCode,
              country: values.country,
            },
            radius: getMeters(values.radius),
            lat: String(values.lat),
            lng: String(values.lng),
            hours: values.schedule,
          },
        }),
      };

      dispatch(
        updateOnBoarding(
          {
            step: 7,
            data: { delivery },
          },
          () => {
            navigate(TABLE_SIDE_SETTING);
          },
          (e: string) => {
            toast({
              title: e,
              status: 'error',
              isClosable: true,
            });
          }
        )
      );
    },
  });
  const {
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    isValid,
    submitCount,
    setValues,
  } = formik;

  const setDeliveryValues = (deliveryValues: FormikValues) => {
    setValues((prevState) => ({
      ...prevState,
      address: deliveryValues.address,
      city: deliveryValues.city,
      country: deliveryValues.country,
      fee: deliveryValues.fee,
      lat: deliveryValues.lat,
      lng: deliveryValues.lng,
      radius: deliveryValues.radius,
      state: deliveryValues.state,
      street: deliveryValues.street,
      streetOptional: deliveryValues.streetOptional,
      zipCode: deliveryValues.zipCode,
    }));
  };

  return (
    <>
      <Box px={{ lg: 6, base: 4 }} sx={{ flex: 1 }} py={6} overflow="auto">
        <AnimateContainer animate>
          <form onSubmit={handleSubmit} id="on-boarding">
            <Heading
              as="h1"
              fontSize={{ lg: '4xl', base: '3xl' }}
              mb={4}
              color="#241c15"
              fontWeight="medium"
            >
              Delivery Settings & Hours
            </Heading>

            <Text fontSize="xl" fontWeight="medium" color="#363c42">
              Would you like to offer direct delivery?
            </Text>
            <Text fontSize="lg" color="rgba(92,92,92,0.8)" mb={1}>
              You can select your delivery hours, zone and delivery fee.
            </Text>

            <ConfirmButton
              value={Boolean(values.enabled)}
              setValue={(value) => setFieldValue('enabled', value)}
              buttons={BUTTONS}
            />
            {values.enabled && (
              <>
                <CustomCheckbox
                  checked={isHoursSameAsBusiness}
                  onClick={() => {
                    setIsHoursSameAsBusiness(!isHoursSameAsBusiness);
                    setFieldValue(
                      'schedule',
                      !isHoursSameAsBusiness ? businessHours : defaultSchedule
                    );
                  }}
                  text="Delivery hours are the same as my business hours."
                  textStyle={{
                    fontWeight: '400',
                    color: '#363c42',
                    fontSize: 'lg',
                    fontFamily: FONT_PRIMARY,
                    mb: 4,
                  }}
                />

                <ScheduleC
                  schedule={values.schedule}
                  errors={submitCount > 0 ? errors : {}}
                  setFieldValue={setFieldValue}
                />
                <Stack mt={6} spacing={2.5}>
                  <Box>
                    <Text fontSize="xl" fontWeight="medium" color="#363c42">
                      What is the minimum order amount for pickup orders?
                    </Text>
                    <Text fontSize="lg" color="#363c42" mb={1}>
                      You can change this later in Settings.
                    </Text>
                  </Box>

                  <FormControl isInvalid={!!errors.fee && touched.fee}>
                    <FormLabel htmlFor="fee">Enter delivery fee</FormLabel>
                    <InputGroup>
                      <NumberInput
                        defaultValue={values.fee}
                        precision={2}
                        step={0.2}
                        size="lg"
                        sx={{ width: '100%' }}
                        onChange={(valueAsString) =>
                          setFieldValue('fee', valueAsString)
                        }
                      >
                        <NumberInputField
                          pl={8}
                          id="fee"
                          name="fee"
                          value={values.fee}
                          placeholder="Delivery fee"
                        />
                      </NumberInput>
                      <InputLeftElement h="full">$</InputLeftElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.fee}</FormErrorMessage>
                  </FormControl>

                  <Box>
                    <OnBoardingDeliveryZone
                      data={{
                        street: values.street,
                        streetOptional: values.streetOptional,
                        zipCode: values.zipCode,
                        country: values.country,
                        city: values.city,
                        state: values.state,
                        lat: values.lat,
                        lng: values.lng,
                        radius: values.radius,
                        fee: values.fee,
                      }}
                      setDeliveryValues={setDeliveryValues}
                    />

                    {errors.street && submitCount > 0 && (
                      <Text fontSize="sm" color="#e53e3e" mt={2.5}>
                        Please add a delivery zone
                      </Text>
                    )}
                  </Box>
                </Stack>
              </>
            )}
          </form>
        </AnimateContainer>
      </Box>
      <Flex
        borderTop={1}
        borderColor="gray.200"
        borderStyle="solid"
        p={6}
        justify="flex-end"
      >
        <Button
          isLoading={loading}
          disabled={(!isValid && submitCount > 0) || loading}
          type="submit"
          form="on-boarding"
          size="lg"
          variant="primary"
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default DeliverySettings;
