export const validateImageForStores = (file: File) => {
  return new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx!.drawImage(img, 0, 0);
      const imageData = ctx!.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      for (let i = 3; i < data.length; i += 4) {
        if (data[i] < 255) {
          reject('Image contains transparency or alpha channel');
          return;
        }
      }
      resolve();
    };
    img.onerror = function () {
      reject('Invalid image');
    };
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      // @ts-ignore
      img.src = event.target.result;
    };
  });
};
