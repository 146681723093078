import moment from 'moment';
import { HolidayHour } from '../types/holiday-hour';
import { CLOCK_FORMATS } from '../constants/constants';

export const validateHolidayHours = (values: HolidayHour) => {
  const { date, allDay, startTime, endTime } = values;

  const errors: { [e: string]: string } = {};

  if (!date) {
    errors.date = 'Please select date for holiday hours';
  }

  if (allDay) {
    return errors;
  }

  if (!startTime) {
    errors.startTime = 'Please select start time';
  }

  if (!endTime) {
    errors.endTime = 'Please select end time';
  }

  const startTimeMoment = moment(startTime, CLOCK_FORMATS.hour12);
  const endTimeMoment = moment(endTime, CLOCK_FORMATS.hour12);

  if (startTime && endTime && !startTimeMoment.isSameOrBefore(endTimeMoment)) {
    errors.endTime = 'Choose an end time later than the start time.';
  }

  return errors;
};
