import axios from 'axios';
import { CRAVE_API_URL } from '../apis/crave';

export const getGoogleUser = async (credential: string) => {
  try {
    const { data } = await axios.post(`${CRAVE_API_URL}/oauth/google/user`, {
      token: credential,
    });
    return data;
  } catch (e) {
    return null;
  }
};
