// ----------------------------------------------------------------------

import { alpha } from '@mui/material/styles';

export default function Table(theme) {
  return {
    MuiTable: {
      styleOverrides: {
        root: {
          // '&.Mui-selected': {
          //   backgroundColor: theme.palette.action.selected,
          //   '&:hover': {
          //     backgroundColor: theme.palette.action.hover,
          //   },
          // },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.grey[100]}`,
          '&:last-of-type': {
            borderBottom: 'none',
          },
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: alpha(theme.palette.grey[100], 0.3),
            },
          },
          '&.Mui-selected': {
            '&:hover': {},
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        head: {
          backgroundColor: alpha(theme.palette.grey[100], 0.3),
          '&:first-of-type': {},
          '&:last-of-type': {},
        },
        stickyHeader: {},
        body: {
          '&:first-of-type': {},
          '&:last-of-type': {},
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: -4,
        },
      },
    },
  };
}
