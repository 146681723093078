import React from 'react';
import {
  Box,
  Image,
  HStack,
  Button,
  SystemStyleObject,
} from '@chakra-ui/react';
import logo from '../../assets/images/new-logo.svg';
import { BRAND_NAME, NAVBAR_HEIGHT } from '../../constants/constants';
import AnimateContainer from './AnimateContainer';

interface LayoutCProps {
  children?: React.ReactNode;
  onClick?: () => void;
  buttonText?: string;
  activeStep?: number;
  sx?: SystemStyleObject;
  animate?: boolean;
  buttonVariant?: string;
}

const steps = [0, 1];

const LayoutC = ({
  children,
  onClick,
  buttonText,
  activeStep,
  sx,
  animate = true,
  buttonVariant = 'primary',
}: LayoutCProps) => (
  <>
    <HStack
      as="nav"
      sx={{
        p: 4,
        height: NAVBAR_HEIGHT,
        borderBottom: '1px solid',
        borderColor: 'rgba(0,0,0,0.08)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: 'full',
        zIndex: 2,
        bg: 'white',
      }}
      justify="space-between"
    >
      <Image
        height="auto"
        width="97px"
        alt={BRAND_NAME}
        objectFit={'contain'}
        src={logo}
      />

      {activeStep !== undefined && (
        <HStack spacing={6} display={{ base: 'none', lg: 'flex' }}>
          {steps.map((step) => (
            <Box
              width="100px"
              height="15px"
              bgColor={activeStep === step ? '#3a1960' : '#dcd9d6'}
              borderRadius="15px"
            />
          ))}
        </HStack>
      )}

      {onClick && (
        <Button
          variant={buttonVariant}
          onClick={onClick}
          fontSize="sm"
          fontWeight="normal"
          px={7}
          bg={buttonVariant === 'outline' ? 'white' : undefined}
          color={buttonVariant === 'outline' ? '#1a202c' : undefined}
        >
          {buttonText}
        </Button>
      )}
    </HStack>

    <AnimateContainer animate={animate}>
      <Box pt={NAVBAR_HEIGHT} sx={{ minHeight: '100vh', ...sx }}>
        {children}
      </Box>
    </AnimateContainer>
  </>
);

export default LayoutC;
