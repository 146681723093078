import { ComponentStyleConfig } from '@chakra-ui/react';
import { FONT_SECONDARY } from '../../constants/constants';

export const ComponentStyle: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    lg: {
      h: 14,
      borderRadius: '15px',
    },
    md: {
      borderRadius: '10px',
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: {
      bg: '#4556dc',
      color: 'white',
      fontSize: 'md',
      letterSpacing: 0,
      px: 8,
      fontWeight: '500',
      fontFamily: FONT_SECONDARY,

      _hover: {
        bg: 'rgb(82,99,225)',
        color: 'white',
        _disabled: {
          bg: 'rgb(217, 217, 217)',
          color: 'rgba(92,92,92,0.8)',
        },
      },
      _disabled: {
        bg: 'rgb(217, 217, 217)',
        color: 'rgba(92,92,92,0.8)',
      },
    },
    primaryOutline: {
      bg: '#fff',
      color: '#4556dc',
      border: '1px solid #4556dc',
      fontSize: 'md',
      letterSpacing: 0,
      px: 8,
      fontWeight: '500',
      fontFamily: FONT_SECONDARY,

      _hover: {
        bg: '#fff',
        color: '#4556dc',
        opacity: 0.8,
        _disabled: {
          opacity: 1,
          bg: '#fff',
          color: 'rgba(92,92,92,0.8)',
          borderColor: 'rgba(92,92,92,0.8)',
        },
      },
      _active: {
        opacity: 1,
      },
      _disabled: {
        bg: '#fff',
        color: 'rgba(92,92,92,0.8)',
        borderColor: 'rgba(92,92,92,0.8)',
      },
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {},
};
