import React from 'react';
import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '../chakraTheme/theme';
import BeatLoader from '../components/common/BeatLoader/BeatLoader';

import NotFoundPage from '../pages/public/404';

const loadableOptions = {
  fallback: <BeatLoader />,
};

const Login = loadable(() => import('../pages/public/Login'), loadableOptions);
const AdminLoginAsUser = loadable(
  () => import('../pages/public/AdminLoginAsUser'),
  loadableOptions
);
const ForgotPassword = loadable(
  () => import('../pages/public/ForgotPassword'),
  loadableOptions
);
const ResetPassword = loadable(
  () => import('../pages/public/ResetPassword'),
  loadableOptions
);
const Register = loadable(
  () => import('../pages/public/register'),
  loadableOptions
);
const VerifyInvitation = loadable(
  () => import('../pages/public/verify'),
  loadableOptions
);

function Public() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="admin-login-as-user" element={<AdminLoginAsUser />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="user/reset-password" element={<ResetPassword />} />
        <Route path="register" element={<Register />} />
        <Route path="verify/:verificationCode" element={<VerifyInvitation />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ChakraProvider>
  );
}

export default Public;
