import React from 'react';
import {
  ACTIVATE_ACCOUNT,
  CREATE_QR_CODE_PAGE,
  DASHBOARD_PAGE,
  DESIGN_QR_CODE_PAGE,
  DISCOUNT_PAGE,
  GUESTS_PAGE,
  HOLIDAY_HOURS_PAGE,
  INTEGRATIONS_PAGE,
  LOCATIONS_PAGE,
  MENU_MANAGER,
  MOBILE_APP_PAGE,
  ORDERS_PAGE,
  QR_CODE_PAGE,
  STATEMENTS_PAGE,
  STORE_INFORMATION_PAGE,
  TEAM_PAGE,
  WEB_WIDGET_PAGE,
} from './index';
import {
  ArrowSquareOutCustomBold,
  CalendarCustom,
  ChatCenteredDots,
  CheckCustom,
  Desktop,
  MapTrifoldCustom,
  MoneyCustom,
  Notepad,
  Percent,
  Plug,
  QrCode,
  Tray,
  Users,
} from '../assets/icons';
import { User } from '../types/user';
import { DashboardPageIds, MemberRoles, Plans } from '../constants';
import { DeviceMobile } from '../assets/icons/device-mobile';
import { ChartBar } from '../assets/icons/chart';
import { Gear } from '../assets/icons/gear';

// ----------------------------------------------------------------------

export interface LinkItemProps {
  name: string;
  path?: string;
  icon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  id: DashboardPageIds;
}

const dashboard = {
  name: 'Dashboard',
  path: DASHBOARD_PAGE,
  icon: <ChartBar />,
  id: DashboardPageIds.HOME,
};

const activateAccount = {
  name: 'Activate your account',
  path: ACTIVATE_ACCOUNT,
  icon: <CheckCustom />,
  id: DashboardPageIds.ACTIVATE_ACCOUNT,
};

const orders = {
  name: 'Orders',
  path: ORDERS_PAGE,
  icon: <Tray />,
  id: DashboardPageIds.ORDERS,
};

const customers = {
  name: 'Customers',
  path: GUESTS_PAGE,
  icon: <Users />,
  id: DashboardPageIds.CUSTOMER,
};

const locations = {
  name: 'Locations',
  path: LOCATIONS_PAGE,
  icon: <MapTrifoldCustom />,
  id: DashboardPageIds.LOCATIONS,
};

const statements = {
  name: 'Statements',
  path: STATEMENTS_PAGE,
  icon: <MoneyCustom />,
  id: DashboardPageIds.STATEMENTS,
};

const menuEditor = {
  name: 'Menu Editor',
  path: MENU_MANAGER,
  icon: <Notepad />,
  id: DashboardPageIds.MENU_EDITOR,
};

const holidayHours = {
  name: 'Holiday Hours',
  path: HOLIDAY_HOURS_PAGE,
  icon: <CalendarCustom />,
  id: DashboardPageIds.HOLIDAY_HOURS,
};
const discounts = {
  name: 'Discounts',
  path: DISCOUNT_PAGE,
  icon: <Percent />,
  id: DashboardPageIds.DISCOUNTS,
};
const integrations = {
  name: 'Integrations',
  path: INTEGRATIONS_PAGE,
  icon: <Plug />,
  id: DashboardPageIds.INTEGRATIONS,
};
const team = {
  name: 'Team',
  path: TEAM_PAGE,
  icon: <Users />,
  id: DashboardPageIds.TEAM,
};
const storeSettings = {
  name: 'Store Settings',
  path: STORE_INFORMATION_PAGE,
  icon: <Gear />,
  id: DashboardPageIds.STORE_SETTINGS,
};
const mobileApp = {
  name: 'Mobile App',
  path: MOBILE_APP_PAGE,
  icon: <DeviceMobile />,
  id: DashboardPageIds.MOBILE_APP,
};
const onlineStore = {
  name: 'Online Store',
  icon: <Desktop />,
  rightIcon: <ArrowSquareOutCustomBold />,
  id: DashboardPageIds.ONLINE_STORE,
};
const qr = {
  name: 'QR Order & Pay',
  path: QR_CODE_PAGE,
  icon: <QrCode />,
  id: DashboardPageIds.QR_CODE,
};
const orderWidget = {
  name: 'Online Order Widget',
  path: WEB_WIDGET_PAGE,
  icon: <ChatCenteredDots />,
  id: DashboardPageIds.WEB_WIDGET,
};

// ------------------------------------------
const commonPages = [dashboard, activateAccount];
// ------------------------------------------
const merchantPages = [orders, customers, locations, statements];

// ------------------------------------------
export interface LocationPages {
  title: string;
  pages: LinkItemProps[];
}

const locationPages: LocationPages[] = [
  {
    title: 'Manage Store',
    pages: [
      menuEditor,
      holidayHours,
      discounts,
      integrations,
      team,
      storeSettings,
    ],
  },
  {
    title: 'Sales Channels',
    pages: [onlineStore, mobileApp, qr, orderWidget],
  },
];

// ------------------------------------------

interface Pages {
  [key: string]: {
    [key: string]: DashboardPageIds[];
  };
}

const starterManager = [
  DashboardPageIds.HOME,
  DashboardPageIds.ACTIVATE_ACCOUNT,
  DashboardPageIds.MENU_EDITOR,
  DashboardPageIds.INTEGRATIONS,
  DashboardPageIds.STORE_SETTINGS,
  DashboardPageIds.ONLINE_STORE,
  DashboardPageIds.QR_CODE,
  DashboardPageIds.WEB_WIDGET,
];
const starterStaff = [
  DashboardPageIds.HOME,
  DashboardPageIds.ACTIVATE_ACCOUNT,
  DashboardPageIds.MENU_EDITOR,
  DashboardPageIds.INTEGRATIONS,
  DashboardPageIds.STORE_SETTINGS,
  DashboardPageIds.ONLINE_STORE,
  DashboardPageIds.QR_CODE,
  DashboardPageIds.WEB_WIDGET,
];
const growthManager = [
  DashboardPageIds.HOME,
  DashboardPageIds.ACTIVATE_ACCOUNT,
  DashboardPageIds.ORDERS,
  DashboardPageIds.CUSTOMER,
  DashboardPageIds.LOCATIONS,
  DashboardPageIds.STATEMENTS,
  DashboardPageIds.MENU_EDITOR,
  DashboardPageIds.HOLIDAY_HOURS,
  DashboardPageIds.DISCOUNTS,
  DashboardPageIds.INTEGRATIONS,
  DashboardPageIds.TEAM,
  DashboardPageIds.STORE_SETTINGS,
  DashboardPageIds.ONLINE_STORE,
  DashboardPageIds.MOBILE_APP,
  DashboardPageIds.QR_CODE,
  DashboardPageIds.WEB_WIDGET,
];
const growthStaff = [
  DashboardPageIds.HOME,
  DashboardPageIds.ACTIVATE_ACCOUNT,
  DashboardPageIds.ORDERS,
  DashboardPageIds.CUSTOMER,
  DashboardPageIds.LOCATIONS,
  DashboardPageIds.MENU_EDITOR,
  DashboardPageIds.HOLIDAY_HOURS,
  DashboardPageIds.DISCOUNTS,
  DashboardPageIds.INTEGRATIONS,
  DashboardPageIds.STORE_SETTINGS,
  DashboardPageIds.ONLINE_STORE,
  DashboardPageIds.MOBILE_APP,
  DashboardPageIds.QR_CODE,
  DashboardPageIds.WEB_WIDGET,
];

const pages: Pages = {
  starter: {
    manager: starterManager,
    staff: starterStaff,
    app: [],
  },
  starter_yearly: {
    manager: starterManager,
    staff: starterStaff,
    app: [],
  },
  growth: {
    manager: growthManager,
    staff: growthStaff,
    app: [],
  },
  growth_yearly: {
    manager: growthManager,
    staff: growthStaff,
    app: [],
  },
  enterprise: {
    manager: growthManager,
    staff: growthStaff,
    app: [],
  },
  enterprise_yearly: {
    manager: growthManager,
    staff: growthStaff,
    app: [],
  },
  legacy: {
    manager: growthManager,
    staff: growthStaff,
    app: [],
  },
};

// ----------------------------------------------------------------------

export const getNavLinks = (user: User, temporarilyFinishOnboarding = true) => {
  if (user.onboardingInProgress && !temporarilyFinishOnboarding) {
    return {
      allowedPages: [],
      commonPages: [],
      merchantPages: [],
      locationPages: [],
    };
  }

  const ordersAllowed = user.isOrdersAllowed;
  const integrationsAllowed = user.isIntegrationsAllowed;
  const deliveryAllowed = user.isDeliveryAllowed;
  const role = user.memberRole ?? MemberRoles.MANAGER; // to support old merchants
  const selectedPlan = user?.subscription?.selectedPlan ?? Plans.LEGACY;

  const pagesForCurrentRole = pages[selectedPlan][role];
  const allowedPages = pagesForCurrentRole.filter((page) => {
    if (page === DashboardPageIds.INTEGRATIONS) {
      return integrationsAllowed || deliveryAllowed;
    }
    if (!ordersAllowed) {
      return starterManager.includes(page);
    }
    return page;
  });

  return {
    allowedPages,
    commonPages: commonPages.filter((commonPage) =>
      allowedPages.includes(commonPage.id)
    ),
    merchantPages: merchantPages.filter((merchantPage) =>
      allowedPages.includes(merchantPage.id)
    ),
    locationPages: locationPages.reduce(
      (accumulator: LocationPages[], locationPage) => {
        const updatedPages = locationPage.pages.filter((page) =>
          allowedPages.includes(page.id)
        );
        if (updatedPages.length === 0) return accumulator;
        return updatedPages.length === 0
          ? accumulator
          : [...accumulator, { ...locationPage, pages: updatedPages }];
      },
      []
    ),
  };
};

export const notRenderSideDrawerAndNavbar = [
  CREATE_QR_CODE_PAGE,
  DESIGN_QR_CODE_PAGE,
];
