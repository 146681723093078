import { Plans } from './index';

export const plansData = [
  {
    name: 'Starter',
    description:
      "For smaller business, or if you're just trying to explore our platform",
    monthlyPrice: 15,
    yearlyPrice: 12,
    id: Plans.STARTER,
    planDetails: {
      title: "What's included",
      features: [
        'Online Menu',
        'Self-serve Onboarding',
        'Downloadable QRs',
        'Basic reporting',
        'Unlimited menu edits',
      ],
    },
  },
  {
    name: 'Growth',
    description:
      'For growing businesses to unlock more features and get to the next level',
    monthlyPrice: 69,
    yearlyPrice: 55,
    id: Plans.GROWTH,
    planDetails: {
      title: 'Everything in Starter',
      features: [
        'Online Ordering',
        'Onboarding Support (includes menu upload & staff onboarding)',
        'Software engineer support',
        'Advanced reporting',
        'Team',
      ],
    },
  },
  {
    name: 'Enterprise',
    description:
      'For multi-unit operators looking for analytics & integrations',
    monthlyPrice: 299,
    yearlyPrice: 239,
    id: Plans.ENTERPRISE,
    planDetails: {
      title: 'Everything in Growth',
      features: [
        'Custom Integrations',
        'Multi-unit storefront for food halls & franchises',
        'Advanced customer segmentation',
        'Dedicated AE',
      ],
    },
  },
];
