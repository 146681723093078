import moment from 'moment';
import { CLOCK_FORMATS } from '../constants/constants';

const validateIntervalsOverlap = (
  intervals,
  scheduleErrors,
  day,
  objKey = 'schedule'
) => {
  if (intervals.length === 1) return scheduleErrors;
  const errorsCopy = { ...scheduleErrors };
  const timeSegments = intervals.map((interval) => [
    interval.from,
    interval.to,
  ]);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < timeSegments.length - 1; i++) {
    const currentEndTime = timeSegments[i][1];
    const nextStartTime = timeSegments[i + 1][0];

    if (
      moment(currentEndTime, CLOCK_FORMATS.hour12) >
      moment(nextStartTime, CLOCK_FORMATS.hour12)
    ) {
      errorsCopy[`${objKey}-${day}-${i}`] =
        'Times overlap with another set of times.';
      return errorsCopy;
    }
  }

  return errorsCopy;
};

export default validateIntervalsOverlap;
