import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop() {
  const backdropColor = alpha('#150824', 0.7);

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          margin: '0 !important',
          background: backdropColor,
          '&.MuiBackdrop-invisible': {
            background: 'transparent',
          },
        },
      },
    },
  };
}
