import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import categoriesAndItemsReducer from './slices/categoriesAndItems';
import menusReducer from './slices/menus';
import modifiersReducer from './slices/modifiers';
import snackbarReducer from './slices/snackbar';
import discountsReducer from './slices/discounts';
import onBoardingReducer from './slices/onBoarding';
import qrCodeReducer from './slices/qrCode';
import commonReducer from './slices/common';

const rootReducer = combineReducers({
  auth: authReducer,
  categoriesAndItems: categoriesAndItemsReducer,
  menus: menusReducer,
  modifiers: modifiersReducer,
  discounts: discountsReducer,
  snackbar: snackbarReducer,
  onBoarding: onBoardingReducer,
  qrCode: qrCodeReducer,
  common: commonReducer,
});

export default rootReducer;
