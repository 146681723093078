import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  open: false,
  message: '',
  type: 'info',
};

const slice = createSlice({
  name: 'categoriesAndItems',
  initialState,
  reducers: {
    openToast(state, action) {
      state.open = true;
      state.message = action.payload.message;
      state.type = action.payload.type || 'info';
    },

    closeToast(state) {
      state.open = false;
      state.message = '';
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openToast, closeToast } = slice.actions;
