// ----------------------------------------------------------------------

import { alpha } from '@mui/material/styles';

export default function Input(theme) {
  return {
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            fontSize: '1.6rem',
          },
          '& .MuiFormHelperText-root': {
            fontSize: '1.4rem',
            margin: '0.5rem 0.5rem 0 0',
          },
        },

        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          border: '1px solid',
          borderColor: theme.palette.grey[100],
          borderRadius: theme.shape.borderRadius * 4,
          fontSize: '1.6rem',
          overflow: 'hidden',

          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },

          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: alpha(theme.palette.grey[100], 0.3),
          },
          '&.Mui-error': {
            backgroundColor: theme.palette.error.lighter,
            borderColor: theme.palette.error.main,
          },
        },
      },
    },
  };
}
