import axios from 'axios';
import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'userData';

export const setSession = (data) => {
  if (data) {
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
  } else {
    window.localStorage.removeItem(TOKEN_KEY);
    delete axios.defaults.headers.common.Authorization;
  }
};

export const getSession = () => {
  const accessToken = window.localStorage.getItem(TOKEN_KEY);
  return accessToken ? JSON.parse(accessToken) : null;
};

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};
