import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Heading,
  Popover,
  Skeleton,
  Stack,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FormikValues, FormikErrors } from 'formik';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { DAY_NAMES, GOOGLE_MAP_API_KEY } from '../../constants/constants';
import ErrorAlert from './ErrorAlert';
import CircularLoader from './CircularLoader';
import { NAVBAR_HEIGHT, SIDE_NAVIGATION_WIDTH } from '../../constants';
import AddressAutoComplete from './AddressAutoCompleteC';
import ChInput from './ChInput';
import useDeliverySettings from '../../hooks/useDeliverySettings';
import { AutoGeneratedAddress } from '../../types/common';
import { Interval, Schedule as ScheduleType } from '../../types/schedule';
import generateWeekSchedule from '../../utils/generateWeekSchedule';
import { getScheduleStatus } from '../../utils';
import DialogC from './DialogC';
import ScheduleC from '../Onboarding/ScheduleC';
import { ArrowLeft } from '../../assets/icons';

enum Libraries {
  PLACES = 'places',
  DRAWING = 'drawing',
}

const libraries = [Libraries.PLACES, Libraries.DRAWING];

const getIsScheduleError = ({
  street,
  streetOptional,
  zipCode,
  country,
  city,
  state,
  lat,
  lng,
  fee,
  address,
  radius,
  ...rest
}: FormikErrors<any>) => Object.keys(rest).length !== 0;

const weekSchedule = (
  schedule: ScheduleType[]
): {
  isScheduleSet: boolean;
  tooltipData: {
    text: string;
    details: string[];
  }[];
} => {
  const isScheduleSet = getScheduleStatus(schedule);

  if (!isScheduleSet) {
    return { isScheduleSet, tooltipData: [] };
  }

  const weeklySchedule = generateWeekSchedule(schedule);

  const tooltipData = weeklySchedule.map((scheduleSingle) => {
    const { days, intervals } = scheduleSingle as {
      days: number[];
      intervals: any;
    };

    const firstDay = days[0];
    const lastDay = days[days.length - 1];

    const firstDayFullName = (
      DAY_NAMES as Record<number, { fullName: string }>
    )[firstDay].fullName;
    const lastDayFullName = (DAY_NAMES as Record<number, { fullName: string }>)[
      lastDay
    ]?.fullName;

    const day =
      days.length > 1
        ? `${firstDayFullName} - ${lastDayFullName}`
        : firstDayFullName;

    const intervalsNew = intervals.map(
      (interval: Interval) => `${interval.from}-${interval.to}`
    );

    return { text: day, details: intervalsNew };
  });

  return { isScheduleSet, tooltipData };
};

interface DeliveryZoneSelectionProps {
  onSubmit: () => void;
  handleGoBack: () => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any
  ) => Promise<FormikErrors<any>> | Promise<void>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  errors: any;
  touched: any;
  loading: boolean;
  onBoarding?: boolean;
}

const DeliveryZoneSelection = ({
  onSubmit,
  handleGoBack,
  values,
  setFieldValue,
  errors,
  touched,
  loading: isLoading,
  onBoarding,
}: DeliveryZoneSelectionProps) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY as string,
    libraries,
  });

  const setAddressValues = (addressInfo: AutoGeneratedAddress) => {
    setFieldValue('street', addressInfo.street);
    setFieldValue('city', addressInfo.city);
    setFieldValue('state', addressInfo.state);
    setFieldValue('country', addressInfo.country);
    setFieldValue('zipCode', addressInfo.zipCode);
    setFieldValue('lat', addressInfo.lat);
    setFieldValue('lng', addressInfo.lng);
  };

  const { loading, setMap } = useDeliverySettings({
    coordinates: values.lat
      ? { lat: Number(values.lat), lng: Number(values.lng) }
      : null,
    radius: values.radius,
    setAddressValues,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isScheduleError = useMemo(() => getIsScheduleError(errors), [errors]);

  const scheduleInfo = useMemo(
    () => (values.schedule ? weekSchedule(values.schedule) : null),
    [values.schedule]
  );

  if (loadError) {
    return <ErrorAlert title="Something went wrong. please try again later" />;
  }

  if (!isLoaded) {
    return <CircularLoader />;
  }

  return (
    <Stack
      position="absolute"
      top={0}
      left={0}
      w="full"
      height="full"
      pl={onBoarding ? 0 : `${SIDE_NAVIGATION_WIDTH}px`}
      pt={onBoarding ? 0 : `${NAVBAR_HEIGHT}px`}
      direction={{ base: 'column', lg: 'row' }}
      h="full"
      spacing={0}
      overflow="hidden"
    >
      <Box flex={1}>
        <GoogleMap
          mapContainerClassName="map-container"
          mapContainerStyle={{ width: '100%', height: '100%' }}
          onLoad={(map) => setMap(map)}
          onUnmount={() => setMap(null)}
          options={{
            disableDefaultUI: true,
            zoomControl: false,
            disableDoubleClickZoom: true,
          }}
        />
      </Box>
      <Box
        flex={1}
        maxW={{ base: '100%', lg: '408px' }}
        px={4}
        py={8}
        overflow="auto"
      >
        <Button
          variant="ghost"
          leftIcon={<ArrowLeft />}
          onClick={handleGoBack}
          mb={8}
        >
          Back
        </Button>

        <Stack direction="row" justify="space-between">
          <Heading fontSize="3xl" mb={4}>
            Add Zone
          </Heading>

          {scheduleInfo && (
            <div>
              <DialogC
                open={isOpen}
                onClose={onClose}
                title="Delivery Schedule"
                closeButtonText="Cancel"
                saveButtonText="Save"
                formId="delivery-schedule"
                loading={loading}
                size="4xl"
              >
                <Box sx={{ py: 4 }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onClose();
                    }}
                    id="delivery-schedule"
                  >
                    <ScheduleC
                      schedule={values.schedule}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  </form>
                </Box>
              </DialogC>
              <Popover trigger="hover">
                <PopoverTrigger>
                  <Button
                    onClick={onOpen}
                    colorScheme={isScheduleError ? 'red' : undefined}
                  >
                    {scheduleInfo?.isScheduleSet
                      ? 'Update Schedule'
                      : 'Add Schedule'}
                  </Button>
                </PopoverTrigger>
                {Boolean(scheduleInfo?.tooltipData?.length) && (
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader>Delivery Schedule</PopoverHeader>
                    <PopoverBody>
                      <Stack>
                        {scheduleInfo?.tooltipData.map((data, index) => {
                          return (
                            <Stack
                              key={String(index)}
                              direction="row"
                              justify="space-between"
                            >
                              <Text fontSize="sm">{data.text} :</Text>

                              <Box>
                                {data.details.map((detail) => (
                                  <Text key={detail} fontSize="sm">
                                    {detail}
                                  </Text>
                                ))}
                              </Box>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                )}
              </Popover>
            </div>
          )}
        </Stack>

        {loading ? (
          <Stack spacing={4}>
            <Skeleton height="48px" />
            <Skeleton height="48px" />
          </Stack>
        ) : (
          <form onSubmit={onSubmit}>
            <Stack spacing={4}>
              {errors.schedule && (
                <Text fontSize="sm" color="#e53e3e">
                  {errors.schedule}
                </Text>
              )}

              <AddressAutoComplete
                label="Address line 1"
                placeholder="Enter address line 1"
                value={values.street}
                isInvalid={!!errors.street && touched.street}
                error={errors.street}
                setAddressValues={setAddressValues}
                setFieldValue={setFieldValue}
              />

              <ChInput
                id="streetOptional"
                label="Address line 2 (optional)"
                placeholder="Enter address line 2 (optional)"
                name="streetOptional"
                value={values.streetOptional}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('streetOptional', event.target.value)
                }
                isInvalid={!!errors.streetOptional && touched.streetOptional}
                error={errors.streetOptional}
              />

              <ChInput
                id="city"
                label="City"
                placeholder="Enter city"
                name="city"
                value={values.city}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('city', event.target.value)
                }
                isInvalid={!!errors.city && touched.city}
                error={errors.city}
              />

              <Stack direction="row" align="flex-start" spacing={2}>
                <ChInput
                  id="state"
                  label="State"
                  placeholder="Enter state"
                  name="state"
                  value={values.state}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('state', event.target.value)
                  }
                  isInvalid={!!errors.state && touched.state}
                  error={errors.state}
                />

                <ChInput
                  id="country"
                  label="Country"
                  placeholder="Enter country"
                  name="country"
                  value={values.country}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('country', event.target.value)
                  }
                  isInvalid={!!errors.country && touched.country}
                  error={errors.country}
                />

                <ChInput
                  id="zipCode"
                  label="Zip code"
                  placeholder="Zip code"
                  name="zipCode"
                  value={values.zipCode}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('zipCode', event.target.value)
                  }
                  isInvalid={!!errors.zipCode && touched.zipCode}
                  error={errors.zipCode}
                />
              </Stack>

              <ChInput
                id="distance-minimum"
                label="Delivery Distance (miles)"
                placeholder="Enter delivery distance"
                name="radius"
                value={values.radius}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('radius', event.target.value)
                }
                isInvalid={!!errors.radius && touched.radius}
                error={errors.radius}
                type="number"
              />

              <Button
                variant="primary"
                size="lg"
                type="submit"
                isLoading={isLoading}
              >
                Save
              </Button>
            </Stack>
          </form>
        )}
      </Box>
    </Stack>
  );
};

export default DeliveryZoneSelection;
