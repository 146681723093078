import React, { useState } from 'react';
import { Box, Button, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { FormikValues, useFormik } from 'formik';
import { IoBanOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateOnBoarding } from '../../store/slices/onBoarding';
import { RECEIVING_METHOD } from '../../routes';
import { defaultSchedule, FONT_PRIMARY } from '../../constants/constants';
import ScheduleC from './ScheduleC';
import ConfirmButton from './common/ConfirmButtons';
import { CheckCircleIcon } from '../../theme/overrides/CustomIcons';
import { useReduxSelector } from '../../store/store';
import { Interval } from '../../types/schedule';
import validateIntervalsOverlap from '../../utils/validateIntervalsOverlap';
import validateStartingAndClosingTime from '../../utils/validateStartAndCloseTime';
import AnimateContainer from '../common/AnimateContainer';
import CustomCheckbox from '../common/CustomCheckbox';

const BUTTONS = [
  {
    icon: <CheckCircleIcon sx={{ width: 5, height: 5 }} />,
    text: 'Yes',
    value: true,
  },
  {
    icon: <IoBanOutline />,
    text: 'No',
    value: false,
  },
];

const validate = (values: FormikValues) => {
  const errors = {};
  const { enabled, schedule } = values;

  if (!enabled) {
    return errors;
  }

  let scheduleErrors = {
    schedule: 'Set tableside hours to continue.',
  } as { [key: string]: string };

  schedule.forEach(
    ({ day, intervals }: { day: number; intervals: Interval[] }) => {
      if (intervals.length) {
        delete scheduleErrors.schedule;
      }

      scheduleErrors = validateIntervalsOverlap(intervals, scheduleErrors, day);

      scheduleErrors = validateStartingAndClosingTime(
        intervals,
        scheduleErrors,
        day
      );
    }
  );

  return { ...errors, ...scheduleErrors };
};

const TablesideSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [isHoursSameAsBusiness, setIsHoursSameAsBusiness] = useState(false);
  const { loading, onBoarding } = useReduxSelector((state) => state.onBoarding);
  const { qr, businessHours } = onBoarding;
  const hours = qr?.settings?.hours;

  const { handleSubmit, values, errors, setFieldValue, isValid, submitCount } =
    useFormik({
      initialValues: {
        enabled: qr?.enabled ?? true,
        schedule: hours && hours.length > 0 ? hours : defaultSchedule,
      },
      validate,
      onSubmit: (values) => {
        const qr = {
          enabled: values.enabled,
          ...(values.enabled && {
            settings: {
              hours: values.schedule,
            },
          }),
        };

        dispatch(
          updateOnBoarding(
            {
              step: 8,
              data: { qr },
            },
            () => {
              navigate(RECEIVING_METHOD);
            },
            (e: string) => {
              toast({
                title: e,
                status: 'error',
                isClosable: true,
              });
            }
          )
        );
      },
    });

  return (
    <>
      <Box px={{ lg: 6, base: 4 }} sx={{ flex: 1 }} py={6} overflow="auto">
        <AnimateContainer animate>
          <form onSubmit={handleSubmit} id="on-boarding">
            <Heading
              as="h1"
              fontSize={{ lg: '4xl', base: '3xl' }}
              mb={4}
              color="#241c15"
              fontWeight="medium"
            >
              Tableside QR Settings & Hours
            </Heading>

            <Text fontSize="xl" fontWeight="medium" color="#363c42">
              Would you like to offer QR Tableside Ordering from your store?
            </Text>
            <Text fontSize="lg" color="rgba(92,92,92,0.8)" mb={1}>
              You can set your hours here.
            </Text>

            <ConfirmButton
              value={Boolean(values.enabled)}
              setValue={(value) => setFieldValue('enabled', value)}
              buttons={BUTTONS}
            />
            {values.enabled && (
              <>
                <CustomCheckbox
                  checked={isHoursSameAsBusiness}
                  onClick={() => {
                    setIsHoursSameAsBusiness(!isHoursSameAsBusiness);
                    setFieldValue(
                      'schedule',
                      !isHoursSameAsBusiness ? businessHours : defaultSchedule
                    );
                  }}
                  text="Tableside hours are the same as my business hours."
                  textStyle={{
                    fontWeight: '400',
                    color: '#363c42',
                    fontSize: 'lg',
                    fontFamily: FONT_PRIMARY,
                    mb: 4,
                  }}
                />

                <Text fontSize="xl" fontWeight="medium" color="#363c42">
                  What are your table service hours?
                </Text>
                <Text fontSize="lg" color="#363c42" mb={1.5}>
                  Choose the days and hours for your table service orders.
                </Text>
                <ScheduleC
                  schedule={values.schedule}
                  errors={submitCount > 0 ? errors : {}}
                  setFieldValue={setFieldValue}
                />
              </>
            )}
          </form>
        </AnimateContainer>
      </Box>
      <Flex
        borderTop={1}
        borderColor="gray.200"
        borderStyle="solid"
        p={6}
        justify="flex-end"
      >
        <Button
          isLoading={loading}
          disabled={(!isValid && submitCount > 0) || loading}
          type="submit"
          form="on-boarding"
          size="lg"
          variant="primary"
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default TablesideSettings;
