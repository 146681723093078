import React, { forwardRef, LegacyRef } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  SystemStyleObject,
} from '@chakra-ui/react';
import PhoneInputN from 'react-phone-number-input';

const phoneInputStyles = {
  '& .PhoneInput': {
    display: 'flex',

    '.PhoneInputCountry': {
      border: '1px solid #e2e8f0',
      borderRadius: 'md',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      px: 2,
      mr: 2,

      '& .PhoneInputCountrySelect': {
        width: '55px',
        pr: 14,
      },

      img: {
        width: '30px',
        height: 'auto',
        position: 'absolute',
        top: '50%',
        left: '0.5rem',
        transform: 'translate(0, -50%)',
      },
    },
  },
};

const CustomTextField = forwardRef(
  (props, ref?: LegacyRef<HTMLInputElement>) => {
    return <Input ref={ref} {...props} />;
  }
);

interface InputWithLabelProps {
  isInvalid?: boolean;
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  value: string;
  error?: string;
  handleChange: (num?: string) => void;
  sx?: SystemStyleObject;
  rightIcon?: React.ReactElement;
  type?: string;
  readOnly?: boolean;
}

const ChPhoneInput = ({
  isInvalid,
  id,
  label,
  placeholder,
  name,
  value,
  error,
  handleChange,
  readOnly,
}: InputWithLabelProps) => {
  return (
    <FormControl isInvalid={isInvalid} sx={phoneInputStyles}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <PhoneInputN
        id={id}
        name={name}
        value={value}
        inputComponent={CustomTextField}
        defaultCountry="US"
        placeholder={placeholder}
        onChange={handleChange}
        size="lg"
        readOnly={readOnly}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default ChPhoneInput;
