// ----------------------------------------------------------------------

export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.shadows[4],
          borderRadius: theme.shape.borderRadius * 4,
          paddingTop: theme.spacing(0.4),
          paddingBottom: theme.spacing(0.4),
          minWidth: '21.9rem',
        },
      },
    },
  };
}
