import { Icon } from '@chakra-ui/react';

export function Money(props: any) {
  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <rect width="256" height="256" fill="none"></rect>
      <circle
        cx="128"
        cy="128"
        r="32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <circle
        fill="currentColor"
        cx="128"
        cy="128"
        r="32"
        opacity="0.2"
      ></circle>
      <path
        fill="currentColor"
        d="M232,64H176l64,56V72A8,8,0,0,0,232,64Z"
        opacity="0.2"
      ></path>
      <path
        fill="currentColor"
        d="M232,192a8,8,0,0,0,8-8V136l-64,56Z"
        opacity="0.2"
      ></path>
      <path
        fill="currentColor"
        d="M16,72v48L80,64H24A8,8,0,0,0,16,72Z"
        opacity="0.2"
      ></path>
      <path
        fill="currentColor"
        d="M16,184a8,8,0,0,0,8,8H80L16,136Z"
        opacity="0.2"
      ></path>
      <rect
        x="16"
        y="64"
        width="224"
        height="128"
        rx="8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></rect>
      <line
        x1="176"
        y1="64"
        x2="240"
        y2="120"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="176"
        y1="192"
        x2="240"
        y2="136"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="80"
        y1="64"
        x2="16"
        y2="120"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="80"
        y1="192"
        x2="16"
        y2="136"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
    </Icon>
  );
}

export function MoneyCustom() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="192"
      height="192"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none"></rect>
      <circle
        cx="128"
        cy="128"
        r="32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <circle cx="128" cy="128" r="32" opacity="0.2"></circle>
      <path d="M232,64H176l64,56V72A8,8,0,0,0,232,64Z" opacity="0.2"></path>
      <path d="M232,192a8,8,0,0,0,8-8V136l-64,56Z" opacity="0.2"></path>
      <path d="M16,72v48L80,64H24A8,8,0,0,0,16,72Z" opacity="0.2"></path>
      <path d="M16,184a8,8,0,0,0,8,8H80L16,136Z" opacity="0.2"></path>
      <rect
        x="16"
        y="64"
        width="224"
        height="128"
        rx="8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></rect>
      <line
        x1="176"
        y1="64"
        x2="240"
        y2="120"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="176"
        y1="192"
        x2="240"
        y2="136"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="80"
        y1="64"
        x2="16"
        y2="120"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="80"
        y1="192"
        x2="16"
        y2="136"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
    </svg>
  );
}
