import React, { useEffect } from 'react';
import { Stack, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BuildPlan from '../../../components/register/BuildPlan';
import StartOnBoarding from '../../../components/Onboarding/StartOnBoarding';
import { getOnBoarding } from '../../../store/slices/onBoarding';
import loadingVdo from '../../../assets/video/onboarding-loading.mp4';
import OnBoardingLayout from '../../../components/Onboarding/OnBoardingLayout';
import { HOME_PAGE } from '../../../routes';

const OnBoarding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const { search } = useLocation();
  const { loadingData } = useSelector((state) => state.onBoarding);

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(search);
      const noRedirect = queryParams.get('noRedirect');

      dispatch(
        getOnBoarding(
          () => {
            if (noRedirect) {
              return;
            }

            navigate(HOME_PAGE);
          },
          (e) => {
            toast({
              title: e,
              status: 'error',
              isClosable: true,
            });
          }
        )
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  if (loadingData) {
    return (
      <Stack h="100vh" w="full" align="center" justify="center">
        <video width="130" height="130" autoPlay muted loop>
          <source src={loadingVdo} type="video/mp4" />
        </video>
      </Stack>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<StartOnBoarding />} />
      <Route path="build-plan" element={<BuildPlan />} />
      <Route path="*" element={<OnBoardingLayout />} />
    </Routes>
  );
};

export default OnBoarding;
