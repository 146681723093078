import { Text, useDisclosure, useToast, Heading } from '@chakra-ui/react';
import { useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import crave from '../../apis/crave';
import { formatApiError } from '../../utils';
import BankAccountUpdate from './BankAccountUpdate';
import BankAccountConnect from './BankAccountConnect';
import { StripeBankAccount } from '../../types/stripe';
import UpdateBankAccountManually from './UpdateBankAccountManually';
import { FormikValues } from 'formik';
import { isFinancialConnectionsSupported } from '../../utils/is-financial-connections-supported';
import { Countries } from '../../constants';
import { BRAND_NAME } from '../../constants/constants';

interface BankAccountProps {
  stripeAccountId: string;
  onSuccess: (payload: any, setIsLoading: (isLoading: boolean) => void) => void;
  stripePayoutBank?: StripeBankAccount;
  onBoardingPage?: boolean;
}

const BankAccountConnection = ({
  stripeAccountId,
  onSuccess,
  stripePayoutBank,
  onBoardingPage,
}: BankAccountProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { token, merchant } = useAuth();
  const stripe = useStripe();
  const toast = useToast();

  const isConnected = Boolean(stripePayoutBank?.token);
  const isFinancialConnectionSupportedCountry = isFinancialConnectionsSupported(
    merchant.country as Countries
  );

  const handleStartLinkBankAccount = async (values?: FormikValues) => {
    try {
      if (!stripe || (!isFinancialConnectionSupportedCountry && !values)) {
        throw new Error('An error occurred');
      }

      setIsLoading(true);

      let result;

      if (isFinancialConnectionSupportedCountry) {
        const { data } = await crave.post(
          '/stripe/fin-conn',
          { stripeAccountId },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        result = await stripe.collectBankAccountToken({
          clientSecret: data.clientSecret,
        });
      } else {
        let data;
        if (merchant.country === Countries.GB) {
          data = {
            sort_code: values!.sortCode,
          };
        }

        if (!data) {
          throw new Error('Missing required parameters');
        }

        result = await stripe.createToken('bank_account', {
          country: values!.country,
          currency: values!.currency,
          account_holder_name: values!.accountHolderName,
          account_number: values!.accountNumber,
          account_holder_type: values!.accountHolderType,
          ...data,
        });
      }

      if (result?.error || !result?.token) {
        throw new Error(result.error?.message || 'Something went wrong');
      }

      const { bank_account: bankAccount } = result.token as any;

      const payload = {
        id: bankAccount.id,
        token: result.token.id,
        object: bankAccount.object,
        accountHolderName: bankAccount.account_holder_name,
        accountHolderType: bankAccount.account_holder_type,
        accountType: bankAccount.account_type,
        bankName: bankAccount.bank_name,
        customer: bankAccount.customer,
        fingerprint: bankAccount.fingerprint,
        last4: bankAccount.last4,
        routingNumber: bankAccount.routing_number,
        status: bankAccount.status,
        clientIp: result.token.client_ip,
      };

      if (onSuccess) {
        onSuccess(payload, setIsLoading);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      const { e } = formatApiError(error);
      toast({
        title: e,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const props = {
    handleStartLinkBankAccount: () => {
      if (isFinancialConnectionSupportedCountry) {
        handleStartLinkBankAccount();
      } else {
        onOpen();
      }
    },
    isLoading,
    stripePayoutBank,
  };

  return (
    <>
      <UpdateBankAccountManually
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleStartLinkBankAccount}
      />

      {onBoardingPage ? (
        <Heading
          as="h1"
          fontSize={{ lg: '4xl', base: '3xl' }}
          mb={4}
          color="#241c15"
          fontWeight="medium"
        >
          Link your bank account for deposits
        </Heading>
      ) : (
        <Text fontSize="xl" color="#363c42" mb={2.5} fontWeight="medium">
          {isConnected
            ? 'Update Your Bank Account for Deposits'
            : 'Link your bank account for deposits'}
        </Text>
      )}
      {isConnected && !onBoardingPage && (
        <Text color="#d43811" mb={5}>
          Update your bank account details for payouts. Please note that this is
          NOT the payment methods management for your {BRAND_NAME} subscription.
        </Text>
      )}
      {isConnected ? (
        <BankAccountUpdate {...props} />
      ) : (
        <BankAccountConnect {...props} />
      )}
    </>
  );
};

export default BankAccountConnection;
