import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  SystemStyleObject,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Tooltip,
} from '@chakra-ui/react';
import { Info } from '../../assets/icons';

interface InputWithLabelProps {
  isInvalid?: boolean;
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  value: string;
  error?: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  sx?: SystemStyleObject;
  rightIcon?: React.ReactElement | string;
  leftIcon?: React.ReactElement | string;
  disabled?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  type?: string;
  labelInfoText?: string;
}

const ChInput = ({
  isInvalid,
  id,
  label,
  placeholder,
  name,
  value,
  error,
  handleChange,
  sx,
  rightIcon,
  leftIcon,
  disabled,
  autoComplete,
  readOnly,
  type = 'text',
  labelInfoText,
}: InputWithLabelProps) => {
  return (
    <FormControl isInvalid={isInvalid} sx={sx}>
      {label && (
        <FormLabel htmlFor={id}>
          {label}{' '}
          {labelInfoText && (
            <Tooltip shouldWrapChildren label={labelInfoText}>
              <Info width={22} height={22} />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <InputGroup>
        <Input
          id={id}
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          size="lg"
          disabled={disabled}
          autoComplete={autoComplete}
          readOnly={readOnly}
        />
        {leftIcon && <InputLeftElement h="full">{leftIcon}</InputLeftElement>}
        {rightIcon && (
          <InputRightElement h="full">{rightIcon}</InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default ChInput;
