export enum DashboardPageIds {
  HOME = 'home',
  ACTIVATE_ACCOUNT = 'activate-account',
  ORDERS = 'orders',
  CUSTOMER = 'customer',
  LOCATIONS = 'locations',
  STATEMENTS = 'statements',
  MENU_EDITOR = 'menu-editor',
  HOLIDAY_HOURS = 'holiday-hours',
  DISCOUNTS = 'discounts',
  INTEGRATIONS = 'integrations',
  TEAM = 'team',
  STORE_SETTINGS = 'store-settings',
  ONLINE_STORE = 'online-store',
  MOBILE_APP = 'mobile-app',
  QR_CODE = 'generate-qr-code',
  WEB_WIDGET = 'web-widget',
}
