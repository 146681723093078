// ----------------------------------------------------------------------

import { FONT_PRIMARY } from '../constants/constants';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightLight: 300,
  h1: {
    fontWeight: 600,
    lineHeight: '4.95rem',
    fontSize: '4.4rem',
  },
  h2: {
    fontWeight: 500,
    lineHeight: '1.2',
    fontSize: '3.6rem',
    letterSpacing: '-1.5px',
  },
  h3: {
    fontWeight: 600,
    lineHeight: '3rem',
    fontSize: '2.4rem',
  },
  h4: {
    fontWeight: 600,
    lineHeight: '2.75rem',
    fontSize: '2rem',
  },
  h5: {
    fontWeight: 600,
    lineHeight: '2rem',
    fontSize: '1.6rem',
  },
  h6: {
    fontWeight: 600,
    lineHeight: '1.848rem',
    fontSize: '1.4rem',
  },

  subtitle1: {
    fontWeight: 400,
    lineHeight: '2rem',
    fontSize: '1.6rem',
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: '1.848rem',
    fontSize: '1.4rem',
  },
  body1: {
    fontWeight: 400,
    lineHeight: '2rem',
    fontSize: '1.6rem',
  },
  body2: {
    fontWeight: 400,
    lineHeight: '1.848rem',
    fontSize: '1.4rem',
  },
  caption: {
    fontWeight: 400,
    lineHeight: '1.75rem',
    fontSize: '1.4rem',
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '1.2rem',
    textTransform: 'uppercase',
  },
  button: {
    fontSize: '1.6rem',
    fontWeight: 400,
    textTransform: 'none',
    lineHeight: '2rem',
  },
};

export default typography;
