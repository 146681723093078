import _findIndex from 'lodash/findIndex';
import _isEqual from 'lodash/isEqual';

export default function generateWeekSchedule(schedule) {
  const updatedSchedule = [];

  schedule
    .filter((scheduleSingle) => scheduleSingle.active)
    .forEach((single) => {
      if (updatedSchedule.length === 0) {
        updatedSchedule.push({
          days: [single.day],
          intervals: single.intervals,
        });
        return;
      }

      const index = _findIndex(updatedSchedule, (scheduleSingle) => {
        return _isEqual(single.intervals, scheduleSingle.intervals);
      });

      if (index !== -1) {
        const existingSchedule = updatedSchedule[index];
        updatedSchedule[index] = {
          days: [...existingSchedule.days, single.day],
          intervals: single.intervals,
        };
      } else {
        updatedSchedule.push({
          days: [single.day],
          intervals: single.intervals,
        });
      }
    });

  const newUpdatedSchedule = updatedSchedule.map((scheduleSingle) => {
    const updatedDays = [];

    scheduleSingle.days.forEach((day) => {
      if (updatedDays.length === 0) {
        updatedDays.push([day]);
        return;
      }

      const lastElementOfArray = updatedDays[updatedDays.length - 1];
      const lastElementOfSubArray =
        lastElementOfArray[lastElementOfArray.length - 1];
      const expectedNumber = lastElementOfSubArray + 1;
      if (expectedNumber === day) {
        updatedDays[updatedDays.length - 1].push(day);
      } else {
        updatedDays.push([day]);
      }
    });

    return { ...scheduleSingle, days: updatedDays };
  });

  const scheduleSplit = [];
  newUpdatedSchedule.forEach((scheduleSingle) => {
    scheduleSingle.days.forEach((day) => {
      scheduleSplit.push({ days: day, intervals: scheduleSingle.intervals });
    });
  });

  return scheduleSplit.sort((a, b) => a.days[0] - b.days[0]);
}
