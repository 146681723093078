import { alpha } from '@mui/material/styles';

export default function Button(theme) {
  return {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 15,
          fontSize: '1.6rem',
        },
        //Size
        sizeLarge: {
          height: '5.6rem',
          minWidth: '16.2rem',
          fontSize: '2rem',
          lineHeight: '2.64rem',
        },
        sizeMedium: {
          height: '4rem',
          minWidth: '9.4rem',
        },
        sizeSmall: {
          height: '3.2rem',
          minWidth: '7.8rem',
        },
        // contained
        containedInherit: {
          '&:Mui-disabled': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.text.tertiary,
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.6),
          },
          '&:active': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        containedSecondary: {},
        containedInfo: {},
        containedSuccess: {},
        containedWarning: {},
        containedError: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.6),
          },
          '&:active': {
            backgroundColor: theme.palette.error.main,
          },
        },
        // outlined
        outlinedInherit: {
          '&:Mui-disabled': {
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[100],
          },
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: alpha(theme.palette.primary.main, 0.6),
            color: alpha(theme.palette.primary.main, 0.6),
          },
        },
        outlinedError: {
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: alpha(theme.palette.error.main, 0.6),
            color: alpha(theme.palette.error.main, 0.6),
          },
        },
      },
    },
  };
}
