import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import crave, { CRAVE_API_URL } from '../../apis/crave';
import { formatApiError, formatUserData } from '../../utils';
import { dispatch } from '../store';
import { getSession, isValidToken, setSession } from '../../utils/jwt';
import { HOME_PAGE, MENU_MANAGER } from '../../routes';
import { openToast } from './snackbar';

// ----------------------------------------------------------------------

const initialState = {
  user: {
    email: '',
    token: '',
    refreshToken: '',
    locationId: '',
    merchantId: '',
    authenticated: false,
    isDeliveryAllowed: false,
    onboardingInProgress: false,
    isOrdersAllowed: false,
    isIntegrationsAllowed: false,
    schedule: [],
    menuStatus: '',
    customDomainStatus: '',
    requiresStripePerson: false,
    locationHasProducts: false,
    locationDisplayName: '',
    stripeBusinessType: '',
    address: '',
    timezone: '',
    firstName: '',
    lastName: '',
    locationPhone: '',
    locationName: '',
    memberRole: '',
    backgroundUrl: '',
    description: '',
    chatToken: '',
    stripeId: '',
    country: '',
    locationModules: [],
    stripePayoutBank: {
      token: '',
      last4: '',
      accountHolderName: '',
      bankName: '',
    },
    subscription: {
      selectedPlan: '',
      customerId: '',
      subscriptionId: '',
      selectedPlanStripeId: '',
      last4: '',
      addons: {
        menu: '',
      },
      subscriptionStatus: '',
      cancelAt: '',
      cancelAtPeriodEnd: false,
      nextBillingDate: '',
    },
  },
  temporarilyFinishOnboarding: false,
  error: '',
  loading: false,
  loadingUser: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoadUser(state) {
      state.loadingUser = true;
    },

    startLoading(state) {
      state.loading = true;
    },

    stopLoading(state) {
      state.loadingUser = false;
      state.loading = false;
    },

    hasError(state, action) {
      state.loading = false;
      state.loadingUser = false;
      state.error = action.payload;
    },

    loginSuccess(state, action) {
      state.loading = false;
      state.loadingUser = false;
      state.user = action.payload;
    },

    updateUser(state, action) {
      state.user = action.payload;
    },

    updateEmailSuccess(state, action) {
      state.user.email = action.payload;
    },

    updatePaymentSuccess(state, action) {
      state.user.subscription.last4 = action.payload;
    },

    logoutSuccess(state) {
      state.user = initialState.user;
      state.temporarilyFinishOnboarding = false;
      state.loading = false;
    },

    updateMenuStatus(state) {
      state.user.menuStatus = 'processing';
    },

    updateDomainStatus(state, action) {
      state.user.customDomainStatus = action.payload;
    },

    updateMenuAddon(state, action) {
      state.user.subscription.addons.menu = action.payload;
    },

    updateSchedule(state, action) {
      state.user.schedule = action.payload;
    },

    updateBusinessRep(state) {
      state.user.requiresStripePerson = false;
    },

    setOnboardingStatus(state, action) {
      state.user.onboardingInProgress = action.payload;
    },

    completeOnboardingTemporarily(state, action) {
      state.temporarilyFinishOnboarding = action.payload;
    },

    updateStripePayoutBank(state, action) {
      state.user.stripePayoutBank = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  updateEmailSuccess,
  updatePaymentSuccess,
  updateMenuStatus,
  updateSchedule,
  updateBusinessRep,
  updateMenuAddon,
  setOnboardingStatus,
  updateUser,
  updateDomainStatus,
  completeOnboardingTemporarily,
  updateStripePayoutBank,
} = slice.actions;

// ----------------------------------------------------------------------

export function register(values, onSuccess, onFail) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const email = values.email.trim();
      const { data } = await axios.post(
        `${CRAVE_API_URL}/auth/signup`,
        { ...values, email },
        {
          withCredentials: true,
        }
      );

      setSession({
        token: data.token,
        email,
      });

      dispatch(
        slice.actions.loginSuccess({
          token: data.token,
          authenticated: true,
          onboardingInProgress: true,
          email,
        })
      );

      if (onSuccess) {
        onSuccess(data.merchantId);
      }
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      if (onFail) {
        onFail(e);
      }
    }
  };
}

// ----------------------------------------------------------------------

export function logout(navigate, showMessage) {
  return async () => {
    axios
      .post(
        `${CRAVE_API_URL}/auth/signout`,
        {},
        {
          withCredentials: true,
        }
      )
      .catch((err) => console.error(err));
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
    if (navigate) {
      navigate(HOME_PAGE);
    }
    if (showMessage) {
      dispatch(openToast({ message: 'Logout successfully' }));
    }
  };
}

//----------------------------------------------------------------------------------------------

// ----------------------------------------------------------------------
export const handleLogin = async ({ token, remember = true }) => {
  if (remember) {
    setSession({ token });
  }
  const { data } = await crave.get('/user', {
    headers: { Authorization: `Bearer ${token}` },
  });
  dispatch(slice.actions.loginSuccess(formatUserData(data, token)));
};

// ----------------------------------------------------------------------

export function adminLogin({ email, password }, navigate, onFail) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());

      const trimmedEmail = email.trim();
      const { data } = await axios.post(
        `${CRAVE_API_URL}/auth/signin-as-user`,
        { email: trimmedEmail, password },
        {
          withCredentials: true,
        }
      );
      await handleLogin({ token: data.token });
      navigate(MENU_MANAGER);
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
      if (onFail) {
        onFail(e);
      }
    }
  };
}

// ----------------------------------------------------------------------

export function login({ email, password, remember }, onSuccess, onFail) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());

      const trimmedEmail = email.trim();
      const { data } = await axios.post(
        `${CRAVE_API_URL}/auth/signin`,
        { email: trimmedEmail, password },
        {
          withCredentials: true,
        }
      );

      await handleLogin({ token: data.token, remember });
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      if (onFail) {
        onFail(e);
      }
    }
  };
}

// ----------------------------------------------------------------------

export function googleLogin(token, onSuccess, onFail) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());

      const { data } = await axios.post(
        `${CRAVE_API_URL}/oauth/google/signin`,
        { token },
        {
          withCredentials: true,
        }
      );

      await handleLogin({ token: data.token });
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      if (onFail) {
        onFail(e);
      }
    }
  };
}

// ----------------------------------------------------------------------

export function loadUser(navigate) {
  return async () => {
    try {
      dispatch(slice.actions.startLoadUser());

      const userSession = getSession();
      if (userSession && isValidToken(userSession.token)) {
        const { data: userData } = await crave.get('/user', {
          headers: { Authorization: `Bearer ${userSession.token}` },
          params: { locationId: userSession.locationId },
        });
        dispatch(
          slice.actions.loginSuccess(
            formatUserData(userData, userSession.token)
          )
        );
        return;
      }

      if (userSession) {
        dispatch(logout(navigate));
        dispatch(slice.actions.hasError('Token invalid or user not logged in'));
      }

      dispatch(slice.actions.stopLoading());
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
      navigate(HOME_PAGE);
    }
  };
}
