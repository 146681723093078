import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { dispatch, getState } from '../store';
import crave from '../../apis/crave';
import { formatApiError } from '../../utils';
import { DISCOUNT_PAGE } from '../../routes';
import {
  discountMinRequirements,
  discountScopes,
} from '../../constants/constants';
import getDiscountChipTextColor from '../../utils/get-discount-chip-text-color';
import { TableCellTypes } from '../../constants';
import { formatDiscountDate } from '../../utils/discount/format-discount-date';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  formLoading: false,
  error: null,
  formattedDiscounts: {
    tableHeadings: ['CODE', 'DESCRIPTION', 'STATUS', 'NUMBER OF USES'],
    tableRows: [],
  },
  discounts: [],
  discount: {},
};

const slice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    startFormLoading(state) {
      state.formLoading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.formLoading = false;
      state.error = action.payload;
    },

    getDiscountsSuccess(state, action) {
      state.loading = false;
      state.discounts = action.payload.discounts;
      state.formattedDiscounts.tableRows = action.payload.formattedDiscounts;
    },

    getDiscountSuccess(state, action) {
      state.loading = false;
      state.discount = action.payload;
    },

    createDiscountSuccess(state, action) {
      state.formLoading = false;
      state.discounts = action.payload.discounts;
      state.formattedDiscounts.tableRows = action.payload.formattedDiscounts;
      state.discount = {};
    },

    deleteDiscountSuccess(state, action) {
      state.loading = false;
      state.discounts = action.payload.discounts;
      state.formattedDiscounts.tableRows = action.payload.formattedDiscounts;
      state.discount = {};
    },

    editDiscountSuccess(state, action) {
      state.formLoading = false;
      state.discounts = action.payload.discounts;
      state.formattedDiscounts.tableRows = action.payload.formattedDiscounts;
      state.discount = {};
    },

    resetDiscount(state) {
      state.discount = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetDiscount } = slice.actions;

// ----------------------------------------------------------------------
const handleFormatDiscountData = (discounts) => {
  const formattedDiscounts = discounts.map((discount) => {
    const { discountId, code, description, usageCounter, startsAt } = discount;
    const { text, colorScheme } = getDiscountChipTextColor(discount);
    return {
      item: discount,
      link: `${DISCOUNT_PAGE}/${discountId}`,
      tableRow: [
        { text: code },
        { text: description || 'N/A' },
        {
          text,
          cellType: TableCellTypes.CHIP,
          colorScheme,
        },
        {
          text: `${usageCounter} used from`,
          text2: moment(startsAt).format('MMMM DD, yyyy'),
        },
      ],
    };
  });

  return { discounts, formattedDiscounts };
};

// ----------------------------------------------------------------------

export function getDiscounts() {
  return async () => {
    try {
      const { auth } = getState();
      const { merchantId, locationId, token } = auth.user;
      dispatch(slice.actions.startLoading());

      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.get(
        `merchants/${merchantId}/locations/${locationId}/discounts`,
        params
      );

      const { formattedDiscounts, discounts: newDiscounts } =
        handleFormatDiscountData(data);

      dispatch(
        slice.actions.getDiscountsSuccess({
          formattedDiscounts,
          discounts: newDiscounts,
        })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
    }
  };
}

// ----------------------------------------------------------------------

const formatDiscountData = (discount) => {
  const {
    minRequirement,
    minPurchaseAmount,
    minItemsQuantity,
    isUsageLimit,
    maxUsage,
    startTime,
    expireTime,
    startDate,
    expireDate,
  } = discount;

  const handleGetAssignedScopedIds = (discountInner) => {
    if (discountInner.scope === discountScopes.CATEGORY)
      return discountInner.categories;
    if (discountInner.scope === discountScopes.PRODUCT)
      return discountInner.products;
    return [];
  };

  return {
    code: discount.code,
    disabled: discount.disabled,
    description: discount.description,
    oncePerCustomer: discount.oncePerCustomer,
    oncePerOrder: discount.oncePerOrder,
    isFixed: discount.isFixed,
    scope: discount.scope,
    amount: discount.amount,
    minPurchaseAmount:
      minRequirement === discountMinRequirements.MINIMUM_PURCHASE_AMOUNT
        ? minPurchaseAmount
        : '0',
    minItemsQuantity:
      minRequirement === discountMinRequirements.MINIMUM_QUANTITY_OF_ITEMS
        ? Number(minItemsQuantity)
        : 0,
    maxUsage: isUsageLimit ? Number(maxUsage) : 0,
    assignedScopeIds: handleGetAssignedScopedIds(discount),
    startsAt: formatDiscountDate(startDate, startTime),
    expiresAt: formatDiscountDate(expireDate, expireTime),
  };
};

// ----------------------------------------------------------------------

export function createDiscount(newDiscount, onSuccess, onFail) {
  return async () => {
    const { auth, discounts: discountsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { discounts } = discountsState;
    dispatch(slice.actions.startFormLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.post(
        `merchants/${merchantId}/locations/${locationId}/discounts`,
        formatDiscountData(newDiscount),
        params
      );

      const { formattedDiscounts, discounts: newDiscounts } =
        handleFormatDiscountData([...discounts, data]);

      dispatch(
        slice.actions.createDiscountSuccess({
          formattedDiscounts,
          discounts: newDiscounts,
        })
      );
      onSuccess();
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      onFail(e);
    }
  };
}

// ----------------------------------------------------------------------

export function getDiscount(discountId) {
  return async () => {
    try {
      const { auth } = getState();
      const { merchantId, locationId, token } = auth.user;
      dispatch(slice.actions.startLoading());

      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.get(
        `merchants/${merchantId}/locations/${locationId}/discounts/${discountId}`,
        params
      );
      dispatch(slice.actions.getDiscountSuccess(data));
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDiscount(discountId, onSuccess, onFail) {
  return async () => {
    const { auth, discounts: discountsState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { discounts } = discountsState;

    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      await crave.delete(
        `merchants/${merchantId}/locations/${locationId}/discounts/${discountId}`,
        params
      );

      const { formattedDiscounts, discounts: newDiscounts } =
        handleFormatDiscountData(
          discounts.filter((discount) => discount.discountId !== discountId)
        );

      dispatch(
        slice.actions.deleteDiscountSuccess({
          formattedDiscounts,
          discounts: newDiscounts,
        })
      );

      onSuccess();
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      onFail(e);
    }
  };
}

// ----------------------------------------------------------------------

export function editDiscount(updateDiscount, onSuccess, onFail) {
  return async () => {
    const { auth, discounts: discountState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { discounts } = discountState;

    dispatch(slice.actions.startFormLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.put(
        `merchants/${merchantId}/locations/${locationId}/discounts/${updateDiscount.discountId}`,
        formatDiscountData(updateDiscount),
        params
      );

      const { formattedDiscounts, discounts: newDiscounts } =
        handleFormatDiscountData(
          discounts.map((discount) =>
            discount.discountId === data.discountId ? data : discount
          )
        );

      dispatch(
        slice.actions.editDiscountSuccess({
          formattedDiscounts,
          discounts: newDiscounts,
        })
      );

      onSuccess();
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      onFail(e);
    }
  };
}
