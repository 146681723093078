import { Icon } from '@chakra-ui/react';

export function PencilSimple(props: any) {
  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <rect width="256" height="256" fill="none"></rect>
      <path
        d="M92.7,216H48a8,8,0,0,1-8-8V163.3a7.9,7.9,0,0,1,2.3-5.6l120-120a8,8,0,0,1,11.4,0l44.6,44.6a8,8,0,0,1,0,11.4l-120,120A7.9,7.9,0,0,1,92.7,216Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></path>
      <line
        x1="136"
        y1="64"
        x2="192"
        y2="120"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
    </Icon>
  );
}
