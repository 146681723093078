import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BsDot } from 'react-icons/bs';
import onBoardingImg from '../../assets/images/ordering.webp';
import { startTrialImg } from '../../theme/overrides/CustomIcons';
import { useNavigate } from 'react-router-dom';
import {
  ADDRESS_AND_CURRENCY,
  BUILD_PLAN,
  BUSINESS_HOURS,
  DELIVERY_SETTINGS,
  LINK_BANK,
  LOGO_AND_COVER,
  PICKUP_SETTINGS,
  RECEIVING_METHOD,
  TABLE_SIDE_SETTING,
  TAXATION,
} from '../../routes';
import { useReduxDispatch, useReduxSelector } from '../../store/store';
import { Plans } from '../../constants';
import { completeOnboardingTemporarily } from '../../store/slices/auth';
import LayoutC from '../common/LayoutC';

const StartTrial = ({ handleStartTrial }: { handleStartTrial: () => void }) => {
  return (
    <HStack
      as="nav"
      sx={{
        p: { base: 1.5, lg: 4 },
        height: '80px',
        borderBottom: '1px solid',
        borderColor: 'rgba(0,0,0,0.08)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: 'full',
        zIndex: 2,
        bg: '#3a1960',
      }}
    >
      <Image
        height="100%"
        width="auto"
        alt={'Start trial'}
        objectFit={'contain'}
        src={startTrialImg}
        sx={{ position: 'absolute', top: 0, left: 0 }}
        display={{ lg: 'inline', base: 'none' }}
      />

      <Heading
        textAlign={{ lg: 'center', base: 'left' }}
        color="white"
        fontSize={{ lg: 'xl', base: 'md' }}
        fontWeight="medium"
        w="full"
      >
        Begin your 14 day free trial
      </Heading>

      <Button
        boxShadow="rgb(71 199 240) 2px 2px 0px 0px"
        bg="rgb(69, 86, 220)"
        color="white"
        sx={{
          position: 'absolute',
          top: '50%',
          right: '15px',
          transform: 'translateY(-50%)',
        }}
        onClick={handleStartTrial}
      >
        Start Trial
      </Button>
    </HStack>
  );
};

//----- ON BOARDING STEPS -----
const ON_BOARDING_STEPS = [
  LOGO_AND_COVER,
  ADDRESS_AND_CURRENCY,
  TAXATION,
  BUSINESS_HOURS,
  LINK_BANK,
  PICKUP_SETTINGS,
  DELIVERY_SETTINGS,
  TABLE_SIDE_SETTING,
  RECEIVING_METHOD,
];

export default function StartOnBoarding() {
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  const { onBoarding } = useReduxSelector((state) => state.onBoarding);
  const { step, selectedPlanStripeId, selectedPlan } = onBoarding;

  const startOnBoarding = () => {
    navigate(selectedPlanStripeId ? ON_BOARDING_STEPS[step] : BUILD_PLAN);
  };

  const isStarterSelected = selectedPlan === Plans.STARTER;

  return (
    <LayoutC
      sx={{ pb: 4 }}
      animate={false}
      onClick={() => dispatch(completeOnboardingTemporarily(true))}
      buttonText="I will do it later"
      buttonVariant="outline"
    >
      {!selectedPlanStripeId && (
        <StartTrial handleStartTrial={startOnBoarding} />
      )}
      <Container maxW="940px">
        <Stack
          minH="100vh"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 3, md: 6 }}
        >
          <Flex flex={{ base: 0, md: 1 }} align="center" justify="center">
            <Image
              height={{ base: '250px', md: '350px' }}
              width="auto"
              alt={'Login Image'}
              objectFit={'contain'}
              src={onBoardingImg}
            />
          </Flex>
          <Flex flex={{ base: 0, md: 1 }} align="center" justify="center">
            <Stack w="full" maxW="lg" spacing={2.5}>
              <Heading
                as="h1"
                fontSize={{ lg: '4xl', base: '3xl' }}
                mb={1.5}
                color="#241c15"
                fontWeight="medium"
              >
                Get started with the Next-Generation Online Ordering
              </Heading>

              <List>
                <ListItem
                  color="#363c42"
                  fontSize="lg"
                  display="flex"
                  alignItems="center"
                >
                  <ListIcon as={BsDot} w={7} h={7} color="gray.500" />
                  Turn tables quicker with pay-at-table
                </ListItem>
                <ListItem
                  color="#363c42"
                  fontSize="lg"
                  display="flex"
                  alignItems="center"
                >
                  <ListIcon as={BsDot} w={7} h={7} color="gray.500" />
                  Increase average spending
                </ListItem>
                <ListItem
                  color="#363c42"
                  fontSize="lg"
                  display="flex"
                  alignItems="center"
                >
                  <ListIcon as={BsDot} w={7} h={7} color="gray.500" />
                  Get more tips for staff
                </ListItem>
              </List>
              <HStack direction={{ base: 'column', md: 'row' }} spacing={4}>
                <Button variant="primary" onClick={startOnBoarding} size="lg">
                  {isStarterSelected
                    ? 'Continue'
                    : selectedPlanStripeId && step > 0
                    ? 'Continue onboarding'
                    : 'Start onboarding'}
                </Button>
                {!isStarterSelected && (
                  <Text color="gray.500" fontSize="xs">
                    Takes 3 min
                  </Text>
                )}
              </HStack>
            </Stack>
          </Flex>
        </Stack>
      </Container>
    </LayoutC>
  );
}
