import React, { ReactNode } from 'react';
import {
  Button,
  Heading,
  Stack,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';
import { Link, To } from 'react-router-dom';
import AlertMessage from './ErrorAlert';
import CircularLoader from './CircularLoader';

interface PageWrapperProps {
  title?: string;
  text?: string;
  onClick?: () => void;
  buttonLoading?: boolean;
  buttonText?: string;
  buttonLink?: To;
  loading?: boolean;
  error?: string;
  children: ReactNode;
  formId?: string;
  isSticky?: boolean;
  bgColor?: string;
  rightChild?: React.ReactElement;
  sx?: SystemStyleObject;
}

function PageWrapper({
  title,
  text,
  buttonText,
  buttonLoading,
  onClick,
  buttonLink,
  loading,
  error,
  children,
  formId,
  isSticky,
  bgColor = 'white',
  rightChild,
  sx,
}: PageWrapperProps) {
  const commonProps = {
    variant: 'primary',
    isLoading: buttonLoading,
  };

  return (
    <Stack
      spacing="25px"
      px={6}
      pb={5}
      bgColor={bgColor}
      minHeight="full"
      sx={sx}
    >
      <Stack
        direction="row"
        align="center"
        justify="space-between"
        position="sticky"
        bgColor={bgColor}
        pt={5}
        zIndex={3}
        {...(isSticky && {
          top: '0px',
          zIndex: 20,
        })}
      >
        {(title || text) && (
          <Stack spacing={2}>
            {title && (
              <Heading
                color="#241c15"
                fontWeight="medium"
                letterSpacing="-1.5px"
              >
                {title}
              </Heading>
            )}

            {text && (
              <Text
                fontSize="lg"
                fontWeight="medium"
                color="rgba(92,92,92,0.8)"
                letterSpacing="-0.5px"
              >
                {text}
              </Text>
            )}
          </Stack>
        )}

        {(onClick || formId) && (
          <Button
            form={formId}
            isDisabled={loading}
            {...commonProps}
            onClick={onClick}
            type={formId ? 'submit' : 'button'}
          >
            {buttonText}
          </Button>
        )}

        {buttonLink && (
          <Button
            isDisabled={loading}
            {...commonProps}
            as={Link}
            to={loading ? '' : buttonLink}
          >
            {buttonText}
          </Button>
        )}

        {rightChild}
      </Stack>

      {error && <AlertMessage title={error} />}
      {loading && <CircularLoader />}
      {!error && !loading && children}
    </Stack>
  );
}

export default PageWrapper;
