import { CSSProperties } from 'react';
import {
  STRIPE_CUSTOMER_PORTAL_LINK,
  STRIPE_PORTAL_NAME,
} from '../../constants';
import useAuth from '../../hooks/useAuth';
import { XCustom } from '../../assets/icons';
import { formatDate } from '../../utils';

const styles: {
  container: CSSProperties;
  containerInner: CSSProperties;
  text: CSSProperties;
  link: CSSProperties;
  button: CSSProperties;
} = {
  container: {
    backgroundColor: 'rgb(254, 218, 96)',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 40,
  },
  containerInner: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: '#363c42',
    fontSize: 14,
    lineHeight: '1.5 !important',
    marginLeft: 10,
  },
  link: {
    display: 'inline-block',
    color: '#363c42',
    fontSize: 14,
    lineHeight: '1.5 !important',
    cursor: 'pointer',
    textDecoration: 'underline',
    alignSelf: 'flex-end',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    lineHeight: '1.5 !important',
    cursor: 'pointer',
  },
};

const infoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="#e53e3e"
    viewBox="0 0 256 256"
  >
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
  </svg>
);

function SubscriptionWillCancelBanner({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}) {
  const { email, merchant } = useAuth();
  const { nextBillingDate } = merchant.subscription;
  if (!isOpen) {
    return <div />;
  }

  return (
    <div style={styles.container}>
      <div style={styles.containerInner}>
        {infoIcon}
        <p style={styles.text}>
          Your plan will be canceled, but is still available until the end of
          your billing period on <b>{formatDate(nextBillingDate)}</b>. If you
          change your mind, you can renew your subscription on{' '}
          <a
            style={styles.link}
            href={`${STRIPE_CUSTOMER_PORTAL_LINK}?prefilled_email=${email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {STRIPE_PORTAL_NAME}
          </a>
        </p>
      </div>

      <button onClick={() => setOpen(false)} style={styles.button}>
        <XCustom />
      </button>
    </div>
  );
}

export default SubscriptionWillCancelBanner;
