import { Icon } from '@chakra-ui/react';

export function ShoppingBag(props: any) {
  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM176,88a48,48,0,0,1-96,0,8,8,0,0,1,16,0,32,32,0,0,0,64,0,8,8,0,0,1,16,0Z"
      ></path>
    </Icon>
  );
}
