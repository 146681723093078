import React from 'react';
import { Flex, Box, Text, VStack, Heading, HStack } from '@chakra-ui/react';
import { CheckIcon } from '../../theme/overrides/CustomIcons';
import MerchantsSliders from './MerchantsSliders';
import { FormikValues } from 'formik';
import {
  FONT_SECONDARY,
  FONT_TERTIARY,
  MENU_UPLOAD_PRICE,
} from '../../constants/constants';
import { PLAN_TRIAL_PERIOD, Plans } from '../../constants';
import Decimal from 'decimal.js';
import convertDecimalJsToNumber from '../../utils/convertDecimalJsToNumber';

interface PlanDetailsSideBarProps {
  values: FormikValues;
  planDetails: {
    name: string;
    description: string;
    monthlyPrice: number;
    yearlyPrice: number;
    id: Plans;
    planDetails: {
      title: string;
      features: string[];
    };
  };
}

export default function PlanDetailsSideBar({
  values,
  planDetails,
}: PlanDetailsSideBarProps) {
  const { name, planDetails: details, monthlyPrice, yearlyPrice } = planDetails;

  const fullYearPrice = React.useMemo(() => {
    const yearlyPriceDecimal = new Decimal(yearlyPrice || '0').times(12);
    return convertDecimalJsToNumber(yearlyPriceDecimal);
  }, [yearlyPrice]);

  return (
    <Flex
      w="346px"
      bgColor="rgb(202, 223, 251)"
      px={4}
      pt={12}
      pb={16}
      display={{ lg: 'flex', base: 'none' }}
      direction="column"
      justify="space-between"
    >
      <VStack borderRadius="2xl" bg="white" p={4} align="stretch" spacing={2.5}>
        <Box>
          <Text fontSize="lg" color="rgb(231, 140, 63)" lineHeight="1.4em">
            {name}
          </Text>
          <Heading
            fontSize="xl"
            fontWeight="extrabold"
            fontFamily={FONT_SECONDARY}
            lineHeight="1.4em"
          >
            {PLAN_TRIAL_PERIOD} days free trial
          </Heading>
        </Box>

        <Text
          fontSize="sm"
          pt={1}
          fontFamily={FONT_TERTIARY}
          fontWeight="bold"
          letterSpacing={0}
        >
          {details.title}
        </Text>

        {details.features.map((feature, index) => {
          return (
            <HStack key={String(index)} spacing={3}>
              <CheckIcon
                sx={{ width: 6, height: 6, fill: 'rgb(102, 102, 255)' }}
              />
              <Text
                fontWeight="semibold"
                fontSize="sm"
                fontFamily={FONT_SECONDARY}
                color="#011439"
              >
                {feature}
              </Text>
            </HStack>
          );
        })}

        <HStack justify="space-between" pt={0.5}>
          <Text fontSize="xs" color="#718096">
            {name}
          </Text>
          <Text fontSize="xs" color="#718096">
            ${values.isMonthlyPlan ? monthlyPrice : yearlyPrice}/month
          </Text>
        </HStack>

        {values.menu && (
          <HStack justify="space-between">
            <Text fontSize="xs" color="#718096">
              Menu Upload
            </Text>
            <Text fontSize="xs" color="#718096">
              ${MENU_UPLOAD_PRICE} (one-time)
            </Text>
          </HStack>
        )}

        <HStack justify="space-between">
          <Text
            fontWeight="bold"
            fontSize="sm"
            fontFamily={FONT_TERTIARY}
            color="#241c15"
          >
            Total after trial
          </Text>
          <Text
            fontWeight="bold"
            fontSize="sm"
            fontFamily={FONT_TERTIARY}
            color="#241c15"
          >
            ${values.isMonthlyPlan ? monthlyPrice : fullYearPrice}
          </Text>
        </HStack>

        {values.menu && (
          <HStack justify="space-between">
            <Text fontWeight="medium" fontSize="lg" color="#1a202c">
              Due today
            </Text>
            <Text fontWeight="medium" fontSize="lg" color="#1a202c">
              ${MENU_UPLOAD_PRICE}
            </Text>
          </HStack>
        )}
      </VStack>

      <MerchantsSliders type={1} />
    </Flex>
  );
}
