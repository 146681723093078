import { useState, useRef, useEffect } from 'react';
import { getAddressComponents, getMeters } from '../utils';
import { AutoGeneratedAddress } from '../types/common';

const getZoomLevel = (radius: number) => {
  if (radius <= 300) {
    return 18;
  }
  if (radius <= 1000) {
    return 15;
  }
  if (radius <= 4000) {
    return 14;
  }
  if (radius <= 6000) {
    return 13;
  }
  if (radius <= 10000) {
    return 12;
  }
  if (radius <= 25000) {
    return 11;
  }
  return 10;
};

type LatLng = { lat: number; lng: number };

interface DeliverySettingsTypes {
  coordinates: LatLng | null;
  radius: number;
  setAddressValues: (addressInfo: AutoGeneratedAddress) => void;
}

const useDeliverySettings = ({
  radius,
  coordinates,
  setAddressValues,
}: DeliverySettingsTypes) => {
  // eslint-disable-next-line no-undef
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [loading, setLoading] = useState(true);

  const circleRef = useRef<any>(null);
  const markerRef = useRef<any>(null);

  useEffect(() => {
    if (!map || !coordinates) return;

    //REMOVE PREVIOUS ITEMS
    markerRef.current?.setMap(null);
    circleRef.current?.setMap(null);

    markerRef.current = new window.google.maps.Marker({
      position: coordinates,
      map,
    });

    const radiusInMeters = Number(getMeters(radius));
    const zoomLevel = getZoomLevel(radiusInMeters);

    map.setZoom(zoomLevel);
    map.setCenter(coordinates);
    circleRef.current = new window.google.maps.Circle({
      editable: false,
      center: coordinates,
      radius: radiusInMeters,
      visible: true,
      fillColor: '#ffb4b3',
      strokeColor: '#df4435',
      strokeWeight: 5,
      strokeOpacity: 0.4,
      map,
    });
  }, [map, coordinates?.lat, coordinates?.lng, radius]);

  useEffect(() => {
    if (!map) return;

    if (coordinates) {
      setLoading(false);
      return;
    }

    // ----- GET USER ADDRESS GEOCODING -----
    if (!window.navigator?.geolocation) {
      setLoading(false);
      return;
    }

    window.navigator.geolocation.getCurrentPosition(
      async ({ coords }) => {
        try {
          const { results } = await new window.google.maps.Geocoder().geocode({
            location: { lat: coords.latitude, lng: coords.longitude },
          });

          const addressInfo = await getAddressComponents(results[0]);
          if (addressInfo) {
            setAddressValues(addressInfo);
          }
        } finally {
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
      }
    );
  }, [map]);

  return {
    loading,
    setMap,
  };
};

export default useDeliverySettings;
