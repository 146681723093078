// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          borderRadius: theme.shape.borderRadius * 4,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          padding: theme.spacing(1.6),
          border: `1px solid ${theme.palette.grey[100]}`,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h4' },
        subheaderTypographyProps: {
          variant: 'body2',
          marginTop: theme.spacing(0.3),
        },
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: '2.4rem',
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
  };
}
