import _isEqual from 'lodash/isEqual';
import Decimal from 'decimal.js';
import { FormikValues } from 'formik';
import convertPercentageToRate from './convertPercentageToRate';
import {
  LocationDetails,
  LocationDetailsLocal,
} from '../types/location-details';
import {
  DEFAULT_WAITER_TIP_RATE,
  defaultSchedule,
} from '../constants/constants';
import convertRateToPercentage from './convertRateToPercentage';

export const formatStoreSettings = (locationDetails: LocationDetails) => {
  if (!locationDetails) return;

  const {
    schedule,
    waiterTipRate,
    deliveryFee,
    pickupSchedule,
    tableSchedule,
  } = locationDetails;

  // eslint-disable-next-line consistent-return
  return {
    ...locationDetails,
    schedule: schedule.length ? schedule : defaultSchedule,
    tableSchedule: tableSchedule?.length ? tableSchedule : defaultSchedule,
    pickupSchedule: pickupSchedule?.length ? pickupSchedule : defaultSchedule,
    waiterTipRate:
      waiterTipRate === null
        ? '0.00'
        : convertRateToPercentage(waiterTipRate || DEFAULT_WAITER_TIP_RATE),
    deliveryFee: deliveryFee || '0.00',
    orderNotificationMethods: locationDetails.orderNotificationMethods ?? [],
  };
};

export const getFormDataForSubmitStoreSettings = (
  newInformation: FormikValues | LocationDetailsLocal,
  oldInformation: LocationDetails
) => {
  const restaurantInformationFormData = new FormData();
  const {
    restaurantDisplayName,
    restaurantName,
    restaurantPhone,
    description,
    schedule,
    pickupTypes,
    waiterTipRate,
    waiterTipEnabled,
    deliveryFee,
    newImage,
    tableEnabled,
    pickupEnabled,
    tableSchedule,
    pickupSchedule,
  } = newInformation;

  if (restaurantDisplayName !== oldInformation.restaurantDisplayName) {
    restaurantInformationFormData.append(
      'restaurantDisplayName',
      restaurantDisplayName
    );
  }

  if (restaurantName !== oldInformation.restaurantName) {
    restaurantInformationFormData.append('restaurantName', restaurantName);
  }

  if (restaurantPhone !== oldInformation.restaurantPhone) {
    restaurantInformationFormData.append('restaurantPhone', restaurantPhone);
  }

  if (description !== oldInformation.description) {
    restaurantInformationFormData.append('description', description);
  }

  if (newImage) {
    restaurantInformationFormData.append('backgroundUrl', newImage);
  }

  if (!_isEqual(schedule, oldInformation.schedule)) {
    restaurantInformationFormData.append('schedule', JSON.stringify(schedule));
  }

  if (tableEnabled !== oldInformation.tableEnabled) {
    restaurantInformationFormData.append(
      'tableEnabled',
      JSON.stringify(tableEnabled)
    );
  }

  if (pickupEnabled !== oldInformation.pickupEnabled) {
    restaurantInformationFormData.append(
      'pickupEnabled',
      JSON.stringify(pickupEnabled)
    );
  }

  if (!_isEqual(tableSchedule, oldInformation.tableSchedule)) {
    restaurantInformationFormData.append(
      'tableSchedule',
      JSON.stringify(
        tableEnabled ? tableSchedule : oldInformation.tableSchedule
      )
    );
  }

  if (!_isEqual(pickupSchedule, oldInformation.pickupSchedule)) {
    restaurantInformationFormData.append(
      'pickupSchedule',
      JSON.stringify(
        pickupEnabled ? pickupSchedule : oldInformation.pickupSchedule
      )
    );
  }

  if (!_isEqual(pickupTypes, oldInformation.pickupTypes)) {
    restaurantInformationFormData.append(
      'pickupTypes',
      JSON.stringify(pickupTypes)
    );
  }

  if (
    waiterTipRate !== oldInformation.waiterTipRate ||
    waiterTipEnabled !== oldInformation.waiterTipEnabled
  ) {
    if (waiterTipEnabled && new Decimal(waiterTipRate).equals('0')) {
      restaurantInformationFormData.append(
        'waiterTipRate',
        JSON.stringify(null)
      );
    } else {
      restaurantInformationFormData.append(
        'waiterTipRate',
        convertPercentageToRate(waiterTipRate)
      );
    }
  }

  if (deliveryFee !== oldInformation.deliveryFee) {
    restaurantInformationFormData.append('deliveryFee', deliveryFee || '0');
  }

  if (waiterTipEnabled !== oldInformation.waiterTipEnabled) {
    restaurantInformationFormData.append(
      'waiterTipEnabled',
      JSON.stringify(waiterTipEnabled)
    );
  }

  return restaurantInformationFormData;
};
