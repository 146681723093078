import {
  Button,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatLabel,
} from '@chakra-ui/react';

interface ConnectBankAccountProps {
  handleStartLinkBankAccount: () => void;
  isLoading: boolean;
}

const BankAccountConnect = ({
  handleStartLinkBankAccount,
  isLoading,
}: ConnectBankAccountProps) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 5, lg: 8 }}>
      <Stat p={4} backgroundColor="#f8f8ff" rounded={'lg'}>
        <StatLabel fontWeight="semibold" mb={3.5} color="#241c15">
          Securely link your account
        </StatLabel>
        <StatHelpText
          fontWeight="semibold"
          mb={3.5}
          color={'rgba(92,92,92,0.8)'}
        >
          Instantly verify and connect your bank account (powered by our
          merchant processor Stripe)
        </StatHelpText>
        <Button
          variant="primary"
          onClick={handleStartLinkBankAccount}
          isLoading={isLoading}
        >
          Continue
        </Button>
      </Stat>
    </SimpleGrid>
  );
};

export default BankAccountConnect;
