import React, { ChangeEvent } from 'react';
import { Schedule } from '../../types/schedule';
import IntervalC from './IntervalC';
import {
  DAY_NAMES,
  MAX_SCHEDULE_INTERVALS_ALLOWED,
} from '../../constants/constants';
import { PlusIcon } from '../../theme/overrides/CustomIcons';
import { FormikErrors } from 'formik';
import {
  Switch,
  Text,
  HStack,
  IconButton,
  VStack,
  Box,
  Stack,
} from '@chakra-ui/react';
import AnimateContainer from '../common/AnimateContainer';

interface DayScheduleProps {
  daySchedule: Schedule;
  onToggleActive: (event: ChangeEvent<HTMLInputElement>) => void;
  handleAddInterval: (day: number) => void;
  handleDeleteInterval: (day: number, index: number) => void;
  handleIntervalUpdates: ({
    name,
    value,
    dayNumber,
    intervalId,
  }: {
    name: string;
    value: string;
    dayNumber: number;
    intervalId: number;
  }) => void;
  errors?: FormikErrors<any>;
  objKey: string;
}

const DaySchedule = (props: DayScheduleProps) => {
  const {
    daySchedule,
    handleAddInterval,
    handleDeleteInterval,
    errors = {},
    objKey,
    handleIntervalUpdates,
    onToggleActive,
  } = props;
  const { day, intervals, active } = daySchedule;

  return (
    <Stack
      spacing={6}
      className="day-schedule"
      align="stretch"
      direction={{ base: 'column', md: 'row' }}
      w={{ base: '100%', md: 'auto' }}
      sx={{ minHeight: '65px' }}
    >
      <VStack justify="flex-end" maxHeight={16}>
        <HStack minHeight={10}>
          {/*@ts-ignore*/}
          <Text sx={{ minW: 12 }}>{DAY_NAMES[day]?.shortName}</Text>
          <Box>
            <Switch
              colorScheme="green"
              value={day}
              size="lg"
              isChecked={active}
              onChange={onToggleActive}
            />
          </Box>
        </HStack>
      </VStack>

      <VStack spacing={2} justifyContent="flex-end" sx={{ minHeight: '65px' }}>
        {intervals.length === 0 && (
          <Text
            py={2}
            w={{ base: '100%', md: '36.49rem' }}
            color="gray.400"
            textAlign={{ base: 'center', md: 'left' }}
          >
            Unavailable
          </Text>
        )}

        {intervals.map((interval, index) => (
          <AnimateContainer animate key={`${day}-${index}`}>
            <IntervalC
              index={index}
              interval={interval}
              handleDeleteInterval={() => handleDeleteInterval(day, index)}
              error={errors[`${objKey}-${day}-${index}`]}
              handleIntervalUpdates={(time, name) =>
                handleIntervalUpdates({
                  name,
                  value: time,
                  dayNumber: day,
                  intervalId: index,
                })
              }
            />
          </AnimateContainer>
        ))}
      </VStack>

      <VStack justify="flex-end">
        <IconButton
          aria-label="Add interval"
          variant="ghost"
          icon={<PlusIcon sx={{ width: 6, height: 6 }} />}
          onClick={() => handleAddInterval(day)}
          disabled={intervals.length === MAX_SCHEDULE_INTERVALS_ALLOWED}
        />
      </VStack>
    </Stack>
  );
};

export default DaySchedule;
