// ----------------------------------------------------------------------

export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: theme.shadows[0],
          width: '80%',
          maxWidth: '63.5rem',
          minHeight: '30rem',
          maxHeight: '73rem',
          '&.MuiPaper-rounded': {
            borderRadius: theme.shape.borderRadius * 6,
          },
          '&.MuiDialog-paperFullScreen': {
            borderRadius: 0,
          },
          '&.MuiDialog-paper .MuiDialogActions-root': {},
          '@media (max-width: 600px)': {},
          '@media (max-width: 663.95px)': {
            '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
              maxWidth: '100%',
            },
          },
        },
        paperFullWidth: {
          width: '100%',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '5.2rem 1rem 4.8rem 1rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: '6rem',
          paddingRight: '6rem',
          borderTop: 0,
          borderBottom: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '2rem 1rem 3.6rem 1rem',
          justifyContent: 'center',
          '& > :not(:first-of-type)': { marginLeft: '1.6rem' },
        },
      },
    },
  };
}
