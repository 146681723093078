import moment from 'moment';
import { CLOCK_FORMATS } from '../constants/constants';

const validateStartingAndClosingTime = (
  intervals,
  scheduleErrors,
  day,
  objKey = 'schedule'
) => {
  const errorsCopy = { ...scheduleErrors };

  intervals.forEach((interval, i) => {
    const { from, to } = interval;

    const fromMoment = moment(from, CLOCK_FORMATS.hour12);
    const toMoment = moment(to, CLOCK_FORMATS.hour12);

    if (!fromMoment.isBefore(toMoment) || fromMoment.isSame(toMoment)) {
      errorsCopy[`${objKey}-${day}-${i}`] =
        'Choose an end time later than the start time.';
    }
  });

  return errorsCopy;
};

export default validateStartingAndClosingTime;
