import _split from 'lodash/split';

const sliceLongerFileName = (fileName = '') => {
  try {
    const regExp = /(?:\.([^.]+))?$/;
    // @ts-ignore
    const fileExt = regExp.exec(fileName)[1];
    if (fileName.length < 15) {
      return {
        fileExt,
        fileName,
      };
    }

    const [firstElement = ''] = _split(fileName, '.', 1);
    return {
      fileExt,
      fileName: `${firstElement.slice(0, 8)}...${fileExt}`,
    };
  } catch (e) {
    return {
      fileExt: '',
      fileName: '',
    };
  }
};

export default sliceLongerFileName;
