import React from 'react';
import { Button, useToast, Flex, Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from '../../store/store';
import { useFormik } from 'formik';
import { updateOnBoarding } from '../../store/slices/onBoarding';
import { useNavigate } from 'react-router-dom';
import { PICKUP_SETTINGS } from '../../routes';
import AnimateContainer from '../common/AnimateContainer';
import BankAccountConnection from '../Settings/BankAccountConnection';

const LinkBankAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const { loading: onBoardingLoading, onBoarding } = useReduxSelector(
    (state) => state.onBoarding
  );
  const { bankAccount } = onBoarding;

  const { handleSubmit, isValid, submitCount } = useFormik({
    initialValues: {
      payload: {},
    },
    onSubmit: () => {
      if (!bankAccount.token) {
        toast({
          title: 'Please link your bank account',
          status: 'error',
          isClosable: true,
        });
        return;
      }

      navigate(PICKUP_SETTINGS);
    },
  });

  const onSuccess = async (
    payload: any,
    setIsLoading: (isLoading: boolean) => void
  ) => {
    dispatch(
      updateOnBoarding(
        { step: 5, data: { bankAccount: payload } },
        () => {
          toast({
            title: 'Successfully added bank account.',
            status: 'success',
            isClosable: true,
          });
          setTimeout(() => {
            setIsLoading(false);
            navigate(PICKUP_SETTINGS);
          }, 300);
        },
        (e: string) => {
          setIsLoading(false);
          toast({
            title: e,
            status: 'error',
            isClosable: true,
          });
        }
      )
    );
  };

  return (
    <>
      <Box px={{ lg: 6, base: 4 }} sx={{ flex: 1 }} py={6} overflow="auto">
        <AnimateContainer animate>
          <form onSubmit={handleSubmit} id="on-boarding">
            <BankAccountConnection
              stripeAccountId={onBoarding.stripeAccountId}
              onSuccess={onSuccess}
              stripePayoutBank={bankAccount}
              onBoardingPage
            />
          </form>
        </AnimateContainer>
      </Box>
      <Flex
        borderTop={1}
        borderColor="gray.200"
        borderStyle="solid"
        p={6}
        justify="flex-end"
      >
        <Button
          isLoading={onBoardingLoading}
          disabled={(!isValid && submitCount > 0) || onBoardingLoading}
          type="submit"
          form="on-boarding"
          size="lg"
          variant="primary"
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default LinkBankAccount;
