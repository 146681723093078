// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.primary.main,
          fontSize: '1.4rem',
          fontWeight: theme.typography.fontWeightRegular,
        },
        arrow: {
          color: theme.palette.primary.main,
        },
      },
    },
  };
}
