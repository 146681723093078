// ----------------------------------------------------------------------

export default function CssBaseline(theme) {
  const baseStyles = {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  };

  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': baseStyles,
        '*::before': baseStyles,
        '*::after': baseStyles,
        '::selection': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.main,
        },
        '#root': {
          height: '100%',
          width: '100%',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          // This defines what 1rem is
          [theme.breakpoints.up('xl')]: {
            //1536
            fontSize: '75%', //1rem = 12, 12/16
          },
          [theme.breakpoints.up('sm')]: {
            // 600
            fontSize: '62.5%', //1 rem = 10px; 10px/16px = 62.5%
          },
          [theme.breakpoints.down('sm')]: {
            // 600
            fontSize: '50%', //1 rem = 8px, 8/16 = 50%
          },
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#__next': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        a: {
          textDecoration: 'none',
          color: theme.palette.primary.main,
        },
      },
    },
  };
}
