import { Button, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { CheckIcon } from '../../theme/overrides/CustomIcons';
import {
  BRAND_NAME,
  FONT_TERTIARY,
  PRIVACY_PAGE,
  TERMS_PAGE,
} from '../../constants/constants';
import { PLAN_TRIAL_PERIOD } from '../../constants';
import StripeElementInput from '../Inputs/StripeElementInput';

interface StartFreeTrialProps {
  isSubmitting: boolean;
  isMonthlyPlan: boolean;
}

export default function StartFreeTrial({
  isMonthlyPlan,
  isSubmitting,
}: StartFreeTrialProps) {
  return (
    <Stack spacing={5} mx={'auto'} w="full" maxW="608px" align="center">
      <Heading
        fontSize={{ lg: '4xl', base: '3xl' }}
        textAlign="center"
        fontWeight="medium"
      >
        Start your free trial
      </Heading>

      <StripeElementInput loading={false} />

      <Stack spacing={4}>
        <Button
          variant="primary"
          loadingText="Submitting"
          size="lg"
          type="submit"
          isLoading={isSubmitting}
        >
          Start using Crave
        </Button>

        <Stack spacing={3.5}>
          <HStack>
            <CheckIcon sx={{ width: 4, height: 4 }} />
            <Text
              fontWeight="bold"
              fontSize="sm"
              color="#666666"
              fontFamily={FONT_TERTIARY}
            >
              You won't be billed today
            </Text>
          </HStack>

          <HStack>
            <CheckIcon sx={{ width: 4, height: 4 }} />
            <Text
              fontWeight="bold"
              fontSize="sm"
              color="#666666"
              fontFamily={FONT_TERTIARY}
            >
              Cancel any time before{' '}
              {moment().add(PLAN_TRIAL_PERIOD, 'days').format('MMMM D, YYYY')}
            </Text>
          </HStack>
        </Stack>

        <Text fontSize="xs" color="#6c7177" lineHeight="2em">
          {`Clicking the "Start using Crave" button above means that you have read ${BRAND_NAME}'s`}{' '}
          <a
            href={PRIVACY_PAGE}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#3a1960' }}
          >
            Privacy Policy
          </a>{' '}
          {`and have reviewed and agree to ${BRAND_NAME}'s`}{' '}
          <a
            href={TERMS_PAGE}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#3a1960' }}
          >
            Terms of Service
          </a>
          {`, which will govern your use
          of ${BRAND_NAME}'s products during and after the ${PLAN_TRIAL_PERIOD} Days Trial period. Immediately after the Trial
          Period, your credit card will be charged ${
            isMonthlyPlan ? 'monthly' : 'yearly'
          }, and your fees may vary based on usage.
          Or view Crave workspace without a trial.`}
        </Text>
      </Stack>
    </Stack>
  );
}
