import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import store from './store/store';
import App from './App';

import { STRIPE_PUBLISHABLE_KEY } from './constants/constants';
import { GoogleOAuthProvider } from '@react-oauth/google';

console.log(process.env.REACT_APP_CRAVE_API_URL);

const container = document.getElementById('root');
const root = createRoot(container);

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_IDENTITY_CLIENT_ID}
          >
            <App />
          </GoogleOAuthProvider>
        </BrowserRouter>
      </Elements>
    </Provider>
  </React.StrictMode>
);
