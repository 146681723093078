import { createSlice } from '@reduxjs/toolkit';
import { dispatch, getState } from '../store';
import { openToast } from './snackbar';
import { formatApiError } from '../../utils';
import crave from '../../apis/crave';
import { DESIGN_QR_CODE_PAGE, QR_CODE_PAGE } from '../../routes';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  saveLoading: false,
  duplicateLoading: false,
  exportLoading: false,
  error: null,
  qrCodes: [],
  deleteQrId: '',
  qrCode: null,
};

const slice = createSlice({
  name: 'qrCode',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    startSaveLoading(state) {
      state.saveLoading = true;
    },

    startDuplicateLoading(state) {
      state.duplicateLoading = true;
    },

    startExportLoading(state) {
      state.exportLoading = true;
    },

    stopExportLoading(state) {
      state.exportLoading = false;
    },

    hasError(state, action) {
      state.loading = false;
      state.saveLoading = false;
      state.duplicateLoading = false;
      state.error = action.payload;
    },

    setDeleteQrId(state, action) {
      state.deleteQrId = action.payload || '';
    },

    getQrCodesSuccess(state, action) {
      state.loading = false;
      state.qrCodes = action.payload;
    },

    saveQrSuccess(state, action) {
      state.saveLoading = false;
      state.qrCodes = state.qrCodes.map((qrCode) => {
        if (qrCode.id === action.payload.id) {
          return action.payload;
        }

        return qrCode;
      });
    },

    requestGetQrCodeSuccess(state) {
      state.loading = false;
      state.qrCode = null;
    },

    getQrCodeSuccess(state, action) {
      state.loading = false;
      state.qrCode = action.payload;
    },

    deleteQrCodeSuccess(state, action) {
      state.loading = false;
      state.qrCodes = state.qrCodes.filter(
        (qrCode) => qrCode.id !== action.payload
      );
      state.deleteQrId = '';
    },

    duplicateQrCodeSuccess(state, action) {
      state.duplicateLoading = false;
      state.qrCodes.push(action.payload);
    },
  },
});

// Actions
export const { setDeleteQrId, startExportLoading, stopExportLoading } =
  slice.actions;
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQrCodes() {
  return async () => {
    try {
      const { auth } = getState();
      const { merchantId, locationId, token } = auth.user;

      dispatch(slice.actions.startLoading());

      const { data } = await crave.get(
        `merchants/${merchantId}/locations/${locationId}/qrcodes`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      dispatch(slice.actions.getQrCodesSuccess(data.data));
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
    }
  };
}

// ----------------------------------------------------------------------

export function createQr(qrcodeData, navigate) {
  return async () => {
    const { auth } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { logo, id, ...remainingData } = qrcodeData;
    try {
      dispatch(slice.actions.startLoading());

      const { data } = await crave.post(
        `merchants/${merchantId}/locations/${locationId}/qrcodes`,
        {
          metadata: remainingData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      dispatch(slice.actions.saveQrSuccess(data));
      navigate(`${DESIGN_QR_CODE_PAGE}/${data.id}`);
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
      navigate(QR_CODE_PAGE);
    }
  };
}

// ----------------------------------------------------------------------

const formatValues = (data) => {
  const { metadata, url, id } = data;

  return {
    logo: '',
    logoBase64: metadata.logoBase64,
    message: metadata.message,
    stationLabel: metadata.stationLabel,
    numOfTables: metadata.numOfTables,
    fontFamily: metadata.fontFamily,
    fontColor: metadata.fontColor,
    templateId: metadata.templateId,
    templateName: metadata.templateName,
    id,
    wallpaper: metadata.wallpaper,
    uploadedWallpaper: '',
    backgroundColor: metadata.backgroundColor,
    qrUrl: url,
  };
};

export function saveQrCode(qrcodeData, resetForm) {
  return async () => {
    const { auth } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { logo, id, uploadedWallpaper, ...remainingData } = qrcodeData;

    try {
      dispatch(slice.actions.startSaveLoading());
      const { data } = await crave.patch(
        `merchants/${merchantId}/locations/${locationId}/qrcodes/${id}`,
        {
          metadata: remainingData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      dispatch(slice.actions.saveQrSuccess(data));
      resetForm({ values: formatValues(data) });
      dispatch(openToast({ message: 'Saved!', type: 'success' }));
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function getQrCode(qrId, navigate) {
  return async () => {
    const { auth } = getState();
    const { merchantId, locationId, token } = auth.user;

    try {
      dispatch(slice.actions.requestGetQrCodeSuccess());

      const { data } = await crave.get(
        `merchants/${merchantId}/locations/${locationId}/qrcodes/${qrId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      dispatch(slice.actions.getQrCodeSuccess(data));
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
      navigate(QR_CODE_PAGE);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteQrCode(onSuccess, onFail) {
  return async () => {
    const { auth, qrCode } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { deleteQrId } = qrCode;

    try {
      dispatch(slice.actions.startLoading());

      await crave.delete(
        `merchants/${merchantId}/locations/${locationId}/qrcodes/${deleteQrId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      onSuccess();
      dispatch(slice.actions.deleteQrCodeSuccess(deleteQrId));
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      onFail(e);
    }
  };
}

// ----------------------------------------------------------------------

export function duplicateQr(qrId, onSuccess, onFail) {
  return async () => {
    const { auth } = getState();
    const { merchantId, locationId, token } = auth.user;

    try {
      dispatch(slice.actions.startDuplicateLoading());

      const { data } = await crave.get(
        `merchants/${merchantId}/locations/${locationId}/qrcodes/${qrId}/duplicate`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(slice.actions.duplicateQrCodeSuccess(data));
      onSuccess();
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      onFail(e);
    }
  };
}
