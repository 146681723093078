import { Schedule } from '../types/schedule';

export const getScheduleStatus = (schedule: Schedule[]) => {
  let isAtLeastOneDayIntervalSet = false;

  schedule.forEach(({ intervals }) => {
    if (intervals.length > 0) {
      isAtLeastOneDayIntervalSet = true;
    }
  });

  return isAtLeastOneDayIntervalSet;
};
