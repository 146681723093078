import { CSSProperties } from 'react';

const warningIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    fill="#d43811"
    viewBox="0 0 256 256"
  >
    <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM120,104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,128,192Z"></path>
  </svg>
);

const styles: {
  mainContainer: CSSProperties;
  heading: CSSProperties;
  text: CSSProperties;
} = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '0 16px',
  },
  heading: {
    fontSize: '36px',
    fontWeight: 'bold',
    margin: '20px 0',
    color: '#241c15',
    lineHeight: 1.3,
  },
  text: {
    fontSize: '20px',
    color: '#363c42',
    textAlign: 'center',
    lineHeight: 1.3,
    fontWeight: 500,
  },
};

const SubscriptionCanceled = () => (
  <div style={styles.mainContainer}>
    {warningIcon}
    <h2 style={styles.heading}>Subscription Canceled</h2>
    <p style={styles.text}>
      You can’t access this store because the last bill payment failed. Please
      contact our team to start selling again.
    </p>
  </div>
);

export default SubscriptionCanceled;
