import './assets/scss/base/base.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Private from './routes/Private';
import BeatLoader from './components/common/BeatLoader/BeatLoader';
import { loadUser } from './store/slices/auth';
import Public from './routes/Public';

const style = {
  width: '100%',
  height: 'calc(100vh - 35px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loadingUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(loadUser(navigate));
  }, []);

  if (loadingUser) {
    return (
      <div style={style}>
        <BeatLoader load />
      </div>
    );
  }

  return user.authenticated ? <Private /> : <Public />;
}

export default App;
