import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';

interface DialogCProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: string;
  title?: string;
  saveButtonText?: string;
  closeButtonText?: string;
  formId?: string;
  loading?: boolean;
  sx?: SystemStyleObject;
  text?: string;
}

function DialogC({
  open,
  onClose,
  children,
  size,
  title,
  closeButtonText,
  saveButtonText,
  formId,
  loading,
  sx,
  text,
}: DialogCProps) {
  return (
    <Modal isOpen={open} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent sx={sx}>
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalCloseButton />
        <ModalBody>
          {text && <Text>{text}</Text>}
          {children}
        </ModalBody>

        <ModalFooter>
          {closeButtonText && (
            <Button variant="ghost" mr={3} onClick={onClose} disabled={loading}>
              {closeButtonText}
            </Button>
          )}
          {saveButtonText && (
            <Button
              type={formId ? 'submit' : 'button'}
              form={formId}
              variant="primary"
              isLoading={loading}
              className="submit-button"
            >
              {saveButtonText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DialogC;
