import React from 'react';
import {
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
  SystemStyleObject,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Tooltip,
} from '@chakra-ui/react';
import { Info } from '../../assets/icons';

interface InputWithLabelProps {
  isInvalid?: boolean;
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  value: string;
  error?: string;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  sx?: SystemStyleObject;
  rightIcon?: React.ReactElement | string;
  leftIcon?: React.ReactElement | string;
  disabled?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  labelInfoText?: string;
}

const ChTextArea = ({
  isInvalid,
  id,
  label,
  placeholder,
  name,
  value,
  error,
  handleChange,
  sx,
  rightIcon,
  leftIcon,
  disabled,
  autoComplete,
  readOnly,
  labelInfoText,
}: InputWithLabelProps) => {
  return (
    <FormControl isInvalid={isInvalid} sx={sx}>
      {label && (
        <FormLabel htmlFor={id}>
          {label}{' '}
          {labelInfoText && (
            <Tooltip shouldWrapChildren label={labelInfoText}>
              <Info width={22} height={22} />
            </Tooltip>
          )}{' '}
        </FormLabel>
      )}
      <InputGroup>
        <Textarea
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          size="lg"
          disabled={disabled}
          autoComplete={autoComplete}
          readOnly={readOnly}
        />

        {leftIcon && <InputLeftElement h="full">{leftIcon}</InputLeftElement>}
        {rightIcon && (
          <InputRightElement h="full">{rightIcon}</InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default ChTextArea;
