import {
  Box,
  Heading,
  IconButton,
  Image,
  Skeleton,
  SkeletonText,
  Stack,
} from '@chakra-ui/react';
import { CRAVE_THUMBNAIL } from '../../constants';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RiShareBoxLine } from 'react-icons/ri';
import { FormikValues } from 'formik';

const headerBoxStyle = {
  height: '165px',
  width: '100%',
  position: 'relative',
  borderRadius: '0 0 12px 12px',
  overflow: 'hidden',

  '&:before': {
    content: "''",
    background:
      'linear-gradient(180deg, rgba(21, 8, 36, 0.3) 29.28%, rgba(21, 8, 36, 0) 100%)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },

  '& .header__bg-image': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  '& .header__logo-box': {
    borderRadius: '4px',
    bgcolor: 'background.paper',
    overflow: 'hidden',
    position: 'absolute',
    bottom: '35px',
    left: '15px',
    zIndex: 2,
  },

  '& .header__icon': {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
    '&.share-icon': {
      left: 'auto',
      right: 0,
    },
  },
};

type HeaderProps = {
  backgroundUrl?: string;
  logo?: string;
};

const Header = ({ backgroundUrl, logo }: HeaderProps) => {
  return (
    <Box sx={headerBoxStyle}>
      <IconButton
        aria-label="menu"
        className="header__icon"
        icon={
          <GiHamburgerMenu color="#fff" style={{ width: 22, height: 22 }} />
        }
        bg="transparent"
        _hover={{ bg: 'transparent' }}
      />

      <IconButton
        className="header__icon share-icon"
        aria-label="share"
        icon={<RiShareBoxLine color="#fff" style={{ width: 22, height: 22 }} />}
        bg="transparent"
        _hover={{ bg: 'transparent' }}
      />

      {/*BACKGROUND IMAGE*/}
      <Image
        className="header__bg-image"
        src={backgroundUrl || CRAVE_THUMBNAIL}
        alt="bgImage"
        objectFit="cover"
        placeholder="blur"
      />

      {/*LOGO*/}
      {logo && (
        <Box className="header__logo-box">
          <Image
            src={logo}
            alt="bgImage"
            width="40px"
            height="40px"
            objectFit="contain"
          />
        </Box>
      )}
    </Box>
  );
};

interface MobilePreviewProps {
  values: FormikValues;
  businessName: string;
}

const MobilePreview = ({ values, businessName }: MobilePreviewProps) => {
  const logo = values.newLogo
    ? URL.createObjectURL(values.newLogo)
    : values.logo;
  const cover = values.newCover
    ? URL.createObjectURL(values.newCover)
    : values.cover;

  return (
    <Box
      h="667px"
      w="375px"
      bg="white"
      boxShadow="rgba(0, 0, 0, 0.15) 0px 10px 30px, rgba(0,0, 0, 0.2) 0px 0px 15px inset"
      borderRadius="33px"
      p={4}
      position="relative"
      transform="scale(0.85)"
      display={{ base: 'none', lg: 'block' }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
          height: '20px',
          width: '40%',
          bg: 'white',
          borderRadius: '0px 0px 12px 12px',
        }}
      />
      <Stack
        borderRadius="20px"
        w="full"
        h="full"
        overflow="hidden"
        spacing={0}
        sx={{ '& div': { animation: 'none' } }}
      >
        <Header logo={logo} backgroundUrl={cover} />
        <Box
          pb={2}
          bg="white"
          p={4}
          flex={1}
          borderRadius="24px 24px 0 0"
          overflow="hidden"
          sx={{
            mt: '-25px !important',
            zIndex: 1,
            position: 'relative',
          }}
        >
          <Heading fontSize="28px" color="#313134">
            {businessName}
          </Heading>

          <SkeletonText
            mt="4"
            noOfLines={4}
            spacing="4"
            sx={{ '& div': { bg: '#d8dbdf' } }}
          />

          <Stack direction="row" justify="space-between" mt={4}>
            <Skeleton
              height="112px"
              w="full"
              borderRadius="12px"
              bg="#d8dbdf"
            />
            <Skeleton
              height="112px"
              w="full"
              borderRadius="12px"
              bg="#d8dbdf"
            />
            <Skeleton
              height="112px"
              w="full"
              borderRadius="12px"
              bg="#d8dbdf"
            />
          </Stack>

          <Stack mt={5}>
            <Skeleton height="90px" w="full" borderRadius="8px" bg="#d8dbdf" />
            <Skeleton height="90px" w="full" borderRadius="8px" bg="#d8dbdf" />
            <Skeleton height="90px" w="full" borderRadius="8px" bg="#d8dbdf" />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default MobilePreview;
