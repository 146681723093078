import React from 'react';
import { HOME_PAGE } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, VStack } from '@chakra-ui/react';
import { BackArrowIcon } from '../../theme/overrides/CustomIcons';
import AnimateContainer from '../../components/common/AnimateContainer';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <AnimateContainer animate>
      <VStack
        justify="center"
        maxW="500px"
        mx="auto"
        minH={`calc(100vh - 80px)`}
        spacing={9}
      >
        <Heading fontSize="3.125rem">Yikes, that's a 404</Heading>
        <Button
          onClick={() => navigate(HOME_PAGE)}
          leftIcon={<BackArrowIcon sx={{ width: 5, height: 5 }} color="#fff" />}
          bg="linear-gradient(90deg, rgb(155, 114, 202) 0%, rgb(0, 25, 214) 100%)"
          _hover={{
            bg: 'linear-gradient(90deg, rgb(155, 114, 202) 0%, rgb(0, 25, 214) 100%)',
          }}
          _active={{
            bg: 'linear-gradient(90deg, rgb(155, 114, 202) 0%, rgb(0, 25, 214) 100%)',
          }}
          variant="solid"
          color="white"
        >
          Go back home
        </Button>
      </VStack>
    </AnimateContainer>
  );
};

export default NotFoundPage;
