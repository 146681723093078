import {
  Box,
  Button,
  HStack,
  Image,
  Stack,
  Tag,
  Text,
  TagLabel,
} from '@chakra-ui/react';
import { WhiteLabelMobileApp } from '../../types/common';
import CircularLoader from '../common/CircularLoader';
import { CodeMagicBuildStatus } from '../../constants';

interface MobileAppSubmittedProps {
  onOpen: () => void;
  whiteLabelMobileApp: WhiteLabelMobileApp;
  manualReleaseRequired: boolean;
  isReleaseInProgress: boolean;
}

const getStatus = (
  whiteLabelMobileApp: WhiteLabelMobileApp,
  manualReleaseRequired?: boolean
) => {
  if (manualReleaseRequired) {
    return {
      message: whiteLabelMobileApp.appStoreConnectStatus || 'Build successful',
    };
  }

  const loadingStatuses = ['building', 'preparing', 'publishing', 'queued'];
  if (loadingStatuses.includes(whiteLabelMobileApp.buildStatus || '')) {
    return {
      message: whiteLabelMobileApp.buildStatus,
      loading: true,
    };
  }

  if (whiteLabelMobileApp.buildStatus === CodeMagicBuildStatus.FINISHED) {
    return {
      message: whiteLabelMobileApp.appStoreConnectStatus || 'N/A',
    };
  }

  return {
    message: whiteLabelMobileApp.buildStatus,
  };
};

function MobileAppSubmitted({
  onOpen,
  whiteLabelMobileApp,
  manualReleaseRequired,
  isReleaseInProgress,
}: MobileAppSubmittedProps) {
  const { loading, message } = getStatus(
    whiteLabelMobileApp,
    manualReleaseRequired
  );

  return (
    <Stack
      border="1px solid rgb(237, 239, 243)"
      borderRadius={8}
      p={8}
      spacing={8}
      align="flex-start"
      position="relative"
    >
      <Stack spacing={4} direction="row" align="center">
        <Box boxSize={10}>
          <Image
            src={whiteLabelMobileApp.appIcon}
            alt={whiteLabelMobileApp.appName}
          />
        </Box>

        <Stack>
          <Text fontSize="xl" fontWeight="semibold">
            {whiteLabelMobileApp.appName}
          </Text>
          <Text fontSize="sm" color="#6b7177">
            {whiteLabelMobileApp.appBundleId}
          </Text>
        </Stack>

        {whiteLabelMobileApp.buildStatus && (
          <Tag
            size="md"
            colorScheme="gray"
            position="absolute"
            top={3}
            right={3}
            textTransform="capitalize"
          >
            {loading && <CircularLoader size={4} sx={{ p: 0, mr: 2 }} />}
            <TagLabel>{message}</TagLabel>
          </Tag>
        )}
      </Stack>

      <HStack>
        <Button onClick={onOpen} variant="outline">
          {manualReleaseRequired || isReleaseInProgress
            ? 'View App Details'
            : 'Edit'}
        </Button>
      </HStack>

      {whiteLabelMobileApp.buildMessage && (
        <Text fontSize="sm" color="#e53e3e">
          {manualReleaseRequired ? '' : whiteLabelMobileApp.buildMessage}
        </Text>
      )}
    </Stack>
  );
}

export default MobileAppSubmitted;
