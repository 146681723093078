import { CSSProperties } from 'react';
import { STRIPE_CUSTOMER_PORTAL_LINK } from '../../constants';
import useAuth from '../../hooks/useAuth';

const styles: {
  container: CSSProperties;
  text: CSSProperties;
  link: CSSProperties;
} = {
  container: {
    backgroundColor: '#fed7d7d7',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    color: '#363c42',
    fontSize: 14,
    lineHeight: 1.5,
    marginLeft: 10,
  },
  link: {
    display: 'inline-block',
    color: '#363c42',
    fontSize: 14,
    lineHeight: 1.5,
    cursor: 'pointer',
    textDecoration: 'underline',
    alignSelf: 'flex-end',
  },
};

const infoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="#e53e3e"
    viewBox="0 0 256 256"
  >
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
  </svg>
);

function SubscriptionExpiredBanner() {
  const { email } = useAuth();

  return (
    <div style={styles.container}>
      {infoIcon}
      <div>
        <p style={styles.text}>
          <b>Payment processing failed.</b>{' '}
        </p>
        <p style={styles.text}>
          Unable to charge your card. Please update payment method for
          uninterrupted service.{' '}
          <a
            style={styles.link}
            href={`${STRIPE_CUSTOMER_PORTAL_LINK}?prefilled_email=${email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Update Payment
          </a>
        </p>
      </div>
    </div>
  );
}

export default SubscriptionExpiredBanner;
