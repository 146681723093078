import { Stack } from '@chakra-ui/react';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import ChInput from '../common/ChInput';
import SelectWithLabel from '../common/SelectWithLabel';
import DialogC from '../common/DialogC';
import { AccountHolderTypes, Countries, Currencies } from '../../constants';
import useAuth from '../../hooks/useAuth';

const validate = (values: FormikValues) => {
  const errors = {} as Record<string, string>;

  if (values.country === Countries.GB) {
    if (!values.accountHolderName) {
      errors.accountHolderName = 'Required';
    }
    if (!values.accountNumber) {
      errors.accountNumber = 'Required';
    } else if (values.accountNumber.length !== 8) {
      errors.accountNumber = 'Invalid account number';
    }
    if (!values.sortCode) {
      errors.sortCode = 'Required';
    } else if (!/^\d{2}-\d{2}-\d{2}$/.test(values.sortCode)) {
      errors.sortCode = 'Invalid sort code';
    }
  }

  return errors;
};

const ACCOUNT_HOLDER_TYPES = [
  { label: 'Individual', value: AccountHolderTypes.INDIVIDUAL },
  { label: 'Company', value: AccountHolderTypes.COMPANY },
];

interface AddBusinessRepresentativeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    values: FormikValues,
    { setSubmitting }: FormikHelpers<any>
  ) => void;
}

const UpdateBankAccountManually = ({
  isOpen,
  onClose,
  onSubmit,
}: AddBusinessRepresentativeDialogProps) => {
  const { merchant } = useAuth();

  const { handleSubmit, values, handleChange, errors, touched, isSubmitting } =
    useFormik({
      initialValues: {
        country: merchant.country,
        currency: Currencies.USD,
        accountHolderName: '',
        accountHolderType: AccountHolderTypes.INDIVIDUAL,
        accountNumber: '',
        sortCode: '',
      },
      validate,
      onSubmit,
    });

  return (
    <DialogC
      title="Update your bank account"
      open={isOpen}
      onClose={onClose}
      closeButtonText="Cancel"
      saveButtonText="Save"
      formId="business-representative"
      loading={isSubmitting}
      size="xl"
    >
      <form onSubmit={handleSubmit} id="business-representative">
        <Stack spacing={4}>
          <ChInput
            id="accountHolderName"
            label="Account Holder Name"
            placeholder="Enter account holder name"
            name="accountHolderName"
            value={values.accountHolderName}
            handleChange={handleChange}
            isInvalid={!!errors.accountHolderName && touched.accountHolderName}
            error={errors.accountHolderName}
          />

          <ChInput
            id="accountNumber"
            label="Account Number"
            placeholder="Enter account number"
            name="accountNumber"
            value={values.accountNumber}
            handleChange={handleChange}
            isInvalid={!!errors.accountNumber && touched.accountNumber}
            error={errors.accountNumber}
          />

          <SelectWithLabel
            id="businessType"
            label="Account Holder Type"
            name="accountHolderType"
            isInvalid={!!errors.accountHolderType && touched.accountHolderType}
            error={errors.accountHolderType}
            value={values.accountHolderType}
            handleChange={handleChange}
            options={ACCOUNT_HOLDER_TYPES}
          />

          {values.country === Countries.GB && (
            <ChInput
              id="sortCode"
              label="Sort Code"
              placeholder="Enter sort code"
              name="sortCode"
              value={values.sortCode}
              handleChange={handleChange}
              isInvalid={!!errors.sortCode && touched.sortCode}
              error={errors.sortCode}
            />
          )}
        </Stack>
      </form>
    </DialogC>
  );
};

export default UpdateBankAccountManually;
