import { useDropzone } from 'react-dropzone';
import {
  useToast,
  VStack,
  Text,
  Button,
  Box,
  Image,
  IconButton,
  CircularProgress,
  CircularProgressLabel,
  Stack,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { FolderPlus } from '../../assets/icons';
import sliceLongerFileName from '../../utils/slice-longer-file-name';
import { File as FileIcon } from '../../assets/icons/file';

// ----------------------------------------------------------------------

const DEFAULT_ERROR_MESSAGE =
  'Please upload a valid image (Accepts .gif, .jpg and .png (maximum file size: 5MB))';
const DEFAULT_ACCEPT = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
};
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MIN_FILE_SIZE = 0.5 * 1024;

// ----------------------------------------------------------------------

interface ImageVideoPickerProps {
  savedImage: string;
  newImage: any;
  onDrop: (acceptedFiles: File[]) => void;
  handleRemoveImage: () => void;
  cover?: boolean;
  progress?: number;
  buttonText?: string;
  instructionText?: string;
  accept?: { [key: string]: string[] };
  loading?: boolean;
  imagePreview?: boolean;
  fileErrorMessage?: string;
  isInvalid?: boolean;
}

// ----------------------------------------------------------------------

const ImageVideoPicker = ({
  savedImage,
  newImage,
  onDrop,
  handleRemoveImage,
  cover,
  progress,
  buttonText = 'Add files',
  instructionText,
  accept = DEFAULT_ACCEPT,
  loading,
  imagePreview = true,
  fileErrorMessage = DEFAULT_ERROR_MESSAGE,
  isInvalid,
}: ImageVideoPickerProps) => {
  const toast = useToast();

  const onDropRejected = () => {
    toast({
      title: fileErrorMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const { getInputProps, getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept,
    maxSize: MAX_FILE_SIZE,
    minSize: MIN_FILE_SIZE,
    onDropAccepted: onDrop,
    onDropRejected,
  });

  if (savedImage || (newImage && newImage?.size > 0)) {
    let fileSrc = savedImage;
    if (newImage && newImage?.size > 0) fileSrc = URL.createObjectURL(newImage);
    return (
      <Box
        w={cover ? 'full' : 40}
        h={cover ? 64 : 40}
        sx={{ position: 'relative' }}
        border="1px dashed #babfc3"
        borderRadius="lg"
        overflow="hidden"
        className="image-video-picker uploaded-image"
      >
        {newImage && loading && (
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bg: 'rgba(0,0,0,0.3)',
            }}
            align="center"
            justify="center"
          >
            <Box>
              <CircularProgress
                isIndeterminate={progress === 0 || progress === 100}
                size="60px"
                value={progress}
                color="green.400"
              >
                <CircularProgressLabel color="white">
                  {progress}%
                </CircularProgressLabel>
              </CircularProgress>
            </Box>
          </Stack>
        )}

        {imagePreview ? (
          <Image
            objectFit={cover ? 'cover' : 'contain'}
            w="full"
            h="full"
            src={fileSrc}
            alt="logo-cover"
          />
        ) : (
          <Stack
            align="center"
            justify="center"
            h="full"
            w="full"
            direction="row"
          >
            <FileIcon w={5} h={5} />
            <Text>{sliceLongerFileName(newImage.name).fileName}</Text>
          </Stack>
        )}

        {!loading && (
          <IconButton
            aria-label="Call Sage"
            fontSize="15px"
            minWidth={6}
            height={6}
            borderRadius="50%"
            icon={<AiOutlineClose />}
            onClick={handleRemoveImage}
            sx={{ position: 'absolute', top: '5px', right: '5px' }}
          />
        )}
      </Box>
    );
  }
  return (
    <VStack
      p={6}
      border="1px dashed #babfc3"
      borderRadius="lg"
      spacing={2}
      justify="center"
      borderColor={isInvalid ? '#e53e3e' : undefined}
      boxShadow={`0 0 0 1px ${isInvalid ? '#e53e3e' : 'transparent'}`}
      {...getRootProps({ className: 'image-video-picker' })}
    >
      <input {...getInputProps()} />
      <FolderPlus color="#1f5199" width={6} height={6} />
      <Button color="#1f5199" bg="#e5effd" size="sm" onClick={open}>
        {buttonText}
      </Button>
      {instructionText && (
        <Text color="#6d7175" fontSize="13px">
          {instructionText}
        </Text>
      )}
    </VStack>
  );
};

export default ImageVideoPicker;
