import { FormikValues, useFormik } from 'formik';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import {
  Box,
  Text,
  HStack,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
} from '@chakra-ui/react';
import ReceivingMethod from './ReceivingMethod';
import React, { ChangeEvent } from 'react';
import { OrdersNotificationMethods, PER_SMS_PRICE } from '../../../constants';
import { CheckCircle } from '../../../theme/overrides/CustomIcons';
import ChPhoneInput from '../ChPhoneInput';
import ChInput from '../ChInput';
import isEmail from 'validator/es/lib/isEmail';
import { TABLET_INSTRUCTIONS } from '../../../constants/tablet-instructions';
import { OrderNotificationMethod } from '../../../types/common';
import { BRAND_NAME } from '../../../constants/constants';

const VOICE_RECIPIENT_MESSAGE = `${BRAND_NAME} will send you a call if the order is not accepted in 3 minutes via email`;

const validate = (values: FormikValues) => {
  const { sms, email, phoneNumber, emailAddress, voiceRecipient } = values;
  const errors = {} as { [key: string]: string };

  if (email && !emailAddress) {
    errors.emailAddress = 'Please enter an email address';
  }

  if (email && emailAddress && !isEmail(emailAddress)) {
    errors.emailAddress = 'Please enter a valid email address';
  }

  if (email && !voiceRecipient) {
    errors.voiceRecipient = 'Please enter phone number';
  }

  if (email && voiceRecipient && !isPossiblePhoneNumber(voiceRecipient)) {
    errors.voiceRecipient = 'Please enter a valid phone number';
  }

  if (sms && !phoneNumber) {
    errors.phoneNumber = 'Please enter phone number';
  }

  if (sms && phoneNumber && !isPossiblePhoneNumber(phoneNumber)) {
    errors.phoneNumber = 'Please enter a valid phone number';
  }

  return errors;
};

interface ReceivingMethodsProps {
  receivingMethods?: OrderNotificationMethod[];
  onSubmit: (orderNotificationMethods: OrderNotificationMethod[]) => void;
}

const ReceivingMethods = ({
  receivingMethods = [],
  onSubmit,
}: ReceivingMethodsProps) => {
  const data = React.useMemo(
    () =>
      receivingMethods.reduce(
        (allMethods: any, currentMethod: OrderNotificationMethod) => {
          if (currentMethod.method === OrdersNotificationMethods.EMAIL) {
            return {
              ...allMethods,
              email: true,
              emailAddress: currentMethod.recipient,
              voiceRecipient: currentMethod.voiceRecipient,
            };
          }

          if (currentMethod.method === OrdersNotificationMethods.SMS) {
            return {
              ...allMethods,
              sms: true,
              phoneNumber: currentMethod.recipient,
            };
          }

          if (currentMethod.method === OrdersNotificationMethods.TABLET) {
            return {
              ...allMethods,
              tablet: true,
            };
          }

          return allMethods;
        },
        {}
      ),
    [receivingMethods]
  );

  const { handleSubmit, values, setFieldValue, errors, touched, handleChange } =
    useFormik({
      initialValues: {
        sms: data?.sms ?? false,
        tablet: data?.tablet ?? false,
        email: data?.email ?? false,
        phoneNumber: data?.phoneNumber ?? '',
        emailAddress: data?.emailAddress ?? '',
        voiceRecipient: data?.voiceRecipient ?? '',
      },
      validate,
      onSubmit: (values) => {
        const orderNotificationMethods = [];

        if (values.sms) {
          orderNotificationMethods.push({
            method: OrdersNotificationMethods.SMS,
            recipient: values.phoneNumber,
          });
        }

        if (values.tablet) {
          orderNotificationMethods.push({
            method: OrdersNotificationMethods.TABLET,
          });
        }

        if (values.email) {
          orderNotificationMethods.push({
            method: OrdersNotificationMethods.EMAIL,
            recipient: values.emailAddress,
            voiceRecipient: values.voiceRecipient,
          });
        }

        onSubmit(orderNotificationMethods);
      },
    });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.checked);
  };

  return (
    <form onSubmit={handleSubmit} id="on-boarding">
      <ReceivingMethod
        active={values.sms}
        onChange={onChange}
        title="SMS"
        name="sms"
      >
        <HStack justify="space-between">
          <HStack>
            <CheckCircle
              sx={{
                fill: 'rgb(157, 217, 173)',
                color: 'rgb(157, 217, 173)',
              }}
            />
            <Text fontWeight="medium" fontSize="lg" color="rgba(92,92,92,0.8)">
              Activate SMS notifications
            </Text>
          </HStack>
          <Text color="#666666" fontWeight="medium" fontSize="md">
            ${PER_SMS_PRICE}{' '}
            <Text
              as="span"
              fontSize="sm"
              style={{ color: 'rgba(92,92,92,0.8)' }}
            >
              per SMS
            </Text>{' '}
          </Text>
        </HStack>

        <Box my={5}>
          <ChPhoneInput
            id="phoneNumber"
            name="phoneNumber"
            isInvalid={!!(errors.phoneNumber && touched.phoneNumber)}
            error={errors.phoneNumber as string}
            value={values.phoneNumber}
            handleChange={(num) => setFieldValue('phoneNumber', num)}
            placeholder="Enter phone number"
            label="Phone"
          />
        </Box>
      </ReceivingMethod>

      <ReceivingMethod
        active={values.tablet}
        onChange={onChange}
        title="Tablet"
        name="tablet"
      >
        <Accordion allowToggle>
          {TABLET_INSTRUCTIONS.map((instruction, index) => {
            const { title, img, subTitle, instructions } = instruction;
            const instructionNumber = index + 1;
            return (
              <AccordionItem
                key={title}
                border="1px solid rgba(220,217,214)"
                borderRadius="15px"
                mb={
                  instructionNumber === TABLET_INSTRUCTIONS.length
                    ? '0'
                    : '15px'
                }
                overflow="hidden"
              >
                <AccordionButton _hover={{ bg: '#fff' }}>
                  <Box flex="1" textAlign="left">
                    <HStack spacing={2.5}>
                      <Box>
                        <Box
                          sx={{
                            border: '1px solid rgb(36,28,21)',
                            borderRadius: '50%',
                            w: '24px',
                            height: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Text as="span" fontSize="sm" color="#241c15">
                            {instructionNumber}
                          </Text>
                        </Box>
                      </Box>
                      <Text
                        fontSize="lg"
                        color="rgba(92,92,92,0.8)"
                        fontWeight="medium"
                        letterSpacing="-0.5px"
                      >
                        {title}
                      </Text>
                    </HStack>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel py={4}>
                  <Stack
                    spacing={5}
                    direction={{ base: 'column', md: 'row' }}
                    align={{ base: 'center', md: 'center' }}
                  >
                    <Image
                      w="180px"
                      h="360px"
                      objectFit="contain"
                      src={img}
                      alt={title}
                    />
                    <Box>
                      <Text color="#241c15" fontWeight="medium">
                        {subTitle}
                      </Text>
                      <Stack mt={2.5}>
                        {instructions.map((ins) => {
                          return (
                            <Text
                              color="#666666"
                              fontWeight="medium"
                              fontSize="sm"
                              key={ins}
                            >
                              {ins}
                            </Text>
                          );
                        })}
                      </Stack>
                    </Box>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </ReceivingMethod>

      <ReceivingMethod
        active={values.email}
        onChange={onChange}
        title="Email"
        name="email"
      >
        <HStack>
          <CheckCircle
            sx={{
              fill: 'rgb(157, 217, 173)',
              color: 'rgb(157, 217, 173)',
            }}
          />
          <Text fontWeight="medium" fontSize="lg" color="rgba(92,92,92,0.8)">
            Activate Email notifications
          </Text>
        </HStack>

        <Box my={5}>
          <ChInput
            id="emailAddress"
            name="emailAddress"
            label="Email"
            placeholder="Enter email address"
            isInvalid={!!(errors.emailAddress && touched.emailAddress)}
            error={errors.emailAddress as string}
            value={values.emailAddress}
            handleChange={handleChange}
            type="email"
          />
        </Box>

        <Box my={5}>
          <ChPhoneInput
            id="voiceRecipient"
            name="voiceRecipient"
            isInvalid={!!(errors.voiceRecipient && touched.voiceRecipient)}
            error={errors.voiceRecipient as string}
            value={values.voiceRecipient}
            handleChange={(num) => setFieldValue('voiceRecipient', num)}
            placeholder="Enter phone number"
            label="Phone"
          />

          <Text fontSize="sm" color="#e53e3e" sx={{ mt: 2 }}>
            {VOICE_RECIPIENT_MESSAGE}
          </Text>
        </Box>
      </ReceivingMethod>

      {errors.sms && (
        <Text fontSize="sm" color="#e53e3e">
          {errors.sms as string}
        </Text>
      )}
    </form>
  );
};

export default ReceivingMethods;
