import moment from 'moment';

export const MIN_PASSWORD_LENGTH = 8;
export const BRAND_NAME = 'Crave';

// EXTERNAL LINKS
export const LANDING_PAGE = 'https://www.craveup.com';
export const ORDERING_APP = 'https://order.craveup.com';
export const CRAVE_PRICING_PAGE = 'https://www.craveup.com/pricing';
export const PRIVACY_PAGE = `${LANDING_PAGE}/privacy-policy`;
export const TERMS_PAGE = `${LANDING_PAGE}/terms-of-use`;
export const SETUP_GUIDE_PAGE = 'https://craveup.mintlify.app/';
export const ORDERING_APP_URL = process.env.REACT_APP_ORDERING_APP_URL;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const RANGE_CALENDAR_DEFAULT_START_DATE = moment()
  .subtract(1, 'week')
  .toDate();
export const RANGE_CALENDAR_DEFAULT_END_DATE = moment().toDate();

export const DATE_FORMAT1 = 'YYYY-MM-DD';

export const CLOCK_FORMATS = {
  hour12: 'hh:mm A',
  hour24: 'HH:mm',
};

export const monthNames = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const DAY_NAMES = {
  0: { shortName: 'SUN', fullName: 'Sunday' },
  1: { shortName: 'MON', fullName: 'Monday' },
  2: { shortName: 'TUE', fullName: 'Tuesday' },
  3: { shortName: 'WED', fullName: 'Wednesday' },
  4: { shortName: 'THU', fullName: 'Thursday' },
  5: { shortName: 'FRI', fullName: 'Friday' },
  6: { shortName: 'SAT', fullName: 'Saturday' },
};

export const itemAvailability = {
  AVAILABLE: 'available',
  SOLD_OUT_TODAY: 'sold-out-today',
  SOLD_OUT_INDEFINITELY: 'sold-out-indefinitely',
};

export const defaultSchedule = [
  {
    day: 0,
    active: false,
    intervals: [],
  },
  {
    day: 1,
    active: false,
    intervals: [],
  },
  {
    day: 2,
    active: false,
    intervals: [],
  },
  {
    day: 3,
    active: false,
    intervals: [],
  },
  {
    day: 4,
    active: false,
    intervals: [],
  },
  {
    day: 5,
    active: false,
    intervals: [],
  },
  {
    day: 6,
    active: false,
    intervals: [],
  },
];

export const allDayMenuSchedule = [
  {
    day: 0,
    active: true,
    intervals: [{ from: '12:00 AM', to: '11:59 PM' }],
  },
  {
    day: 1,
    active: true,
    intervals: [{ from: '12:00 AM', to: '11:59 PM' }],
  },
  {
    day: 2,
    active: true,
    intervals: [{ from: '12:00 AM', to: '11:59 PM' }],
  },
  {
    day: 3,
    active: true,
    intervals: [{ from: '12:00 AM', to: '11:59 PM' }],
  },
  {
    day: 4,
    active: true,
    intervals: [{ from: '12:00 AM', to: '11:59 PM' }],
  },
  {
    day: 5,
    active: true,
    intervals: [{ from: '12:00 AM', to: '11:59 PM' }],
  },
  {
    day: 6,
    active: true,
    intervals: [{ from: '12:00 AM', to: '11:59 PM' }],
  },
];

export const newModifierOption = {
  name: '',
  price: '0',
  quantity: 1,
  maxQuantity: '1',
};

export const MAX_SCHEDULE_INTERVALS_ALLOWED = 3;
export const MAX_CHARACTERS_LIMIT_FOR_DESCRIPTION = 250;

export const DEFAULT_WAITER_TIP_RATE = '0.18';

export const pickupTypes = {
  ASAP: 'ASAP',
  LATER: 'LATER',
};

export const detailsTableContentTypes = {
  CHIP: 'chip',
  DEFAULT: 'default',
};

export const discountStatus = {
  ALL: 'All',
  ACTIVE: 'Active',
  SCHEDULED: 'Scheduled',
  EXPIRED: 'Expired',
  DISABLED: 'Disabled',
};

export const discountScopes = {
  ORDER: 'order',
  PRODUCT: 'product',
  CATEGORY: 'category',
};

export const discountMinRequirements = {
  NONE: 'none',
  MINIMUM_PURCHASE_AMOUNT: 'minimumPurchaseAmount',
  MINIMUM_QUANTITY_OF_ITEMS: 'minimumQuantityOfItems',
};

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const CLIENT_API_URL = process.env.REACT_APP_ORDERING_APP_URL;
export const SERVER_API_URL = process.env.REACT_APP_CRAVE_API_URL;

export const MODULES = {
  DELIVERY_MODULE: 'delivery',
  INTEGRATIONS_MODULE: 'integrations',
  ORDERS_ALLOWED: 'orders_allowed',
  PMS_ORDERS_ALLOWED: 'pms_orders_allowed',
};

export const fontFamilies = [
  'Poppins',
  'Roboto',
  'Noto Sans',
  'Montserrat',
  'Raleway',
  'Open Sans',
];

export const defaultColors = [
  '#000000',
  '#ffffff',
  '#0f77ff',
  '#00a47c',
  '#d82c0d',
  '#ffe85f',
  '#f784ad',
];

export const QR_TEMPLATE_106 = 'QR_TEMPLATE_106';
export const QR_TEMPLATE_107 = 'QR_TEMPLATE_107';
export const QR_TEMPLATE_109 = 'QR_TEMPLATE_109';
export const QR_TEMPLATE_110 = 'QR_TEMPLATE_110';
export const QR_TEMPLATE_111 = 'QR_TEMPLATE_111';
export const QR_TEMPLATE_112 = 'QR_TEMPLATE_112';
export const QR_TEMPLATE_115 = 'QR_TEMPLATE_115';

export const MENU_UPLOAD_PRICE = '20'; // dollars
export const FONT_PRIMARY = "'Lexend', sans-serif"; // Google Font
export const FONT_SECONDARY = "'Inter', sans-serif"; // Google Font
export const FONT_TERTIARY = "'Open Sans', sans-serif"; // Google Font
export const NAVBAR_HEIGHT = '5rem';

export const customDeliveryFeeLocations = ['661ff982dd67f4346fcf615b']; // 661ff982dd67f4346fcf615b id for VUE restaurant & bar
export const customDeliveryFee = '4.00'; // 661ff982dd67f4346fcf615b id for VUE restaurant & bar
