import { Flex, Stack, useToast } from '@chakra-ui/react';
import { FormikValues, useFormik } from 'formik';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import PlanDetailsSideBar from './PlanDetailsSideBar';
import StartFreeTrial from './StartFreeTrial';
import { plansData } from '../../constants/plansData';
import { useReduxDispatch, useReduxSelector } from '../../store/store';
import { Plans, StripePlans } from '../../constants';
import crave from '../../apis/crave';
import { HOME_PAGE } from '../../routes';
import { formatApiError } from '../../utils';
import LayoutC from '../common/LayoutC';
import {
  completeOnboardingTemporarily,
  handleLogin,
  logout,
} from '../../store/slices/auth';

const defaultObject = {
  name: '',
  description: '',
  monthlyPrice: 0,
  yearlyPrice: 0,
  id: Plans.STARTER,
  planDetails: {
    title: '',
    features: [],
  },
};

const getPlanPriceId = (values: FormikValues, selectedPlan: Plans) => {
  const { isMonthlyPlan } = values;

  if (selectedPlan === Plans.STARTER && isMonthlyPlan) {
    return StripePlans.STARTER_MONTHLY;
  }
  if (selectedPlan === Plans.STARTER) {
    return StripePlans.STARTER_YEARLY;
  }
  if (selectedPlan === Plans.GROWTH && isMonthlyPlan) {
    return StripePlans.GROWTH_MONTHLY;
  }
  if (selectedPlan === Plans.GROWTH && !isMonthlyPlan) {
    return StripePlans.GROWTH_YEARLY;
  }
  if (selectedPlan === Plans.ENTERPRISE && isMonthlyPlan) {
    return StripePlans.ENTERPRISE_MONTHLY;
  }
  if (selectedPlan === Plans.ENTERPRISE && !isMonthlyPlan) {
    return StripePlans.ENTERPRISE_YEARLY;
  }

  return '';
};

export default function BuildPlan() {
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const { user } = useReduxSelector((state) => state.auth);
  const { onBoarding } = useReduxSelector((state) => state.onBoarding);
  const { selectedPlan, merchantId } = onBoarding;

  const { handleSubmit, values, isSubmitting } = useFormik({
    initialValues: {
      isMonthlyPlan: true, // TODO -- need design for monthly and yearly switch
      menu: false,
      screen: 0,
      acceptTerms: false,
    },
    onSubmit: async (newValues, { setSubmitting }) => {
      if (!stripe || !elements) {
        return;
      }

      try {
        const card = elements.getElement(CardElement);

        if (!card) {
          return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card,
        });

        if (error) {
          throw new Error(error.message);
        }

        const data = {
          priceId: getPlanPriceId(newValues, selectedPlan),
          paymentId: paymentMethod?.id,
          merchantId,
          menu: false, // auto menu upload
        };
        await crave.post(`/stripe/subs`, data, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        await handleLogin({ token: user.token });
        dispatch(completeOnboardingTemporarily(true));
        navigate(HOME_PAGE);
        setSubmitting(false);
      } catch (error) {
        const { e } = formatApiError(error);
        toast({
          title: e,
          status: 'error',
          isClosable: true,
        });
        setSubmitting(false);
      }
    },
  });

  const planDetails =
    plansData.find((plan) => plan.id === selectedPlan) || defaultObject;

  return (
    <LayoutC
      sx={{ height: '100vh', overflow: 'hidden' }}
      activeStep={values.screen + 1}
      animate={false}
      onClick={() => dispatch(logout(navigate))}
      buttonText="Logout"
      buttonVariant="outline"
    >
      <Stack direction="row" h="full" spacing={0}>
        <Flex py={8} px={4} flex={1} justify="center" overflow="auto">
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <StartFreeTrial
              isSubmitting={isSubmitting}
              isMonthlyPlan={values.isMonthlyPlan}
            />
          </form>
        </Flex>
        <PlanDetailsSideBar values={values} planDetails={planDetails} />
      </Stack>
    </LayoutC>
  );
}
