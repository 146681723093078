const PRIMARY_COLORS = {
  50: '#e8ebff',
  100: '#bec5f8',
  200: '#939eed',
  300: '#6877e4',
  400: '#3e50db',
  500: '#2437c1',
  600: '#1b2b97',
  700: '#111e6d',
  800: '#081244',
  900: '#02051c',
};

const colors = {
  primary: PRIMARY_COLORS,
};

export default colors;
