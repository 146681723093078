import { Icon } from '@chakra-ui/react';

export function LockOpen(props: any) {
  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <rect width="256" height="256" fill="none"></rect>
      <rect
        x="40"
        y="88"
        width="176"
        height="128"
        rx="8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></rect>
      <path
        d="M92,88V52a36,36,0,0,1,72,0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></path>
      <circle cx="128" cy="152" r="12"></circle>
    </Icon>
  );
}
