import { User } from '../types/user';
import { isStarterPlan } from './is-starter-plan';
import { getScheduleStatus } from './get-schedule-status';

const getStepsStatus = (user: User) => {
  const {
    menuStatus,
    schedule,
    requiresStripePerson,
    locationHasProducts,
    subscription,
    customDomainStatus,
    onboardingInProgress,
  } = user;

  const menuUploaded = Boolean(menuStatus) || locationHasProducts;
  const isScheduleSet = getScheduleStatus(schedule);
  const isPersonSet = !requiresStripePerson;
  const isDomainSet = !!customDomainStatus;
  const isOnboardingCompleted = !onboardingInProgress;

  return {
    menuUploaded,
    isScheduleSet,
    isPersonSet,
    isOnboardingCompleted,
    isAllStepsCompleted: isStarterPlan(subscription)
      ? menuUploaded && isScheduleSet && isDomainSet
      : menuUploaded &&
        isScheduleSet &&
        isPersonSet &&
        isDomainSet &&
        isOnboardingCompleted,
  };
};

export default getStepsStatus;
