import { Stack, Text, HStack, Switch, Collapse } from '@chakra-ui/react';
import { ChatIcon } from '../../../theme/overrides/CustomIcons';
import React, { ChangeEvent } from 'react';

interface ReceivingMethodProps {
  title: string;
  active: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  name: string;
}

const ReceivingMethod = ({
  title,
  active,
  children,
  onChange,
  name,
}: ReceivingMethodProps) => {
  return (
    <Stack
      p="15px"
      border="2px solid"
      borderColor={active ? 'rgb(69,86,220)' : 'transparent'}
      borderRadius="15px"
      spacing="15px"
      mb={active ? '15px' : '0px'}
    >
      <HStack
        justify="space-between"
        border="1px solid"
        borderColor={active ? 'rgb(69,86,220)' : 'rgb(220, 217, 214)'}
        p="15.1px"
        borderRadius="15px"
      >
        <HStack>
          <ChatIcon sx={{ color: 'rgb(69,86,220)', w: '22px', h: '22px' }} />
          <Text
            color={active ? '#4556dc' : 'rgba(92,92,92,0.8)'}
            fontSize="lg"
            fontWeight="medium"
          >
            {title}
          </Text>
        </HStack>

        <Switch
          value="sms"
          size="lg"
          isChecked={active}
          onChange={onChange}
          name={name}
          colorScheme="green"
        />
      </HStack>

      <Collapse in={active} animateOpacity>
        <Stack
          border="1px solid rgb(220, 217, 214)"
          p="15px"
          borderRadius="15px"
        >
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default ReceivingMethod;
