import { useReduxSelector } from '../store/store';

const useUser = () => {
  const {
    user: { backgroundUrl, locationDisplayName, description },
  } = useReduxSelector((state) => state.auth);

  return {
    currentLocation: { backgroundUrl, locationDisplayName, description },
  };
};

export default useUser;
