import useSWR from 'swr';
import { useReduxSelector } from '../store/store';
import { apiFetcher } from '../apis/crave';
import { formatApiError } from '../utils';
import { SWR_CONFIG } from '../constants';

function useWhiteLabelMobileApp(shouldRequest = true) {
  const { user } = useReduxSelector((state) => state.auth);
  const { merchantId, locationId, token } = user;

  const { data, error, mutate } = useSWR(
    shouldRequest && [
      `merchants/${merchantId}/locations/${locationId}/mobile-app`,
      token,
    ],
    apiFetcher,
    { ...SWR_CONFIG, refreshInterval: 15000 }
  );

  return {
    whiteLabelMobileApp: data?.appData,
    loading: !error && !data,
    error: error ? formatApiError(error)?.e : undefined,
    mutate,
  };
}

export default useWhiteLabelMobileApp;
