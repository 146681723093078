import { FONT_PRIMARY } from '../constants/constants';

export const fonts = {
  body: FONT_PRIMARY,
  heading: FONT_PRIMARY,
  mono: FONT_PRIMARY,
};

export const fontSizes = {
  xs: '1.2rem',
  sm: '1.4rem',
  md: '1.6rem',
  lg: '1.8rem',
  xl: '2rem',
  '2xl': '2.4rem',
  '3xl': '3rem',
  '4xl': '3.6rem',
  '5xl': '4.8rem',
  '6xl': '6rem',
  '7xl': '7.2rem',
  '8xl': '9.6rem',
  '9xl': '12.8rem',
};

export const lineHeights = {
  normal: 'normal',
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: '2',
  '3': '1.2rem',
  '4': '1.6rem',
  '5': '2rem',
  '6': '2.4rem',
  '7': '2.8rem',
  '8': '3.2rem',
  '9': '3.6rem',
  '10': '4rem',
};
