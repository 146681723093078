import ins1Img from '../assets/images/onboarding/ins-1.png';
import ins2Img from '../assets/images/onboarding/ins-2.png';
import ins3Img from '../assets/images/onboarding/ins-3.png';
import ins4Img from '../assets/images/onboarding/ins-4.png';
import ins5Img from '../assets/images/onboarding/ins-5.png';
import ins6Img from '../assets/images/onboarding/ins-6.png';
import ins7Img from '../assets/images/onboarding/ins-7.png';

export const TABLET_INSTRUCTIONS = [
  {
    title: 'Begin by factory resetting your device.',
    img: ins1Img,
    subTitle: 'Begin by factory resetting your device.',
    instructions: [
      '1. Open Settings, and select System.',
      '2. Select Reset options.',
      '3. Select Erase all data (factory reset).',
      '4. Select Reset Phone or Reset Tablet at the bottom.',
      "5. You'll be asked to confirm, select Erase Everything.",
      '6. Your device should reboot and may show a progress screen indicating that it is erasing the data.',
    ],
  },
  {
    title: 'Tap anywhere on the screen six times',
    img: ins2Img,
    subTitle:
      'Once the factory reset is completed, on the first setup screen, tap anywhere on the screen six times.',
    instructions: [
      'Note: This step will not be applicable on android 9 and above.',
    ],
  },
  {
    title: 'Click Next to go to the QR Code Reader Setup section.',
    img: ins3Img,
    subTitle:
      'If your device first needs to download the QR code reader, you’ll see the QR code setup screen.',
    instructions: [
      'Click next on this screen to go to the QR code setup section.',
    ],
  },
  {
    title: 'Once the camera opens on the device, scan the QR Code',
    img: ins4Img,
    subTitle: 'Once the camera opens on the device, scan the QR Code',
    instructions: [
      'Hold up the device’s camera to your computer screen, centering it on the QR code.',
    ],
  },
  {
    title: 'Connect the device to either the cellular network or Wi-Fi.',
    img: ins5Img,
    subTitle: 'Connect the device to either the cellular network or Wi-Fi.',
    instructions: [
      'If your device does not have an active cellular data network connection or isn’t set up to connect to a Wi-Fi access point specified in the QR code to use for provisioning, you will need to connect the device to the internet either using the cellular data or via Wi-Fi. To do so, you will need to either enable your SIM or enter your Wi-Fi access point credentials.',
    ],
  },
  {
    title: 'Click Accept & Continue to complete device enrollment',
    img: ins6Img,
    subTitle: 'Click Accept & Continue to complete device enrollment',
    instructions: [
      'To complete the installation of the Esper Device Management application, click Accept & Continue',
    ],
  },
  {
    title: 'Give permission to Device Manage to modify system settings',
    img: ins7Img,
    subTitle: 'Give permission to the Esper Device Manage application',
    instructions: [
      'Give permission to the Esper Device Manage application to modify system settings. Continue provisioning by clicking Resolve',
    ],
  },
];
