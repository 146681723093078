import { useReduxSelector } from '../store/store';
import { MODULES } from '../constants/constants';

const useAuth = () => {
  const {
    user: {
      merchantId,
      locationId,
      token,
      email,
      stripeId,
      stripePayoutBank,
      country,
      locationDisplayName,
      locationName,
      firstName,
      lastName,
      locationModules = [],
      address,
      timezone,
      subscription,
    },
  } = useReduxSelector((state) => state.auth);

  const user = {
    firstName,
    lastName,
    fullName: (firstName && lastName
      ? `${firstName} ${lastName}`
      : `${firstName}`
    ).trim(),
  };

  const merchant = {
    stripeId,
    stripePayoutBank,
    country,
    isMerchantHaveStripeSubscription: Boolean(subscription?.customerId),
    subscription: subscription ?? {},
  };

  const location = {
    locationName,
    locationDisplayName,
    address,
    timezone,
    isDeliveryAllowed: (locationModules as string[]).includes(
      MODULES.DELIVERY_MODULE
    ),
  };

  return { merchantId, locationId, token, email, user, merchant, location };
};

export default useAuth;
