interface LogoProps {
  variant?: string;
  color?: string;
}

const Logo = ({ variant = '', color = 'white' }: LogoProps) => {
  if (variant === 'smile') {
    return (
      <svg
        width="167"
        height="173"
        viewBox="0 0 167 173"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3181 147.013C59.0755 180.621 113.672 180.621 147.43 147.013L25.3181 25.4414C-8.43935 59.0494 -8.43935 113.405 25.3181 147.013Z"
          fill="#3A1960"
        />
        <path
          d="M81.543 45.6641C94.1528 45.6641 104.375 35.4418 104.375 22.832C104.375 10.2222 94.1528 0 81.543 0C68.9332 0 58.7109 10.2222 58.7109 22.832C58.7109 35.4418 68.9332 45.6641 81.543 45.6641Z"
          fill="#3A1960"
        />
        <path d="M167 61.9727H120.684V108.289H167V61.9727Z" fill="#3A1960" />
      </svg>
    );
  }

  return (
    <svg
      width="84"
      height="25"
      viewBox="0 0 84 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.42249 14.1389C5.65248 17.3757 10.8764 17.3757 14.1064 14.1389L2.42249 2.43018C-0.807496 5.66702 -0.807496 10.9021 2.42249 14.1389Z"
        fill={color}
      />
      <path
        d="M7.80932 4.41706C9.02648 4.41706 10.0132 3.42827 10.0132 2.20853C10.0132 0.988793 9.02648 0 7.80932 0C6.59217 0 5.60547 0.988793 5.60547 2.20853C5.60547 3.42827 6.59217 4.41706 7.80932 4.41706Z"
        fill={color}
      />
      <path
        d="M15.9722 5.97119H11.5645V10.3883H15.9722V5.97119Z"
        fill={color}
      />
      <path
        d="M23.2488 21.0569C21.1849 21.0569 19.9022 19.608 19.9022 17.4228C19.9022 15.1909 21.2665 13.7302 23.2255 13.7302C24.9279 13.7302 25.7675 14.5949 25.9657 16.1608H29.9886C29.4173 12.106 26.9336 10.3999 23.1322 10.3999C18.7478 10.3999 15.9609 13.2511 15.9609 17.4228C15.9609 21.8165 18.7012 24.3873 23.2138 24.3873C27.7381 24.3873 30.0236 21.8165 30.1636 18.5446H26.1173C26.0124 20.1338 24.9746 21.0569 23.2488 21.0569Z"
        fill={color}
      />
      <path
        d="M35.609 15.7401H35.4924V10.7621H31.6094V24.025H35.4924V17.6799C35.4924 15.3311 36.6118 14.4664 39.457 14.4664H40.1566V10.3999C37.1482 10.3999 35.7256 11.9657 35.609 15.7401Z"
        fill={color}
      />
      <path
        d="M51.1641 15.5181L51.0475 15.4597L50.9892 14.9572C50.9076 11.9073 49.6716 10.3999 46.7215 10.3999C43.2349 10.3999 40.2148 13.1109 40.2148 17.3877C40.2148 21.6646 42.7802 24.3756 46.4066 24.3756C49.0536 24.3756 50.8726 23.1486 50.9892 19.7599L51.0475 19.4561L51.1641 19.3976V24.0133H55.0355V10.7621H51.1525V15.5181H51.1641ZM47.8992 21.0569C45.6953 21.0569 44.1561 19.7131 44.1561 17.3877C44.1561 15.0974 45.7769 13.7185 47.9808 13.7185C50.1264 13.7185 51.2691 15.2026 51.2691 17.4111C51.2691 19.5378 50.068 21.0569 47.8992 21.0569Z"
        fill={color}
      />
      <path
        d="M66.311 10.7627L64.1071 16.991L62.9644 20.777L61.74 16.991L59.4779 10.7627H55.6299L60.4807 24.0139H65.3665L70.2289 10.7627H66.311Z"
        fill={color}
      />
      <path
        d="M77.039 21.0569C74.9984 21.0569 73.7158 19.7365 73.7158 17.8435V17.563H83.9887C83.9887 13.0291 81.7499 10.3999 76.8758 10.3999C72.5497 10.3999 69.7861 13.2511 69.7861 17.5864C69.7861 21.8048 72.5264 24.3873 76.9341 24.3873C81.2368 24.3873 83.9421 21.7814 84.0004 18.9886H80.1524C79.9658 20.0754 78.8231 21.0569 77.039 21.0569ZM77.1207 13.7185C78.8814 13.7185 79.9425 14.6417 80.269 16.0907H73.844C74.2755 14.5015 75.5232 13.7185 77.1207 13.7185Z"
        fill={color}
      />
    </svg>
  );
};

export default Logo;
