import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Info } from '../../assets/icons';
import { ChromePicker, ColorChangeHandler } from 'react-color';

interface ColorPickerProps {
  label: string;
  color: string;
  onChange: ColorChangeHandler;
  isInvalid?: boolean;
  labelInfoText?: string;
}
const ColorPicker = ({
  label,
  color,
  onChange,
  isInvalid,
  labelInfoText,
}: ColorPickerProps) => {
  return (
    <Box>
      <Text mr={3} mb={2} fontWeight="medium">
        {label}{' '}
        {labelInfoText && (
          <Tooltip shouldWrapChildren label={labelInfoText}>
            <Info width={22} height={22} />
          </Tooltip>
        )}
      </Text>
      <Popover>
        <PopoverTrigger>
          <Button
            size="md"
            borderColor={isInvalid ? '#e53e3e' : undefined}
            boxShadow={`0 0 0 1px ${isInvalid ? '#e53e3e' : 'transparent'}`}
            variant="outline"
            width="170px"
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Text
              color={isInvalid ? '#e53e3e' : '#1a202c'}
              textTransform="uppercase"
            >
              {color}
            </Text>
            <Box
              backgroundColor={color}
              width={6}
              height={6}
              borderRadius={2}
              border="1px solid #e2e8f0"
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Select color</PopoverHeader>
          <PopoverBody
            sx={{
              '& > div': {
                boxShadow: 'none !important',
                width: 'full !important',
              },
            }}
          >
            <ChromePicker color={color} onChange={onChange} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default ColorPicker;
