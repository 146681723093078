import { IconNames } from '../constants';
import {
  ArrowBendUpLeft,
  ArrowsClockwise,
  Copy,
  LockOpen,
  PencilSimple,
  Trash,
} from '../assets/icons';

const sxProps = { w: 5, h: 5 };
export const getIcon = (iconName?: IconNames) => {
  switch (iconName) {
    case IconNames.ARROW_BEND_UP_LEFT:
      return <ArrowBendUpLeft sx={sxProps} />;
    case IconNames.TRASH:
      return <Trash sx={sxProps} />;
    case IconNames.LOCK_OPEN:
      return <LockOpen sx={sxProps} />;
    case IconNames.ARROWS_CLOCKWISE:
      return <ArrowsClockwise sx={sxProps} />;
    case IconNames.PENCIL_SIMPLE:
      return <PencilSimple sx={sxProps} />;
    case IconNames.COPY:
      return <Copy sx={sxProps} />;
    default:
      return <div />;
  }
};
