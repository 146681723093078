// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTabs: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.grey[100]}`,
          minHeight: 'auto',
        },
        indicator: {
          height: '0.4rem',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: '1rem 0',
          marginRight: 0,
          minWidth: 'auto',
          minHeight: 'auto',
          fontWeight: theme.typography.fontWeightSemiBold,
          color: theme.palette.grey[400],
          letterSpacing: theme.letterSpacing,
          fontSize: '1.6rem',
          textTransform: 'uppercase',

          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },

          '&:first-of-type': {
            marginLeft: '4.2rem',
          },

          '&:not(:last-of-type)': {
            marginRight: '4rem',
          },
        },
      },
    },
  };
}
