import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Text,
} from '@chakra-ui/react';
import { LockIcon } from '../../theme/overrides/CustomIcons';
import { CardElement } from '@stripe/react-stripe-js';

const wrapperStyle = {
  '& .StripeElement': {
    border: '1px solid #c3c3c3',
    py: 1.5,
    px: 4,
    borderRadius: 'md',
    height: '53px',
  },
  '& .StripeElement--focus': { borderColor: '#515151' },
  '& .StripeElement--invalid': { borderColor: '#C53030' },
};

export const CARD_ELEMENT_OPTIONS = {
  base: {
    fontFamily: '"Poppins", sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    lineHeight: '40px',
    '::placeholder': {
      color: '#DCDFE4',
    },
  },
  invalid: {
    color: '#DB2A2A',
    iconColor: '#DB2A2A',
  },
};

interface StripeElementInputProps {
  loading: boolean;
  cardBaseOptions?: { [key: string]: any };
  hidePostalCode?: boolean;
}

const StripeElementInput = ({
  loading,
  cardBaseOptions,
  hidePostalCode,
}: StripeElementInputProps) => {
  const { base, invalid } = CARD_ELEMENT_OPTIONS;

  const cardElementOptions = React.useMemo(
    () => ({
      hidePostalCode,
      disabled: loading,
      style: {
        base: { ...base, ...cardBaseOptions },
        invalid,
      },
    }),
    [loading]
  );

  return (
    <FormControl sx={wrapperStyle}>
      <FormLabel
        htmlFor="card-number"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <span>Card number</span>
        <HStack spacing={1} ml={4}>
          <LockIcon sx={{ width: 4, height: 4 }} />
          <Text fontSize="xs" color="#6c7177">
            Secured by Stripe
          </Text>
        </HStack>
      </FormLabel>
      <CardElement options={cardElementOptions} />
      <FormErrorMessage>Some error</FormErrorMessage>
    </FormControl>
  );
};

export default StripeElementInput;
