// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(0.6),
            margin: theme.spacing(0.3, 0),
          },
        },
      },
    },
  };
}
