import React from 'react';
import { Box, HStack, IconButton, Link, VStack } from '@chakra-ui/react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { BackArrowIcon } from '../../../theme/overrides/CustomIcons';
import {
  HOME_PAGE,
  SETTINGS_PAGE,
  SETTINGS_PAGE_BANK_ACCOUNT,
  SETTINGS_PAGE_UPGRADE_PLAN,
} from '../../../routes';
import { UserCircle, ArrowCircleUp, Bank } from '../../../assets/icons';
import { isGrowthPlan, isStarterPlan } from '../../../utils';
import { useReduxSelector } from '../../../store/store';

interface LinkItemProps {
  name: string;
  path: string;
  icon: React.ReactElement;
  id: string;
}

const iconStyle = { width: 6, height: 6 };

const LinkItems: Array<LinkItemProps> = [
  {
    name: 'Account',
    path: SETTINGS_PAGE,
    icon: <UserCircle sx={iconStyle} />,
    id: 'account',
  },
  {
    name: 'Bank Account',
    path: SETTINGS_PAGE_BANK_ACCOUNT,
    icon: <Bank sx={iconStyle} />,
    id: 'bank',
  },
  {
    name: 'Upgrade Plan',
    path: SETTINGS_PAGE_UPGRADE_PLAN,
    icon: <ArrowCircleUp sx={iconStyle} />,
    id: 'plan',
  },
];

const SidebarContent = () => {
  const { user } = useReduxSelector((state) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const updatedLinkItems = React.useMemo(() => {
    let updatedLinks = [...LinkItems];
    if (isGrowthPlan(user.subscription)) {
      updatedLinks = updatedLinks.filter((link) => link.id !== 'plan');
    }

    if (isStarterPlan(user.subscription)) {
      const excludePagesForStarter = ['notification', 'bank'];
      updatedLinks = updatedLinks.filter(
        (link) => !excludePagesForStarter.includes(link.id)
      );
    }
    return updatedLinks;
  }, []);

  return (
    <Box
      bg="rgb(249, 250, 251)"
      w={{ base: 'full', md: 64 }}
      pos="fixed"
      h="full"
      pt={12}
    >
      <Box p={4}>
        <IconButton
          aria-label="back"
          variant="ghost"
          icon={<BackArrowIcon sx={{ width: 6, height: 6 }} />}
          onClick={() => navigate(HOME_PAGE)}
        />
      </Box>

      <VStack alignItems="flex-start" spacing={0}>
        {updatedLinkItems.map((link) => {
          const active = pathname === link.path;
          return (
            <Link
              as={RouterLink}
              key={link.name}
              to={link.path}
              sx={{
                textDecoration: 'none',
                color: active ? 'white' : 'rgba(92, 92, 92, 0.8)',
                bg: active ? '#459ccf' : 'transparent',
                flex: 1,
                width: 'full',
                fontWeight: 'medium',
                px: 5,
                py: 2,
                '& span': {
                  color: active ? 'white' : '#363c42',
                },
              }}
              _hover={{ bg: active ? '#459ccf' : 'rgba(0,0,0,0.1)' }}
              _focus={{ boxShadow: 'none' }}
            >
              <HStack spacing={5}>
                {link.icon}
                <span>{link.name}</span>
              </HStack>
            </Link>
          );
        })}
      </VStack>
    </Box>
  );
};

export default function Settings() {
  return (
    <Box minH="100vh">
      <SidebarContent />

      <Box ml={{ base: 0, md: 64 }} px={4} py={12}>
        <Outlet />
      </Box>
    </Box>
  );
}
