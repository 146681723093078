import React from 'react';
import ReceivingMethods from '../common/ReceivingMethods/ReceivingMethods';
import { Box, Button, Flex, useToast } from '@chakra-ui/react';
import AnimateContainer from '../common/AnimateContainer';
import { useReduxSelector } from '../../store/store';
import { updateOnBoarding } from '../../store/slices/onBoarding';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HOME_PAGE } from '../../routes';
import { OrderNotificationMethod } from '../../types/common';

const SelectReceivingMethods = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { loading, onBoarding } = useReduxSelector((state) => state.onBoarding);

  const onSubmit = (orderNotificationMethods: OrderNotificationMethod[]) => {
    dispatch(
      updateOnBoarding(
        {
          step: 9,
          data: {
            orderNotificationMethods,
            completed: true,
          },
        },
        () => {
          navigate(HOME_PAGE);
        },
        (e: string) => {
          toast({
            title: e,
            status: 'error',
            isClosable: true,
          });
        },
        true
      )
    );
  };

  return (
    <>
      <Box px={{ lg: 6, base: 4 }} sx={{ flex: 1 }} py={6} overflow="auto">
        <AnimateContainer animate>
          <ReceivingMethods
            onSubmit={onSubmit}
            receivingMethods={onBoarding.orderNotificationMethods}
          />
        </AnimateContainer>
      </Box>
      <Flex
        borderTop={1}
        borderColor="gray.200"
        borderStyle="solid"
        p={6}
        justify="flex-end"
      >
        <Button
          isLoading={loading}
          disabled={loading}
          type="submit"
          form="on-boarding"
          size="lg"
          variant="primary"
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default SelectReceivingMethods;
