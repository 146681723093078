import moment, { unitOfTime } from 'moment';

export function getRemainingTime(targetDate: string, timeUnit = 'days') {
  if (!targetDate) return 0;
  const currentDate = moment();
  const remainingDays = moment(targetDate).diff(
    currentDate,
    timeUnit as unitOfTime.Diff,
    true
  );
  return Math.ceil(remainingDays);
}
