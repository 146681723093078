import { useReduxDispatch } from '../../store/store';
import { Stack, Flex } from '@chakra-ui/react';
import OnBoardingSteps from './OnBoardingSteps';
import AddressAndCurrency from './AddressAndCurrency';
import { Route, Routes } from 'react-router-dom';
import LogoAndCover from './LogoAndCover';
import Taxation from './Taxation';
import NotFoundPage from '../../pages/public/404';
import BusinessHours from './BusinessHours';
import PickupSettings from './PickupSettings';
import DeliverySettings from './DeliverySettings';
import LinkBankAccount from './LinkBankAccount';
import TablesideSettings from './TablesideSettings';
import LayoutC from '../common/LayoutC';
import { completeOnboardingTemporarily } from '../../store/slices/auth';
import SelectReceivingMethods from './SelectReceivingMethods';

export default function OnBoardingLayout() {
  const dispatch = useReduxDispatch();

  return (
    <LayoutC
      sx={{ height: '100vh', overflow: 'hidden' }}
      animate={false}
      onClick={() => dispatch(completeOnboardingTemporarily(true))}
      buttonText="I will do it later"
      buttonVariant="outline"
    >
      <Stack direction={'row'} spacing={0} h="full">
        <Flex maxW="full" flex={1} mt={0}>
          <OnBoardingSteps />

          <Flex direction="column" justify="space-between" flex={1}>
            <Routes>
              <Route path="logo-and-cover" element={<LogoAndCover />} />
              <Route
                path="address-and-currency"
                element={<AddressAndCurrency />}
              />
              <Route path="taxation" element={<Taxation />} />
              <Route path="business-hours" element={<BusinessHours />} />
              <Route path="link-bank" element={<LinkBankAccount />} />
              <Route path="pickup-settings" element={<PickupSettings />} />
              <Route path="delivery-settings" element={<DeliverySettings />} />
              <Route
                path="tableside-settings"
                element={<TablesideSettings />}
              />
              <Route
                path="receiving-method"
                element={<SelectReceivingMethods />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Flex>
        </Flex>
      </Stack>
    </LayoutC>
  );
}
