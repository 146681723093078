import { Button } from '@chakra-ui/react';
import { Gear } from '../../assets/icons';
import { HStack } from '@chakra-ui/react';

interface MobileAppSubmitNewProps {
  onOpen: () => void;
}

function MobileAppSubmitNew({ onOpen }: MobileAppSubmitNewProps) {
  return (
    <HStack justify="center">
      <Button
        leftIcon={<Gear width={5} height={5} />}
        onClick={onOpen}
        variant="primary"
        size="lg"
      >
        Configure your app
      </Button>
    </HStack>
  );
}

export default MobileAppSubmitNew;
