import { FormikValues } from 'formik';
import isNumeric from 'validator/es/lib/isNumeric';
import Decimal from 'decimal.js';
import { MAX_DELIVERY_RADIUS_LIMIT } from '../constants';
import { Schedule } from '../types/schedule';
import validateIntervalsOverlap from './validateIntervalsOverlap';
import validateStartingAndClosingTime from './validateStartAndCloseTime';

export const validateDeliverySettings = (values: FormikValues) => {
  let errors = {} as { [key: string]: string };

  if (!values.fee) {
    errors.fee = 'Please set delivery fee';
  }

  if (values.fee && !isNumeric(values.fee || '0')) {
    errors.fee = 'Please enter a valid delivery fee';
  }

  if (
    values.fee &&
    isNumeric(values.fee || '0') &&
    new Decimal(values.fee).lt('0')
  ) {
    errors.fee = 'Please enter a valid delivery fee';
  }

  if (!values.street) {
    errors.street = 'Please add street address';
  }

  if (!values.city) {
    errors.city = 'Please add your city';
  }

  if (!values.state) {
    errors.state = 'Please add your state';
  }

  if (!values.zipCode) {
    errors.zipCode = 'Please enter a valid zip code';
  }

  if (!values.radius) {
    errors.radius = 'Please enter delivery distance';
  }

  if (
    values.radius &&
    new Decimal(values.radius).gt(MAX_DELIVERY_RADIUS_LIMIT)
  ) {
    errors.radius = `Radius cannot be greater than ${MAX_DELIVERY_RADIUS_LIMIT} meters`;
  }

  let scheduleErrors = {
    schedule: 'Please set delivery hours.',
  } as { [key: string]: string };

  values.schedule.forEach(({ day, intervals }: Schedule) => {
    if (intervals.length) {
      delete scheduleErrors.schedule;
    }

    scheduleErrors = validateIntervalsOverlap(intervals, scheduleErrors, day);
    scheduleErrors = validateStartingAndClosingTime(
      intervals,
      scheduleErrors,
      day
    );
  });

  errors = { ...errors, ...scheduleErrors };

  return errors;
};
