import { Icon } from '@chakra-ui/react';

export function DotsThree(props: any) {
  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <rect width="256" height="256" fill="none"></rect>
      <circle cx="128" cy="128" r="12"></circle>
      <circle cx="192" cy="128" r="12"></circle>
      <circle cx="64" cy="128" r="12"></circle>
    </Icon>
  );
}
