export enum OrderStatus {
  ACTION_REQUIRED_BY_VENDOR = 'action_required_by_vendor', // action required by vendor (new)
  PENDING = 'pending', // new order - payment not received yet
  PROCESSING = 'processing', // accepted by the restaurant owner
  COMPLETED = 'completed', // order is completed
  DELIVERY = 'delivery',
  READY_FOR_PICKUP = 'ready_for_pickup',
  CANCELED = 'canceled', // order canceled and refunded
  REFUNDED = 'refunded', // order refunded after it was completed
  ACTION_REQUIRED_BY_CUSTOMER = 'action_required_by_customer', // action required by customer
}

export enum OrderPickupType {
  ASAP = 'ASAP',
  SCHEDULED = 'LATER',
}

export enum TableCellTypes {
  CHIP = 'CHIP',
  DEFAULT = 'DEFAULT',
  MENU = 'MENU',
  ICON_BUTTONS = 'ICON_BUTTONS',
}

export enum FulfillmentMethods {
  DELIVERY = 'delivery',
  TAKE_OUT = 'takeout',
  ROOM_SERVICE = 'room_service',
  TABLE_SIDE = 'table_side',
}

export enum ItemUnavailableActions {
  REMOVE_ITEM = 'remove_item',
  CANCEL_ENTIRE_ORDER = 'cancel_entire_order',
}
