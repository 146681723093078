import React from 'react';
import {
  Button,
  Stack,
  SystemStyleObject,
  useColorModeValue,
} from '@chakra-ui/react';
import { FONT_TERTIARY } from '../../constants/constants';

interface CustomCheckboxProps {
  checked: boolean;
  onClick: () => void;
  text: string;
  textStyle?: SystemStyleObject;
}

const CustomCheckbox = ({
  checked,
  text,
  onClick,
  textStyle,
}: CustomCheckboxProps) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        fontSize: 'sm',
        color: '#666666',
        fontFamily: FONT_TERTIARY,
        textAlign: 'left',
        bg: 'transparent',
        ...textStyle,
      }}
      bg="white"
      _hover={{ bg: useColorModeValue('white', 'gray.800') }}
      _active={{ bg: useColorModeValue('white', 'gray.800') }}
      px={0}
      whiteSpace="normal"
      leftIcon={
        <Stack
          border={`1px solid ${useColorModeValue('black', 'white')}`}
          w="20px"
          h="20px"
          borderRadius="4px"
          position="relative"
          _before={
            checked
              ? {
                  content: '""',
                  w: '16px',
                  h: '16px',
                  bg: '#3a1960',
                  display: 'inline-block',
                  borderRadius: '2px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }
              : undefined
          }
        />
      }
    >
      {text}
    </Button>
  );
};

export default CustomCheckbox;
