import {
  Grid,
  GridItem,
  Stack,
  Box,
  useToast,
  Text,
  HStack,
  useDisclosure,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';

import { FormikHelpers, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import DialogC from '../common/DialogC';
import ChInput from '../common/ChInput';
import ChTextArea from '../common/ChTextArea';
import ImageVideoPicker from '../pickers/ImageVideoPicker';
import { WhiteLabelMobileApp } from '../../types/common';
import { validateImageForStores } from '../../utils';
import ColorPicker from '../common/ColorPicker';
import MobileAppPreview from './MobileAppPreview';
import { DeviceMobile } from '../../assets/icons';

const validate = (values: FormikValues) => {
  const { appIcon, newAppIcon, appName, shortDescription, fullDescription } =
    values;
  const errors = {} as { [key: string]: string };

  if (!appIcon && !newAppIcon) {
    errors.appIcon = 'App icon is required';
  }

  const appNameRegex = /^[a-zA-Z0-9&' -]{1,30}$/;

  if (!appName) {
    errors.appName = 'App name is required';
  } else if (appName && (appName.length < 1 || appName.length > 30)) {
    errors.appName = 'App name must be between 1 and 30 characters';
  } else if (appName && !appNameRegex.test(appName)) {
    errors.appName =
      'App name can only contain letters, numbers, spaces, apostrophes, dashes, and ampersands';
  }

  if (!shortDescription) {
    errors.shortDescription = 'Short description is required';
  } else if (shortDescription.length > 170) {
    errors.shortDescription =
      'Short description must be less than or equal to 170 characters';
  }

  if (!fullDescription) {
    errors.fullDescription = 'Full description is required';
  } else if (fullDescription.length < 50) {
    errors.fullDescription =
      'Full description must be at least 50 characters long';
  } else if (fullDescription.length > 4000) {
    errors.fullDescription =
      'Full description must be less than 4000 characters long';
  }

  return errors;
};
const hexRegex = /^#([0-9A-F]{3}){1,2}$/i; // regular expression to match hex color codes

const validationSchema = Yup.object().shape({
  privacyPolicyUrl: Yup.string()
    .url('Please enter a valid URL')
    .required('Privacy policy URL is required'),
  primaryColor: Yup.string()
    .matches(hexRegex, 'Invalid hex color code')
    .required('Hex color code is required'),
  primaryContrastColor: Yup.string()
    .matches(hexRegex, 'Invalid hex color code')
    .required('Hex color code is required'),
  secondaryContrastColor: Yup.string()
    .matches(hexRegex, 'Invalid hex color code')
    .required('Hex color code is required'),
  secondaryColor: Yup.string()
    .matches(hexRegex, 'Invalid hex color code')
    .required('Hex color code is required'),
  splashScreenBgColor: Yup.string()
    .matches(hexRegex, 'Invalid hex color code')
    .required('Hex color code is required'),
});

interface mobileAppForm {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    values: FormikValues,
    { setSubmitting }: FormikHelpers<any>
  ) => void;
  whiteLabelMobileApp: WhiteLabelMobileApp;
  manualReleaseRequired: boolean;
}

const MobileAppForm = ({
  isOpen,
  onClose,
  onSubmit,
  whiteLabelMobileApp,
  manualReleaseRequired,
}: mobileAppForm) => {
  const toast = useToast();
  const {
    isOpen: isPreviewOpen,
    onClose: onPreviewClose,
    onOpen: onPreviewOpen,
  } = useDisclosure();
  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: {
      primaryColor: whiteLabelMobileApp?.primaryColor || '',
      primaryContrastColor: whiteLabelMobileApp?.primaryContrastColor || '',
      secondaryColor: whiteLabelMobileApp?.secondaryColor || '',
      secondaryContrastColor: whiteLabelMobileApp?.secondaryContrastColor || '',
      appIcon: whiteLabelMobileApp?.appIcon || '',
      splashScreenImage: whiteLabelMobileApp?.splashScreenImage || '',
      newSplashScreenImage: null,
      splashScreenBgColor: whiteLabelMobileApp?.splashScreenBgColor || '',
      appName: whiteLabelMobileApp?.appName || '',
      shortDescription: whiteLabelMobileApp?.shortDescription || '',
      fullDescription: whiteLabelMobileApp?.fullDescription || '',
      newAppIcon: null,
      appScreenshots: [],
      privacyPolicyUrl: whiteLabelMobileApp?.privacyPolicyUrl || '',
    },
    validate,
    validationSchema,
    onSubmit,
  });

  const removeAppIcon = () => {
    setFieldValue('appIcon', '');
    setFieldValue('newAppIcon', null);
  };

  return (
    <DialogC
      title="App Release Information"
      open={isOpen}
      onClose={onClose}
      closeButtonText="Cancel"
      saveButtonText="Save"
      formId="app-release-form"
      loading={isSubmitting}
      size="3xl"
    >
      <form onSubmit={handleSubmit} id="app-release-form">
        {manualReleaseRequired && (
          <Text mb={5} fontWeight="medium">
            It can take 2-3 working days to submit your app to app store.
          </Text>
        )}

        <MobileAppPreview
          isOpen={isPreviewOpen}
          onClose={onPreviewClose}
          primaryColor={values.primaryColor}
          splashScreenBgColor={values.splashScreenBgColor}
          primaryContrastColor={values.primaryContrastColor}
          appIcon={
            values.newAppIcon
              ? URL.createObjectURL(values.newAppIcon)
              : values.appIcon
          }
        />

        <Grid templateColumns="repeat(6, 1fr)" gap={6}>
          <GridItem colSpan={2} w="100%">
            <Box
              sx={{
                '& .uploaded-image': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'full',
                },
                '& img': { width: '80px', height: '80px' },
              }}
            >
              <ImageVideoPicker
                savedImage={values.appIcon}
                newImage={values.newAppIcon}
                fileErrorMessage="Please upload a valid image (Accepts .png (maximum file size: 5MB))"
                onDrop={async (files) => {
                  try {
                    await validateImageForStores(files[0]);
                    await setFieldValue('newAppIcon', files[0]);
                  } catch (e) {
                    toast({
                      title:
                        "App icon can't be transparent nor contain an alpha channel.",
                      status: 'error',
                      isClosable: true,
                    });
                  }
                }}
                handleRemoveImage={removeAppIcon}
                instructionText="Accepts .png"
                buttonText="Add App Icon"
                accept={{
                  'image/png': ['.png'],
                }}
                isInvalid={!!errors.appIcon && touched.appIcon}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={4} w="100%">
            <HStack mb={4} spacing={4}>
              <Text fontSize="2xl" color="#241c15" fontWeight="medium">
                App colors
              </Text>
              <Box>
                <Tooltip title="App preview">
                  <IconButton
                    onClick={onPreviewOpen}
                    aria-label="App Preview"
                    icon={<DeviceMobile width="28px" height="28px" />}
                  />
                </Tooltip>
              </Box>
            </HStack>
            <Stack spacing={4}>
              <HStack>
                <ColorPicker
                  isInvalid={!!errors.primaryColor && touched.primaryColor}
                  label="Primary color"
                  color={values.primaryColor}
                  onChange={(color) => setFieldValue('primaryColor', color.hex)}
                  labelInfoText="This color will be the primary color of your app's theme and will be used in various UI elements throughout the app."
                />
                <ColorPicker
                  isInvalid={
                    !!errors.primaryContrastColor &&
                    touched.primaryContrastColor
                  }
                  label="Primary contrast color"
                  color={values.primaryContrastColor}
                  onChange={(color) =>
                    setFieldValue('primaryContrastColor', color.hex)
                  }
                  labelInfoText="This color will be used to provide contrast against the primary color, such as in text and buttons."
                />
              </HStack>
              <HStack>
                <ColorPicker
                  isInvalid={!!errors.secondaryColor && touched.secondaryColor}
                  label="Secondary color"
                  color={values.secondaryColor}
                  onChange={(color) =>
                    setFieldValue('secondaryColor', color.hex)
                  }
                  labelInfoText="This color will be used to complement the primary color and add visual interest to the app's design."
                />
                <ColorPicker
                  isInvalid={
                    !!errors.secondaryContrastColor &&
                    touched.secondaryContrastColor
                  }
                  label="Secondary contrast color"
                  color={values.secondaryContrastColor}
                  onChange={(color) =>
                    setFieldValue('secondaryContrastColor', color.hex)
                  }
                  labelInfoText="This color will be used to provide contrast against the secondary color, such as in text and buttons."
                />
              </HStack>
              <ColorPicker
                isInvalid={
                  !!errors.splashScreenBgColor && touched.splashScreenBgColor
                }
                label="Splash screen background"
                color={values.splashScreenBgColor}
                onChange={(color) =>
                  setFieldValue('splashScreenBgColor', color.hex)
                }
                labelInfoText="This color will be used as the background color for the app's splash screen."
              />
            </Stack>

            <Text
              fontSize="2xl"
              color="#241c15"
              fontWeight="medium"
              mb={4}
              mt={5}
            >
              App information
            </Text>
            <Stack spacing={4}>
              <ChInput
                id="appName"
                label="App Name"
                placeholder="Enter the name of your app"
                name="appName"
                value={values.appName}
                handleChange={handleChange}
                isInvalid={!!errors.appName && touched.appName}
                error={errors.appName}
                labelInfoText="The display name that will appear in the App Store, as well as on the user's device."
              />

              <ChInput
                id="shortDescription"
                label="Short Description"
                placeholder="Enter your app short description"
                name="shortDescription"
                value={values.shortDescription}
                handleChange={handleChange}
                isInvalid={
                  !!errors.shortDescription && touched.shortDescription
                }
                error={errors.shortDescription}
                labelInfoText="Briefly describe your app (170 characters max)"
              />

              <ChTextArea
                id="fullDescription"
                label="Full Description"
                placeholder="Enter your app full description"
                name="fullDescription"
                value={values.fullDescription}
                handleChange={handleChange}
                isInvalid={!!errors.fullDescription && touched.fullDescription}
                error={errors.fullDescription}
                labelInfoText="Provide a detailed description of your app (up to 4000 characters)"
              />

              <ChInput
                id="privacyPolicyUrl"
                label="Privacy Policy URL"
                placeholder="Enter privacy policy URL"
                name="privacyPolicyUrl"
                value={values.privacyPolicyUrl}
                handleChange={handleChange}
                isInvalid={
                  !!errors.privacyPolicyUrl && touched.privacyPolicyUrl
                }
                error={errors.privacyPolicyUrl}
              />
            </Stack>
          </GridItem>
        </Grid>
      </form>
    </DialogC>
  );
};

export default MobileAppForm;
