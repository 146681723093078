import Decimal from 'decimal.js';
import convertDecimalJsToNumber from './convertDecimalJsToNumber';

const MILES_IN_ONE_METER = 0.000621371192;
const METERS_IN_ONE_MILES = 1609.35;

export function getMiles(meters: number) {
  const milesDecimal = new Decimal(meters || '0').times(MILES_IN_ONE_METER);
  return convertDecimalJsToNumber(milesDecimal);
}

export function getMeters(miles: number) {
  const metersDecimal = new Decimal(miles || '0').times(METERS_IN_ONE_MILES);
  return convertDecimalJsToNumber(metersDecimal);
}
