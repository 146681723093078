import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { Outlet } from 'react-router-dom';
import WebFont from 'webfontloader';
import { useSelector } from 'react-redux';
import { fontFamilies } from '../../../constants/constants';

const QrCode = () => {
  const { duplicateLoading, exportLoading } = useSelector(
    (state) => state.qrCode
  );

  useEffect(() => {
    const families = fontFamilies.map(
      (fontFamily) => `${fontFamily}:300,400,500,600,700`
    );

    WebFont.load({
      google: {
        families,
      },
    });
  }, []);

  return (
    <>
      <Backdrop
        open={duplicateLoading || exportLoading}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Outlet />
    </>
  );
};

export default QrCode;
