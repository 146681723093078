import { OrderStatus } from '../constants';

export const printOrderStatus = (status: OrderStatus) => {
  if (status === OrderStatus.CANCELED) {
    return {
      text: 'Canceled',
      colorScheme: 'red',
    };
  }

  if (status === OrderStatus.REFUNDED) {
    return {
      text: 'Refunded',
      colorScheme: 'red',
    };
  }

  if (status === OrderStatus.ACTION_REQUIRED_BY_VENDOR) {
    return {
      text: 'New',
      colorScheme: 'yellow',
    };
  }

  if (status === OrderStatus.PROCESSING) {
    return {
      text: 'In progress',
      colorScheme: 'blue',
    };
  }

  if (status === OrderStatus.DELIVERY) {
    return {
      text: 'Delivery',
      colorScheme: 'blue',
    };
  }

  if (status === OrderStatus.COMPLETED) {
    return {
      text: 'Completed',
      colorScheme: 'green',
    };
  }

  if (status === OrderStatus.READY_FOR_PICKUP) {
    return {
      text: 'Ready for pickup',
      colorScheme: 'blue',
    };
  }

  return {
    text: '',
    colorScheme: '',
  };
};
