import React, { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Link, useNavigate } from 'react-router-dom';
import { HOME_PAGE, SETTINGS_PAGE } from '../../routes';
import { useReduxDispatch } from '../../store/store';
import { logout } from '../../store/slices/auth';
import { SETUP_GUIDE_PAGE } from '../../constants/constants';
import {
  NAVBAR_HEIGHT,
  STRIPE_CUSTOMER_PORTAL_LINK,
  STRIPE_PORTAL_NAME,
} from '../../constants';
import useAuth from '../../hooks/useAuth';
import Logo from '../common/Logo';
import MyAvatar from '../common/MyAvatar';

import './navbar.scss';
import { ArrowSquareOutCustomBold } from '../../assets/icons';

const Navbar = () => {
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  const { email, user } = useAuth();
  const [open, setOpen] = useState(false);

  return (
    <nav className="navbar" style={{ height: NAVBAR_HEIGHT }}>
      <div className="navbar__logo-wrapper">
        <Link to={HOME_PAGE}>
          <Logo variant="smile" color="#3a1960" />
        </Link>
      </div>

      <div className="navbar__buttons-wrapper">
        <a
          className="navbar__btn navbar__new-button"
          href={SETUP_GUIDE_PAGE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Setup Guide
        </a>

        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className="navbar__avatar-wrapper">
            <MyAvatar
              round
              onClick={() => setOpen(!open)}
              fullName={user.fullName}
            />

            {open && (
              <div className="navbar__dropdown">
                <Link className="navbar__dropdown-btn" to={SETTINGS_PAGE}>
                  Account & Settings
                </Link>
                <a
                  onClick={() => setOpen(!open)}
                  className="navbar__dropdown-btn"
                  href={`${STRIPE_CUSTOMER_PORTAL_LINK}?prefilled_email=${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{STRIPE_PORTAL_NAME}</span>
                  <ArrowSquareOutCustomBold />
                </a>
                <button
                  className="navbar__dropdown-btn"
                  onClick={() => dispatch(logout(navigate))}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
    </nav>
  );
};

export default Navbar;
