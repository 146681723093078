import {
  CircularProgress,
  HStack,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';

interface CircularLoaderProps {
  text?: string;
  size?: string | number;
  sx?: SystemStyleObject;
  textStyle?: SystemStyleObject;
}

const CircularLoader = ({ text, sx, textStyle, size }: CircularLoaderProps) => {
  return (
    <HStack py={10} justify="center" align="center" sx={sx}>
      <CircularProgress isIndeterminate size={size} />
      {text && <Text sx={textStyle}>{text}</Text>}
    </HStack>
  );
};

export default CircularLoader;
