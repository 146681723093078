import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  SystemStyleObject,
} from '@chakra-ui/react';

interface SelectWithLabelProps {
  isInvalid?: boolean;
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  value: string;
  error?: string;
  handleChange: React.ChangeEventHandler<HTMLSelectElement>;
  sx?: SystemStyleObject;
  options?: { value: string; label: string }[];
}

const SelectWithLabel = ({
  isInvalid,
  id,
  label,
  placeholder,
  name,
  value,
  error,
  handleChange,
  sx,
  options = [],
}: SelectWithLabelProps) => {
  return (
    <FormControl isInvalid={isInvalid} sx={sx}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Select
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        size="lg"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default SelectWithLabel;
