export * from './order';
export * from './icons';
export * from './common';
export * from './stripe';
export * from './page';
export * from './page';
export * from './integrations';

export enum Plans {
  STARTER = 'starter',
  GROWTH = 'growth',
  ENTERPRISE = 'enterprise',
  LEGACY = 'legacy',
}

export enum BusinessTypes {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export enum AccountHolderTypes {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export enum OrdersNotificationMethods {
  SMS = 'sms',
  EMAIL = 'email',
  TABLET = 'tablet',
}

export const PLAN_TRIAL_PERIOD = 14; // days
export const PER_SMS_PRICE = 0.03; // dollar

export const StripePlans = {
  STARTER_MONTHLY: process.env.REACT_APP_STRIPE_PRODUCT_PLAN_STARTER,
  STARTER_YEARLY: process.env.REACT_APP_STRIPE_PRODUCT_PLAN_STARTER_YEARLY,
  GROWTH_MONTHLY: process.env.REACT_APP_STRIPE_PRODUCT_PLAN_GROWTH,
  GROWTH_YEARLY: process.env.REACT_APP_STRIPE_PRODUCT_PLAN_GROWTH_YEARLY,
  ENTERPRISE_MONTHLY: process.env.REACT_APP_STRIPE_PRODUCT_PLAN_ENTERPRISE,
  ENTERPRISE_YEARLY:
    process.env.REACT_APP_STRIPE_PRODUCT_PLAN_ENTERPRISE_YEARLY,
};

export const CALENDLY_URL = 'https://calendly.com/crave-alvin/virtual-coffee';
export const STRIPE_CUSTOMER_PORTAL_LINK =
  process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK;

export enum AuthProviders {
  GOOGLE_DASHBOARD = 'GOOGLE_DASHBOARD',
  EMAIL = 'EMAIL',
}

export const DEFAULT_DELIVERY_FEE = '5'; // DOLLARS
export const DEFAULT_DELIVERY_RADIUS = '1609.35'; // METERS
export const MAX_DELIVERY_RADIUS_LIMIT = 48280; // METERS

export const CRAVE_THUMBNAIL =
  'https://s3.us-west-2.amazonaws.com/assets.orderspot.app/images/crave-thumbnail.png';

export const SIDE_NAVIGATION_WIDTH = 260; // px
export const NAVBAR_HEIGHT = 50; // px

export const SINGLE_REQUEST_DATA_LIMIT = 25;

export const SWR_CONFIG = {
  revalidateOnFocus: false,
  refreshInterval: 90000, // 90sec
};

export const WEB_WIDGET_URL = process.env.REACT_APP_WIDGET_URL;

export enum Countries {
  US = 'US',
  GB = 'GB',
}

export enum Currencies {
  USD = 'usd',
  GBP = 'gbp',
}

export enum DiscountStatuses {
  ALL = '',
  ACTIVE = 'Active',
  SCHEDULED = 'Scheduled',
  EXPIRED = 'Expired',
  DISABLED = 'Disabled',
}
