import { useEffect, CSSProperties } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import SideNavigation from './SideNavigation';
import { getCategoriesAndItems } from '../../store/slices/categoriesAndItems';
import {
  authenticateZendeskUser,
  toggleWidgetPreview,
} from '../../utils/zendesk';
import { notRenderSideDrawerAndNavbar } from '../../routes/nav-config';
import { StripeSubscriptionStatuses } from '../../constants';
import Navbar from './Navbar';
import SubscriptionExpiredBanner from '../subscription/SubscriptionExpiredBanner';
import { useReduxSelector } from '../../store/store';
import SubscriptionCanceled from '../subscription/SubscriptionCanceled';
import useAuth from '../../hooks/useAuth';
import SubscriptionTrialBanner from '../subscription/SubscriptionTrialBanner';
import SubscriptionWillCancelBanner from '../subscription/SubscriptionWillCancelBanner';
import { ACTIVATE_ACCOUNT, DASHBOARD_PAGE } from '../../routes';

const styles: {
  layout: CSSProperties;
  mainContainer: CSSProperties;
  contentStyle: CSSProperties;
} = {
  layout: {
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
  },
  contentStyle: {
    flex: 1,
    overflow: 'auto',
  },
};

const getBgColor = (pathname: string) => {
  const pageForBg = [DASHBOARD_PAGE, ACTIVATE_ACCOUNT];
  return pageForBg.includes(pathname) ? 'rgb(247, 249, 251)' : '#fff';
};

const Layout = ({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { merchant } = useAuth();
  const { subscriptionStatus, cancelAtPeriodEnd } = merchant.subscription;
  const { user } = useReduxSelector((state) => state.auth);

  useEffect(() => {
    //  Fetch data that required on more than one pages of MD
    dispatch(getCategoriesAndItems());

    //  ZENDESK
    toggleWidgetPreview(true);
    authenticateZendeskUser(user?.chatToken);

    return () => {
      toggleWidgetPreview();
    };
  }, []);

  if (
    notRenderSideDrawerAndNavbar.some((path) => pathname.indexOf(path) !== -1)
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          minHeight: 1,
        }}
      >
        <Outlet />
      </Box>
    );
  }

  const isSubscriptionCanceled =
    user.subscription?.subscriptionStatus ===
    StripeSubscriptionStatuses.CANCELED;

  if (isSubscriptionCanceled) {
    return (
      <div style={styles.layout}>
        <Navbar />
        <div style={styles.mainContainer}>
          <SubscriptionCanceled />
        </div>
      </div>
    );
  }

  const renderBanner = () => {
    if (subscriptionStatus === StripeSubscriptionStatuses.TRIALING) {
      return <SubscriptionTrialBanner isOpen={isOpen} setOpen={setOpen} />;
    }

    if (subscriptionStatus === StripeSubscriptionStatuses.PAST_DUE) {
      return <SubscriptionExpiredBanner />;
    }

    if (cancelAtPeriodEnd) {
      return <SubscriptionWillCancelBanner isOpen={isOpen} setOpen={setOpen} />;
    }

    return <div />;
  };

  return (
    <div style={styles.layout}>
      <Navbar />
      <div style={{ position: 'relative', zIndex: 2 }}>{renderBanner()}</div>
      <div style={styles.mainContainer}>
        <SideNavigation />
        <div
          style={{
            ...styles.contentStyle,
            backgroundColor: getBgColor(pathname),
          }}
          id="infinite-scroll"
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
