import { createSlice } from '@reduxjs/toolkit';
import { dispatch, getState } from '../store';
import crave from '../../apis/crave';
import { formatApiError } from '../../utils';
import { uid } from 'uid';
import { openToast } from './snackbar';

// ----------------------------------------------------------------------

const itemsInitialState = [
  {
    modifierId: uid(),
    name: '',
    price: '0',
    quantity: 1,
    maxQuantity: '1',
    sortOrder: 1,
  },
];

const initialState = {
  loading: false,
  error: null,
  modifiers: [],
  formattedModifiers: {
    tableHeadings: [
      'NAME',
      'NO. OF OPTIONS',
      'REQUIRED',
      'OPTIONS NEED TO SELECT',
      '',
    ],
    tableRows: [],
  },
  modifierDetails: {
    id: '',
    isFixed: false,
    name: '',
    items: itemsInitialState,
    quantity: '1',
    required: false,
  },
  isCreateModifiersModalOpen: false,
  isDeleteModifiersModalOpen: false,
  isEditModifiersModalOpen: false,
};

const slice = createSlice({
  name: 'modifiers',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // OPEN MODIFIER MODAL
    openModifierModal(state, action) {
      state[action.payload] = true;
    },

    // CLOSE MODIFIER MODAL
    closeModifierModal(state, action) {
      state[action.payload] = false;
    },

    getModifiersSuccess(state, action) {
      state.loading = false;
      state.modifiers = action.payload.modifiers;
      state.formattedModifiers.tableRows = action.payload.formattedModifiers;
    },

    setDeleteModifierModalData(state, action) {
      state.isDeleteModifiersModalOpen =
        action.payload.isDeleteModifiersModalOpen;
      state.modifierDetails.id = action.payload.id || '';
    },

    createModifierSuccess(state, action) {
      state.loading = false;
      state.modifiers = action.payload.modifiers;
      state.formattedModifiers.tableRows = action.payload.formattedModifiers;
      state.isCreateModifiersModalOpen = false;
      state.modifierDetails = {
        id: '',
        isFixed: false,
        name: '',
        items: itemsInitialState,
        quantity: 0,
        required: false,
      };
    },

    deleteModifiersSuccess(state, action) {
      state.loading = false;
      state.modifiers = action.payload.modifiers;
      state.formattedModifiers.tableRows = action.payload.formattedModifiers;
      state.isDeleteModifiersModalOpen = false;
    },

    setEditModifiersModalData(state, action) {
      state.isEditModifiersModalOpen = action.payload.isEditModifiersModalOpen;
      state.modifierDetails = {
        id: action.payload.id || '',
        isFixed: action.payload.isFixed || false,
        name: action.payload.name || '',
        items: action.payload.items || itemsInitialState,
        quantity: action.payload.quantity || 0,
        required: action.payload.required || false,
      };
    },

    editModifiersSuccess(state, action) {
      state.loading = false;
      state.modifiers = action.payload.modifiers;
      state.formattedModifiers.tableRows = action.payload.formattedModifiers;
      state.isEditModifiersModalOpen = false;
      state.modifierDetails = {
        id: '',
        isFixed: false,
        name: '',
        items: itemsInitialState,
        quantity: 0,
        required: false,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openModifierModal,
  closeModifierModal,
  setDeleteModifierModalData,
  setEditModifiersModalData,
} = slice.actions;

// ----------------------------------------------------------------------
const handleFormatModifiersData = (modifiers) => {
  const formattedModifiers = modifiers.map((modifier) => {
    const { name, items, isFixed, quantity, required } = modifier;

    const noOfOptions = items.length;
    const selectQuantity =
      isFixed || quantity === 1
        ? `Select ${quantity}`
        : `Select up to ${quantity}`;

    return {
      item: modifier,
      tableRow: [
        { text: name },
        { text: `${noOfOptions} ${noOfOptions > 1 ? 'Options' : 'Option'}` },
        { text: required ? 'Yes' : 'No' },
        { text: selectQuantity },
        { text: '' },
      ],
    };
  });

  return { modifiers, formattedModifiers };
};

// ----------------------------------------------------------------------

export function getModifiers() {
  return async () => {
    try {
      const { auth } = getState();
      const { merchantId, locationId, token } = auth.user;
      dispatch(slice.actions.startLoading());

      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.get(
        `merchants/${merchantId}/locations/${locationId}/modifiers`,
        params
      );
      const { modifiers, formattedModifiers } = handleFormatModifiersData(data);
      dispatch(
        slice.actions.getModifiersSuccess({ modifiers, formattedModifiers })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createModifier(newModifier, onSuccess) {
  return async () => {
    const { auth, modifiers: modifiersState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { modifiers } = modifiersState;
    dispatch(slice.actions.startLoading());
    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.post(
        `merchants/${merchantId}/locations/${locationId}/modifiers`,
        newModifier,
        params
      );
      const { formattedModifiers, modifiers: newModifiers } =
        handleFormatModifiersData([...modifiers, data.data]);
      dispatch(
        slice.actions.createModifierSuccess({
          formattedModifiers,
          modifiers: newModifiers,
        })
      );
      dispatch(
        openToast({
          message: 'Modifier group added successfully',
          type: 'success',
        })
      );
      if (onSuccess) {
        onSuccess(data.data);
      }
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteModifiers() {
  return async () => {
    const { auth, modifiers: modifiersState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { modifierDetails, modifiers } = modifiersState;

    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      await crave.delete(
        `merchants/${merchantId}/locations/${locationId}/modifiers/${modifierDetails.id}`,
        params
      );
      const { formattedModifiers, modifiers: newModifiers } =
        handleFormatModifiersData(
          modifiers.filter((modifier) => modifier.id !== modifierDetails.id)
        );
      dispatch(
        slice.actions.deleteModifiersSuccess({
          formattedModifiers,
          modifiers: newModifiers,
        })
      );
      dispatch(
        openToast({
          message: 'Modifier group deleted successfully',
          type: 'info',
        })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function editModifier(updateModifier) {
  return async () => {
    const { auth, modifiers: modifiersState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { modifiers } = modifiersState;

    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.put(
        `merchants/${merchantId}/locations/${locationId}/modifiers/${updateModifier.id}`,
        updateModifier,
        params
      );
      const { formattedModifiers, modifiers: newModifiers } =
        handleFormatModifiersData(
          modifiers.map((modifier) =>
            modifier.id === updateModifier.id ? data.data : modifier
          )
        );
      dispatch(
        slice.actions.editModifiersSuccess({
          formattedModifiers,
          modifiers: newModifiers,
        })
      );
      dispatch(
        openToast({
          message: 'Modifier group updated successfully',
          type: 'info',
        })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}
