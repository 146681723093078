import { MODULES } from '../constants/constants';
import { Countries } from '../constants';

export const formatUserData = (userData, token) => {
  return {
    token,
    authenticated: true,
    onboardingInProgress: false,
    isOrdersAllowed: userData.locationModules?.includes(MODULES.ORDERS_ALLOWED),
    isIntegrationsAllowed: userData.locationModules?.includes(
      MODULES.INTEGRATIONS_MODULE
    ),
    isDeliveryAllowed: userData.locationModules?.includes(
      MODULES.DELIVERY_MODULE
    ),
    // TODO -- will update to dynamic value
    country: Countries.US,
    ...userData,
  };
};
