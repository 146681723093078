import React from 'react';
import { Button, Stack, Text } from '@chakra-ui/react';

interface ButtonType {
  icon: React.ReactElement;
  text: string;
  description?: string;
  value: any;
}

interface ConfirmButtonProps {
  value: any;
  setValue: (value: any) => void;
  buttons: ButtonType[];
}

const ConfirmButton = ({ value, setValue, buttons }: ConfirmButtonProps) => {
  return (
    <Stack justify="stretch" mb={4} direction={{ base: 'column', md: 'row' }}>
      {buttons.map((button) => {
        const active = value === button.value;
        return (
          <Button
            key={button.text}
            leftIcon={button.icon}
            sx={{
              flex: 1,
              justifyContent: 'flex-start',
              height: 'auto',
              p: 3.5,
            }}
            variant="outline"
            onClick={() => setValue(button.value)}
            fontWeight="medium"
            bg={active ? '#f8f8ff' : '#ffffff'}
            minW="0"
            whiteSpace="normal"
          >
            <Stack align="flex-start" ml={1.5}>
              <Text color={active ? '#4556dc' : '#5c5c5c'}>{button.text}</Text>
              {button.description && (
                <Text
                  key={button.description}
                  color="#666666"
                  fontWeight="normal"
                  fontSize="13px"
                  textAlign="left"
                >
                  {button.description}
                </Text>
              )}
            </Stack>
          </Button>
        );
      })}
    </Stack>
  );
};

export default ConfirmButton;
