import { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { COLORS } from './colors';
import { FONT_WEIGHT, FONT_FAMILY } from './fonts';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import ComponentsOverrides from './overrides';
import shadows, { customShadows } from './shadows';
import shape from './shape';

export { COLORS, FONT_WEIGHT, FONT_FAMILY };

const ThemeProvider = ({ children }) => {
  const isLight = true;

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape,
      spacing: (factor) => `${2 * factor}rem`,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      letterSpacing: '0.096rem',
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};

export default ThemeProvider;
