import { createSlice } from '@reduxjs/toolkit';
import { dispatch, getState } from '../store';
import crave from '../../apis/crave';
import { formatApiError } from '../../utils';
import { OrdersListParams } from '../../types/common';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    stopLoading(state) {
      state.loading = false;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function exportOrdersListCSV(
  orderListParams: OrdersListParams,
  onFail?: (error: string) => void
) {
  return async () => {
    const {
      auth: { user },
    } = getState();

    try {
      dispatch(slice.actions.startLoading());
      const { data } = await crave.get(
        `/merchants/${user.merchantId}/orders-list-csv`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
          params: orderListParams,
        }
      );

      const blob = new Blob([data.csv], { type: 'text/csv;charset=utf-8,' });
      const objUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.setAttribute('href', objUrl);
      link.setAttribute('download', 'File.csv');
      link.click();

      dispatch(slice.actions.stopLoading());
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      if (onFail) onFail(e);
    }
  };
}
