import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import DialogC from '../common/DialogC';
import DeliveryZoneSelection from '../common/DeliveryZoneSelection';
import { FormikValues, useFormik } from 'formik';
import { MAX_DELIVERY_RADIUS_LIMIT } from '../../constants';
import isNumeric from 'validator/es/lib/isNumeric';
import Decimal from 'decimal.js';
import { DefaultDeliverySettingsData } from '../../types/common';

const validate = (values: FormikValues) => {
  const errors = {} as { [key: string]: string };

  if (!values.fee) {
    errors.fee = 'Please set delivery fee';
  }

  if (values.fee && !isNumeric(values.fee || '0')) {
    errors.fee = 'Please enter a valid delivery fee';
  }

  if (
    values.fee &&
    isNumeric(values.fee || '0') &&
    new Decimal(values.fee).lt('0')
  ) {
    errors.fee = 'Please enter a valid delivery fee';
  }

  if (!values.street) {
    errors.street = 'Please add street address';
  }

  if (!values.city) {
    errors.city = 'Please add your city';
  }

  if (!values.state) {
    errors.state = 'Please add your state';
  }

  if (!values.zipCode) {
    errors.zipCode = 'Please enter a valid zip code';
  }

  if (!values.radius) {
    errors.radius = 'Please enter delivery distance';
  }

  if (
    values.radius &&
    new Decimal(values.radius).gt(MAX_DELIVERY_RADIUS_LIMIT)
  ) {
    errors.radius = `Radius cannot be greater than ${MAX_DELIVERY_RADIUS_LIMIT} meters`;
  }

  return errors;
};

const OnBoardingDeliveryZone = ({
  data,
  setDeliveryValues,
}: {
  data: DefaultDeliverySettingsData;
  setDeliveryValues: (deliveryValues: FormikValues) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, values, handleChange, errors, touched, setFieldValue } =
    useFormik({
      validate,
      initialValues: data,
      onSubmit: (values) => {
        onClose();
        setDeliveryValues(values);
      },
    });

  return (
    <>
      <Button variant="primary" onClick={onOpen}>
        Add a zone
      </Button>

      <DialogC
        open={isOpen}
        onClose={onClose}
        size="full"
        sx={{ '& .chakra-modal__close-btn': { display: 'none' } }}
      >
        <DeliveryZoneSelection
          onSubmit={handleSubmit}
          handleGoBack={onClose}
          values={values}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          loading={false}
          onBoarding
        />
      </DialogC>
    </>
  );
};

export default OnBoardingDeliveryZone;
