import { AutoGeneratedAddress } from '../types/common';

export const getAddressComponents = (
  // eslint-disable-next-line no-undef
  detailsResult: google.maps.places.PlaceResult
): AutoGeneratedAddress | null => {
  try {
    // eslint-disable-next-line camelcase
    const { geometry, address_components } = detailsResult;

    const fullAddress = {
      street: '',
      zipCode: '',
      country: '',
      city: '',
      state: '',
      lat: 0,
      lng: 0,
    };

    // eslint-disable-next-line camelcase
    address_components?.forEach((component: any) => {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          fullAddress.street = `${component.long_name} ${fullAddress.street}`;
          break;
        }

        case 'route': {
          fullAddress.street += component.short_name;
          break;
        }

        case 'postal_code': {
          fullAddress.zipCode = `${component.long_name}${fullAddress.zipCode}`;
          break;
        }

        case 'locality':
          fullAddress.city = component.long_name;
          break;
        case 'administrative_area_level_1': {
          fullAddress.state = component.short_name;
          break;
        }
        case 'country':
          fullAddress.country = component.long_name;
          break;
        default:
      }
    });

    fullAddress.lat = geometry?.location?.lat() || 0;
    fullAddress.lng = geometry?.location?.lng() || 0;

    return fullAddress;
  } catch (e) {
    return null;
  }
};
