import { createSlice } from '@reduxjs/toolkit';
import { dispatch, getState } from '../store';
import crave from '../../apis/crave';
import { formatApiError } from '../../utils';
import { handleLogin, logout } from './auth';
import { Plans } from '../../constants';

// ----------------------------------------------------------------------

const initialState = {
  loadingData: false,
  loading: false,
  error: null,
  onBoarding: {
    selectedPlan: Plans.STARTER,
    selectedPlanStripeId: '',
    merchantId: '',
    priceId: '',
    step: 0,

    businessName: '',
    restaurantLogo: '',
    backgroundUrl: '',
    stripeAccountId: '',

    businessPhoneNumber: '',
    currency: '',
    businessAddressString: '',
    businessAddress: {
      street: '',
      streetOptional: '',
      city: '',
      country: '',
      state: '',
      zipCode: '',
    },
    taxPercentage: '',
    taxInclusive: false,
    businessHours: [],
    bankAccount: {
      token: '',
      last4: '',
      accountHolderName: '',
      bankName: '',
    },
    pickup: {
      enabled: false,
      settings: { hours: [] },
    },
    delivery: {
      enabled: false,
      settings: {
        hours: [],
        fee: '',
        radius: '',
        address: '',
        lat: '',
        lng: '',
        addressData: {
          street: '',
          streetOptional: '',
          zipCode: '',
          country: '',
          city: '',
          state: '',
        },
      },
    },
    qr: {
      enabled: false,
      settings: { hours: [] },
    },
    orderNotificationMethods: [],
  },
};

const slice = createSlice({
  name: 'onBoarding',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    startLoadingData(state) {
      state.loadingData = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.loadingData = false;
      state.error = action.payload;
    },

    getOnBoardingSuccess(state, action) {
      state.loadingData = false;
      state.onBoarding = action.payload;
    },

    updateOnBoardingSuccess(state, action) {
      state.loading = false;
      state.onBoarding = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateOnBoardingSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getOnBoarding(onSuccess, onFail) {
  return async () => {
    try {
      const { auth } = getState();
      const { token } = auth.user;
      dispatch(slice.actions.startLoadingData());

      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.get('/onboarding', params);
      dispatch(slice.actions.getOnBoardingSuccess(data?.onboardingData));
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      if (onFail) {
        onFail(e);
      }
      dispatch(logout());
    }
  };
}

// ----------------------------------------------------------------------

export function updateOnBoarding(payload, onSuccess, onFail, isLastStep) {
  return async () => {
    const {
      auth: { user },
    } = getState();

    try {
      dispatch(slice.actions.startLoading());
      const { data } = await crave.patch(`/onboarding`, payload, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (isLastStep) {
        await handleLogin({ token: user.token });
        onSuccess();
        return;
      }

      dispatch(slice.actions.updateOnBoardingSuccess(data?.onboardingData));
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      if (onFail) {
        onFail(e);
      }
    }
  };
}
