import { CSSProperties } from 'react';
import {
  STRIPE_CUSTOMER_PORTAL_LINK,
  STRIPE_PORTAL_NAME,
} from '../../constants';
import useAuth from '../../hooks/useAuth';
import { getRemainingTime } from '../../utils';
import { XCustom } from '../../assets/icons';

const styles: {
  container: CSSProperties;
  containerInner: CSSProperties;
  text: CSSProperties;
  link: CSSProperties;
  button: CSSProperties;
} = {
  container: {
    backgroundColor: 'rgb(254, 218, 96)',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 40,
  },
  containerInner: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: '#363c42',
    fontSize: 14,
    lineHeight: '1.5 !important',
    marginLeft: 10,
  },
  link: {
    display: 'inline-block',
    color: '#363c42',
    fontSize: 14,
    lineHeight: '1.5 !important',
    cursor: 'pointer',
    textDecoration: 'underline',
    alignSelf: 'flex-end',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    lineHeight: '1.5 !important',
    cursor: 'pointer',
  },
};

const infoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="#363c42"
    viewBox="0 0 256 256"
  >
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path>
  </svg>
);

function SubscriptionTrialBanner({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}) {
  const { email, merchant } = useAuth();
  const { nextBillingDate, cancelAtPeriodEnd } = merchant.subscription;
  if (!isOpen) {
    return <div />;
  }

  const remainingTimeForTrialExpire = getRemainingTime(nextBillingDate);

  const getDays = (remainingDays: number) => {
    if (!remainingDays) return 'soon';
    if (remainingDays > 1) return `in ${remainingDays} days`;
    return `in ${remainingDays} day`;
  };

  const getMessage = () => {
    if (cancelAtPeriodEnd) {
      return `Your trial ends ${getDays(remainingTimeForTrialExpire)}. After the
      trial, your plan will no longer be available. To
      change, navigate to `;
    }

    return `Your trial ends ${getDays(remainingTimeForTrialExpire)}. After the
      trial, you will automatically be charged for the current plan. To
      change, navigate to `;
  };

  return (
    <div style={styles.container}>
      <div style={styles.containerInner}>
        {infoIcon}
        <p style={styles.text}>
          {getMessage()}
          <a
            style={styles.link}
            href={`${STRIPE_CUSTOMER_PORTAL_LINK}?prefilled_email=${email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {STRIPE_PORTAL_NAME}
          </a>
        </p>
      </div>

      <button onClick={() => setOpen(false)} style={styles.button}>
        <XCustom />
      </button>
    </div>
  );
}

export default SubscriptionTrialBanner;
