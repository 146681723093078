import {
  Alert,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { FormikValues, useFormik } from 'formik';
import SelectWithLabel from '../common/SelectWithLabel';
import { updateOnBoarding } from '../../store/slices/onBoarding';
import { TAXATION } from '../../routes';
import { useNavigate } from 'react-router-dom';
import ChPhoneInput from '../common/ChPhoneInput';
import { useReduxSelector } from '../../store/store';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import AnimateContainer from '../common/AnimateContainer';
import AddressAutoComplete from '../common/AddressAutoCompleteC';
import { useLoadScript } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from '../../constants/constants';
import ChInput from '../common/ChInput';
import { getAddressString } from '../../utils';
import React from 'react';
import { AutoGeneratedAddress } from '../../types/common';

const DEFAULT_CURRENCY = 'usd';
const CURRENCIES = [{ value: 'usd', label: 'US Dollars' }];

//----------

const validate = (values: FormikValues) => {
  const { phoneNumber, currency } = values;

  const errors = {} as { [e: string]: string };

  if (!values.street) {
    errors.street = 'Please add street address';
  }

  if (!values.city) {
    errors.city = 'Please add your city';
  }

  if (!values.state) {
    errors.state = 'Please add your state';
  }

  if (!values.zipCode) {
    errors.zipCode = 'Please enter a valid zip code';
  }

  if (!phoneNumber) {
    errors.phoneNumber = 'Phone number is required';
  }

  if (phoneNumber && !isPossiblePhoneNumber(phoneNumber)) {
    errors.phoneNumber = 'Please enter a valid phone number';
  }

  if (!currency) {
    errors.currency = 'Please select currency';
  }

  return errors;
};

//----------
enum Lib {
  PLACES = 'places',
}

const LIBRARIES = [Lib.PLACES];

const AddressAndCurrency = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY as string,
    libraries: LIBRARIES,
  });
  const { loading, onBoarding } = useReduxSelector((state) => state.onBoarding);
  const {
    businessAddress,
    businessAddressString,
    currency,
    businessPhoneNumber,
  } = onBoarding;

  const {
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    isValid,
    submitCount,
  } = useFormik({
    initialValues: {
      address: businessAddressString || '',
      phoneNumber: businessPhoneNumber || '',
      currency: currency || DEFAULT_CURRENCY,
      street: businessAddress?.street || '',
      streetOptional: businessAddress?.streetOptional || '',
      city: businessAddress?.city || '',
      country: businessAddress?.country || '',
      state: businessAddress?.state || '',
      zipCode: businessAddress?.zipCode || '',
    },
    validate,
    onSubmit: (values) => {
      const businessAddress = {
        street: values.street,
        streetOptional: values.streetOptional,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        country: values.country,
      };

      dispatch(
        updateOnBoarding(
          {
            step: 2,
            data: {
              businessAddressString: getAddressString(businessAddress),
              businessPhoneNumber: values.phoneNumber,
              currency: values.currency,
              businessAddress,
            },
          },
          () => {
            navigate(TAXATION);
          },
          (e: string) => {
            toast({
              title: e,
              status: 'error',
              isClosable: true,
            });
          }
        )
      );
    },
  });

  if (loadError) {
    return (
      <Alert status="error">Something went wrong, please reload page</Alert>
    );
  }

  const setAddressValues = (addressInfo: AutoGeneratedAddress) => {
    setFieldValue('street', addressInfo.street);
    setFieldValue('city', addressInfo.city);
    setFieldValue('state', addressInfo.state);
    setFieldValue('country', addressInfo.country);
    setFieldValue('zipCode', addressInfo.zipCode);
  };

  return (
    <>
      <Box px={{ lg: 6, base: 4 }} sx={{ flex: 1 }} py={6} overflow="auto">
        <AnimateContainer animate>
          <form onSubmit={handleSubmit} id="on-boarding">
            <Heading
              as="h1"
              fontSize={{ lg: '4xl', base: '3xl' }}
              mb={4}
              color="#241c15"
              fontWeight="medium"
            >
              What is your business address?
            </Heading>

            <VStack align="flex-start" spacing={3.5}>
              <Text fontSize="lg" color="#363c42">
                Your Restaurant's Address
              </Text>

              {isLoaded ? (
                <AddressAutoComplete
                  label="Address line 1"
                  placeholder="Enter address line 1"
                  value={values.street}
                  isInvalid={!!errors.street && touched.street}
                  error={errors.street}
                  setAddressValues={setAddressValues}
                  setFieldValue={setFieldValue}
                  disabled={!isLoaded}
                />
              ) : (
                <ChInput
                  id="street"
                  label="Address line 1"
                  placeholder="Enter address line 1"
                  name="street"
                  value={values.street}
                  handleChange={() => {}}
                />
              )}

              <ChInput
                id="streetOptional"
                label="Address line 2 (optional)"
                placeholder="Enter address line 2 (optional)"
                name="streetOptional"
                value={values.streetOptional}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('streetOptional', event.target.value)
                }
                isInvalid={!!errors.streetOptional && touched.streetOptional}
                error={errors.streetOptional}
              />

              <Stack
                direction={{ base: 'column', md: 'row' }}
                align="flex-start"
                spacing={2}
                w="full"
              >
                <ChInput
                  id="city"
                  label="City"
                  placeholder="Enter city"
                  name="city"
                  value={values.city}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('city', event.target.value)
                  }
                  isInvalid={!!errors.city && touched.city}
                  error={errors.city}
                />

                <ChInput
                  id="state"
                  label="State"
                  placeholder="Enter state"
                  name="state"
                  value={values.state}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('state', event.target.value)
                  }
                  isInvalid={!!errors.state && touched.state}
                  error={errors.state}
                />

                <ChInput
                  id="country"
                  label="Country"
                  placeholder="Enter country"
                  name="country"
                  value={values.country}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('country', event.target.value)
                  }
                  isInvalid={!!errors.country && touched.country}
                  error={errors.country}
                />

                <ChInput
                  id="zipCode"
                  label="Zip code"
                  placeholder="Zip code"
                  name="zipCode"
                  value={values.zipCode}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('zipCode', event.target.value)
                  }
                  isInvalid={!!errors.zipCode && touched.zipCode}
                  error={errors.zipCode}
                />
              </Stack>

              <ChPhoneInput
                id="phoneNumber"
                name="phoneNumber"
                value={values.phoneNumber}
                handleChange={(num) => setFieldValue('phoneNumber', num)}
                placeholder="Business Phone Number"
                error={errors.phoneNumber}
                isInvalid={!!errors.phoneNumber && touched.phoneNumber}
              />
            </VStack>

            <VStack align="flex-start" mt={9}>
              <Text fontSize="lg" color="#363c42">
                Store Currency Settings
              </Text>

              <SelectWithLabel
                id="currency"
                name="currency"
                placeholder="Currency"
                isInvalid={!!errors.currency && touched.currency}
                error={errors.currency}
                value={values.currency}
                handleChange={handleChange}
                options={CURRENCIES}
              />
            </VStack>
          </form>
        </AnimateContainer>
      </Box>
      <Flex
        borderTop={1}
        borderColor="gray.200"
        borderStyle="solid"
        p={6}
        justify="flex-end"
      >
        <Button
          isLoading={loading}
          disabled={(!isValid && submitCount > 0) || loading}
          type="submit"
          form="on-boarding"
          size="lg"
          variant="primary"
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default AddressAndCurrency;
