import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { DashboardPageIds, SIDE_NAVIGATION_WIDTH } from '../../constants';
import { SETTINGS_PAGE_UPGRADE_PLAN } from '../../routes';
import MyAvatar from '../common/MyAvatar';
import { CaretRightCustom } from '../../assets/icons';
import { useReduxSelector } from '../../store/store';
import { isStarterPlan, printUserRole } from '../../utils';
import {
  getNavLinks,
  LinkItemProps,
  LocationPages,
} from '../../routes/nav-config';
import './side-navigation.scss';

import useLocations from '../../hooks/locations/useLocations';
import { getSession, setSession } from '../../utils/jwt';
import useAuth from '../../hooks/useAuth';
import { ORDERING_APP_URL } from '../../constants/constants';

// ----------------------------------------------------------------------

interface LocationSwitcherProps {
  locations: {
    value: string;
    name: string;
    locationId: string;
  }[];
  currentLocationId: string;
}

const LocationSwitcher = ({
  locations,
  currentLocationId,
}: LocationSwitcherProps) => {
  const handleSwitchLocation = async (newLocationId: string) => {
    const session = await getSession();
    setSession({ ...session, locationId: newLocationId });
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <div className="location-switch-dropdown">
      {locations.map((location) => {
        const disabled = location.locationId === currentLocationId;

        return (
          <button
            key={location.locationId}
            disabled={disabled}
            className={`location-switch-dropdown__btn ${
              disabled && 'disabled'
            }`}
            onClick={() => handleSwitchLocation(location.locationId)}
          >
            {location.name}
          </button>
        );
      })}
    </div>
  );
};

const LocationSwitch = () => {
  const { locationId, location } = useAuth();
  const { loading, error, locations } = useLocations();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const shouldShowSwitcher = locations?.length > 1;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="location-switch">
        <>
          <button
            className="location-switch__button"
            onClick={isOpen ? onClose : onOpen}
            disabled={loading || error}
            style={{ cursor: shouldShowSwitcher ? 'pointer' : 'default' }}
          >
            <MyAvatar fullName={location.locationDisplayName} round />
            <div className="location-switch__button-text-box">
              <p className="location-switch__button-text-main">
                {location.locationDisplayName}
              </p>
              <p className="location-switch__button-text">Store</p>
            </div>
            {shouldShowSwitcher && (
              <span
                style={{
                  transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)',
                }}
              >
                <CaretRightCustom />
              </span>
            )}
          </button>

          {isOpen && shouldShowSwitcher && (
            <LocationSwitcher
              locations={locations}
              currentLocationId={locationId}
            />
          )}
        </>
      </div>
    </ClickAwayListener>
  );
};

// ----------------------------------------------------------------------

const styles = {
  linkStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',
    fontWeight: '500',
    padding: '3px 8px',
  },
  iconTextWrapper: { display: 'flex', alignItems: 'center' },
  linkText: {
    marginLeft: '10px',
    fontSize: '14px',
    color: '#363c42',
  },
  linkIcon: {
    marginLeft: '10px',
    fontSize: '14px',
    color: '#363c42',
  },
};

const getLink = (id: DashboardPageIds, locationName: string) => {
  switch (id) {
    case DashboardPageIds.ONLINE_STORE:
      return `${ORDERING_APP_URL}/${locationName}`;
    default:
      return '';
  }
};

const PageLink = ({ link }: { link: LinkItemProps }) => {
  const { pathname } = useLocation();
  const { location } = useAuth();

  if (!link.path) {
    return (
      <a
        style={styles.linkStyle}
        href={getLink(link.id, location.locationName)}
        className={`page-link__link`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div style={styles.iconTextWrapper}>
          <span className="link-icon">{link.icon}</span>
          <span style={styles.linkText} className="link-text">
            {link.name}
          </span>
        </div>
        {link.rightIcon && (
          <span className="link-right-icon">{link.rightIcon}</span>
        )}
      </a>
    );
  }

  const active = pathname.includes(link.path);

  return (
    <Link
      style={styles.linkStyle}
      to={link.path}
      className={`page-link__link ${active ? 'active' : ''}`}
    >
      <div style={styles.iconTextWrapper}>
        <span className="link-icon">{link.icon}</span>
        <span style={styles.linkText} className="link-text">
          {link.name}
        </span>
      </div>
      {link.rightIcon && (
        <span className="link-right-icon">{link.rightIcon}</span>
      )}
    </Link>
  );
};

// ----------------------------------------------------------------------

const LinkSection = ({ locationPage }: { locationPage: LocationPages }) => {
  const { title, pages } = locationPage;

  return (
    <div className="link-section">
      <p className="link-section__title">{title}</p>
      {pages.map((link) => (
        <PageLink key={link.id} link={link} />
      ))}
    </div>
  );
};

// ----------------------------------------------------------------------

const SideNavigation = () => {
  const { location, user } = useAuth();
  const { user: userState } = useReduxSelector((state) => state.auth);

  const { commonPages, merchantPages, locationPages } = React.useMemo(
    () => getNavLinks(userState),
    []
  );

  return (
    <div className="side-navigation" style={{ width: SIDE_NAVIGATION_WIDTH }}>
      <div className="side-navigation__links">
        <div
          className="side-navigation__top-links"
          style={{ marginBottom: 10 }}
        >
          {commonPages.map((link) => (
            <PageLink key={link.id} link={link} />
          ))}
        </div>

        <div className="side-navigation__merchant-links">
          {merchantPages.map((link) => (
            <PageLink key={link.id} link={link} />
          ))}
        </div>

        <LocationSwitch />

        <div className="side-navigation__merchant-links">
          {locationPages.map((locationPage) => (
            <LinkSection key={locationPage.title} locationPage={locationPage} />
          ))}
        </div>
      </div>

      <div className="side-navigation__bottom">
        {isStarterPlan(userState.subscription) && (
          <div className="side-navigation__bottom-btn-wrapper">
            <Link
              to={SETTINGS_PAGE_UPGRADE_PLAN}
              className="side-navigation__bottom-upgrade-btn"
            >
              Upgrade
            </Link>
          </div>
        )}
      </div>
      <div className="side-navigation__user-info">
        <MyAvatar fullName={user.fullName} />

        <div className="side-navigation__user-info-names">
          <p className="side-navigation__user-info-name">
            {user.firstName} ({printUserRole(userState.memberRole)})
          </p>
          <p className="side-navigation__user-info-location-name">
            {location.locationDisplayName}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
