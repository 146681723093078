import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Heading, useToast } from '@chakra-ui/react';
import { FormikValues, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { LINK_BANK } from '../../routes';
import { updateOnBoarding } from '../../store/slices/onBoarding';
import { defaultSchedule } from '../../constants/constants';
import ScheduleC from './ScheduleC';
import { useReduxSelector } from '../../store/store';
import validateIntervalsOverlap from '../../utils/validateIntervalsOverlap';
import validateStartingAndClosingTime from '../../utils/validateStartAndCloseTime';
import { Interval } from '../../types/schedule';
import AnimateContainer from '../common/AnimateContainer';

const validate = (values: FormikValues) => {
  const errors = {};
  const { schedule } = values;

  let scheduleErrors = {
    schedule: 'Set store hours to continue.',
  } as { [key: string]: string };

  schedule.forEach(
    ({ day, intervals }: { day: number; intervals: Interval[] }) => {
      if (intervals.length) {
        delete scheduleErrors.schedule;
      }

      scheduleErrors = validateIntervalsOverlap(intervals, scheduleErrors, day);

      scheduleErrors = validateStartingAndClosingTime(
        intervals,
        scheduleErrors,
        day
      );
    }
  );

  return { ...errors, ...scheduleErrors };
};

const BusinessHours = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { loading, onBoarding } = useReduxSelector((state) => state.onBoarding);
  const { businessHours } = onBoarding;

  const { handleSubmit, values, errors, setFieldValue, isValid, submitCount } =
    useFormik({
      initialValues: {
        schedule:
          businessHours && businessHours.length > 0
            ? businessHours
            : defaultSchedule,
      },
      validate,
      onSubmit: (values) => {
        dispatch(
          updateOnBoarding(
            {
              step: 4,
              data: {
                businessHours: values.schedule,
              },
            },
            () => {
              navigate(LINK_BANK);
            },
            (e: string) => {
              toast({
                title: e,
                status: 'error',
                isClosable: true,
              });
            }
          )
        );
      },
    });

  return (
    <>
      <Box px={{ lg: 6, base: 4 }} sx={{ flex: 1 }} py={6} overflow="auto">
        <AnimateContainer animate>
          <form onSubmit={handleSubmit} id="on-boarding">
            <Heading
              as="h1"
              fontSize={{ lg: '4xl', base: '3xl' }}
              mb={5}
              color="#241c15"
              fontWeight="medium"
            >
              Now, set up your business hours.
            </Heading>

            <ScheduleC
              schedule={values.schedule}
              errors={submitCount > 0 ? errors : {}}
              setFieldValue={setFieldValue}
            />
          </form>
        </AnimateContainer>
      </Box>
      <Flex
        borderTop={1}
        borderColor="gray.200"
        borderStyle="solid"
        p={6}
        justify="flex-end"
      >
        <Button
          isLoading={loading}
          disabled={(!isValid && submitCount > 0) || loading}
          type="submit"
          form="on-boarding"
          size="lg"
          variant="primary"
        >
          Continue
        </Button>
      </Flex>
    </>
  );
};

export default BusinessHours;
