import useSWR from 'swr';
import { useReduxSelector } from '../../store/store';
import { apiFetcher } from '../../apis/crave';
import { formatApiError } from '../../utils';
import { SWR_CONFIG } from '../../constants';
import { Location } from '../../types/common';

function useLocations() {
  const { user } = useReduxSelector((state) => state.auth);
  const { merchantId, token } = user;

  const { data, error, mutate } = useSWR(
    [`/merchants/${merchantId}/locations`, token],
    apiFetcher,
    SWR_CONFIG
  );

  return {
    locations: (data ?? []).map((location: Location) => ({
      value: location.restaurantName,
      name: location.restaurantDisplayName,
      locationId: location.id,
    })) as { value: string; name: string; locationId: string }[],
    loading: !error && !data,
    error: error ? formatApiError(error)?.e : undefined,
    mutate,
  };
}

export default useLocations;
